import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import useResumeStore from "../../stores/resume/resumeStore";
import useFeedbackModalStore from "../../stores/resume/modalsStore";
import logoDarkPurple from "../../assets/logoLightPurple.svg";
import homepageIcon from "../../assets/homepageIcon.svg";
import homepageIconLight from "../../assets/homepageIconLight.svg";
import analyticsIcon from "../../assets/analyticsIcon.svg";
import analyticsIconDark from "../../assets/analyticsIconDark.svg";
import sparklesIcon from "../../assets/sparklesIcon.svg";
import sparklesIconDark from "../../assets/sparklesIconDark.svg";
import settingsIcon from "../../assets/settingsIcon.svg";
import settingsIconDark from "../../assets/settingsIconDark.svg";
import logoutIcon from "../../assets/logoutIcon.svg";
import logoutIconDark from "../../assets/logoutIconDark.svg";
import feedbackIcon from "../../assets/feedbackIcon.svg";
import feedbackIconDark from "../../assets/feedbackIconDark.svg";
import Modal from "../Elements/Modal";

const Navigation = () => {
  const resume = useResumeStore((state) => state.resume);
  const isSaved = useResumeStore((state) => state.isSaved);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activePage, setActivePage] = useState();
  const [logoutHover, setLogoutHover] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [nextRoute, setNextRoute] = useState(null);
  const [isEmptyFieldsModalOpen, setIsEmptyFieldsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = useAuth();
  const { openModal } = useFeedbackModalStore();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUserData(storedUser);
    }
  }, []);

  const emptyFieldsModalMessage = `You must fill ${!resume.title ? 'Job title' : '' }${ !resume.title && !resume.country ? 'and' : '' }${!resume.country ? 'Country' : ''} to be able to navigate to other pages`

  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);

  const handleClick = (page) => {
    const currentPath = location.pathname; 
    const nextPath = `/${page}`; 
    const onResumeRoute = currentPath.startsWith("/resume/edit");
    const goingToResumeRoute = nextPath.startsWith("/resume/edit");
    const restrictedURLS = ['/custom-cv', '/dashboard']

    if(onResumeRoute && restrictedURLS.some(url => nextPath.startsWith(url)) && (Object.keys(resume).length && (!resume.title || !resume.country))) {
      setIsEmptyFieldsModalOpen(true);
      return;
    }

    if (!isSaved && onResumeRoute && !goingToResumeRoute) {
      setNextRoute(nextPath);
      setShowUnsavedModal(true);
      return; 
    }

    navigate(nextPath);
  };

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1].replace("/", "");
    setActivePage(currentPath);
    console.log(currentPath); 
  }, [location]);

  const handleLogout = async () => {
    localStorage.removeItem("user");
    logout();
    try {
      const extensionId = process.env.REACT_APP_EXTENSION_ID;
      if (window.chrome?.runtime?.sendMessage) {
        await window.chrome.runtime.sendMessage(extensionId, {
          action: "logout",
        });
      }
    } catch (error) {
      console.error("Error sending message to extension:", error);
    }
    navigate("/login");
  };

  const handleConfirmLeave = () => {
    setShowUnsavedModal(false);
    if (nextRoute) {
      const page = nextRoute.replace("/", ""); 
      setActivePage(page);
      navigate(nextRoute);
    }
  };
  const handleCancelLeave = () => {
    setShowUnsavedModal(false);
    setNextRoute(null);
  };

  const navClass = `relative flex flex-col h-full transition-all items-center bg-off-white z-20 ${
    location.pathname === "/dashboard" ? "h-6/6" : "h-auto"
  } w-24 transition-all duration-300`;

  const btnClass = 'absolute left-0 flex items-center justify-start w-auto max-w-10 overflow-hidden transition-all duration-300 hover:max-w-[300px] hover:shadow-md hover:rounded-full hover:pe-4 hover:bg-white';

  const btnIconClass = (selected) =>
    `font-small uppercase m-0 font-bold overflow-hidden text-nowrap transition-all ${
      selected ? "text-dark-blue" : "text-light-liliac"
    }`;
  
    const photoURLWithCacheBust = userData?.photoURL
    ? `${userData.photoURL}?cacheBust=${Date.now()}`
    : null;
  return (
    <div>
      {showUnsavedModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md max-w-sm w-full mx-4">
            <h2 className="text-lg font-bold mb-4">Unsaved Changes</h2>
            <p className="mb-4">
              You have unsaved changes in your resume. Are you sure you want to
              leave?
            </p>
            <div className="flex justify-end gap-2">
              <button
                onClick={handleCancelLeave}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmLeave}
                className="px-4 py-2 bg-purple text-white rounded hover:bg-purple-dark"
              >
                Leave
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal message={emptyFieldsModalMessage} isOpen={isEmptyFieldsModalOpen} onClose={() => setIsEmptyFieldsModalOpen(false)} />
      <div
        className={navClass}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex flex-col items-center fixed space-y-3 h-full py-6">
          <div className="grow flex flex-col gap-4">
            <div className="flex justify-center items-center space-x-4 mb-4">
            {photoURLWithCacheBust ? (
                <img
                  src={photoURLWithCacheBust}
                  alt={userData?.displayName || "User Avatar"}
                  referrerPolicy="no-referrer"
                  className="h-10 w-10 rounded-full object-cover"
                  
                  onError={(e) => {
                    console.error(
                      "Profile image failed to load:",
                      photoURLWithCacheBust
                    );
                    
                  }}
                />
              ) : (
                <img src={logoDarkPurple} alt="Logo" className="h-10 w-10" />
              )}
            </div>
            <div className="cursor-pointer relative w-10 h-10">
              <div onClick={() => handleClick("dashboard")} className={btnClass}>
                <div className="min-w-10 w-10 h-10 flex items-center justify-center">
                  <img
                    src={ activePage === "dashboard" ? homepageIcon : homepageIconLight}
                    alt="Homepage"
                    className="h-5 w-5"
                  />
                </div>
                <span className={btnIconClass(activePage === "dashboard")}>
                  My Dashboard
                </span>
              </div>
            </div>
            <div className="cursor-pointer relative w-10 h-10">
              <div onClick={() => handleClick("custom-cv")} className={btnClass}>
                <div className="min-w-10 w-10 h-10 flex items-center justify-center">
                  <img
                    src={
                      activePage === "custom-cv" ? sparklesIconDark : sparklesIcon
                    }
                    alt="Sparkles"
                    className="h-5 w-5"
                  />
                </div>
                <span className={btnIconClass(activePage === "custom-cv")}>
                  Tailor CV
                </span>
              </div>
            </div>
            <div className="cursor-pointer relative w-10 h-10">
              <div onClick={() => handleClick("resume")} className={btnClass}>
                <div className="min-w-10 w-10 h-10 flex items-center justify-center">
                  <img
                    src={
                      activePage === "resume" ? analyticsIconDark : analyticsIcon
                    }
                    alt="Resume Builder"
                    className="h-5 w-5"
                  />
                </div>
                <span className={btnIconClass(activePage === "resume")}>
                  Resume builder
                </span>
              </div>
            </div>
          </div>

          <div className="w-10/12 border-t border-light-liliac opacity-30 mx-auto"></div>
          <div className="cursor-pointer relative w-10 h-10">
            <div onClick={openModal} className={btnClass}>
              <div className="min-w-10 w-10 h-10 flex items-center justify-center">
                <img
                  src={activePage === "feedback" ? feedbackIcon : feedbackIconDark}
                  alt="Feedback"
                  className="h-5 w-5"
                />
              </div>
              <span className={btnIconClass(activePage === "feedback")}>
                Feedback
              </span>
            </div>
          </div>
          <div className="cursor-pointer relative w-10 h-10">
            <div onClick={() => handleClick("settings")} className={btnClass}>
              <div className="min-w-10 w-10 h-10 flex items-center justify-center">
                <img
                  src={activePage === "settings" ? settingsIconDark : settingsIcon}
                  alt="Settings"
                  className="h-6 w-6"
                />
              </div>
              <span className={btnIconClass(activePage === "settings")}>
                Settings
              </span>
            </div>
          </div>
          <div className="cursor-pointer relative w-10 h-10">
            <div
              onClick={handleLogout}
              onMouseEnter={() => setLogoutHover(true)}
              onMouseLeave={() => setLogoutHover(false)}
              className={btnClass}
            >
              <div className="min-w-10 w-10 h-10 flex items-center justify-center">
                <img
                  src={logoutHover ? logoutIconDark : logoutIcon}
                  alt="Logout"
                  className="h-6 w-6"
                />
              </div>
              <span className={btnIconClass(activePage === "resume")}>
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
