import React from "react";
import ProductList from "./Payment.jsx";

function PlanPopup({ show, onClose, currentPlan }) {
  // If `show` is false, return nothing (i.e., no popup)
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded shadow-lg p-8 w-full max-w-3xl relative overflow-auto">
        <button
          onClick={onClose}
          className="absolute pr-2 top-2 right-2 text-gray-400 hover:text-gray-600 text-2xl font-bold"
        >
          &times;
        </button>

        <div className="text-center mb-6">
          <h2 className="text-2xl font-extrabold text-gray-800">
            Accelerate Your Job Search With{" "}
            <span className="text-purple">jobbyo TURBO</span>
          </h2>
          <p className="text-md text-gray-600">
            Get 3x More Interviews with Unlimited Access to the jobbyo TURBO
          </p>
        </div>

        <ProductList />

        <div className="mt-8 bg-white p-4 rounded-lg shadow-sm">
          <h4 className="text-xl font-bold text-center mb-2">jobbyo Turbo</h4>
          <p className="text-center text-sm text-gray-600 mb-4">
            Trusted by 100,000+ job seekers, our tools leverage methods from
            top experts and advanced AI to give you the best chance at
            interview success.
          </p>
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm text-left text-gray-700 border-collapse">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-100 font-bold"></th>
                  <th className="py-2 px-4 bg-gray-100 font-bold">
                    Free Plan
                    {!currentPlan?.name?.toUpperCase() && (
                      <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                        Current
                      </span>
                    )}
                  </th>
                  <th className="py-2 px-4 bg-gray-100 font-bold">
                    Starter
                    {currentPlan?.name?.toUpperCase() === "STARTER" && (
                      <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                        Current
                      </span>
                    )}
                  </th>
                  <th className="py-2 px-4 bg-gray-100 font-bold">
                    Growth
                    {currentPlan?.name?.toUpperCase() === "GROWTH" && (
                      <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                        Current
                      </span>
                    )}
                  </th>
                  <th className="py-2 px-4 bg-gray-100 font-bold">
                    Premium
                    {currentPlan?.name?.toUpperCase() === "PREMIUM" && (
                      <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                        Current
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="py-2 px-4">
                    AI Custom Resume Generation
                  </td>
                  <td className="py-2 px-4">1/day</td>
                  <td className="py-2 px-4">5/day</td>
                  <td className="py-2 px-4">15/day</td>
                  <td className="py-2 px-4">Unlimited</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 px-4">
                    AI Resume Builder Enhancement
                  </td>
                  <td className="py-2 px-4">1/day</td>
                  <td className="py-2 px-4">5/day</td>
                  <td className="py-2 px-4">15/day</td>
                  <td className="py-2 px-4">Unlimited</td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 px-4">Instant Job Alerts</td>
                  <td className="py-2 px-4">✖</td>
                  <td className="py-2 px-4">✖</td>
                  <td className="py-2 px-4">✖</td>
                  <td className="py-2 px-4">Unlimited</td>
                </tr>
                <tr>
                  <td className="py-2 px-4">
                    Exclusive Career Coach Support 1-1 monthly
                  </td>
                  <td className="py-2 px-4">✖</td>
                  <td className="py-2 px-4">✖</td>
                  <td className="py-2 px-4">✖</td>
                  <td className="py-2 px-4">Unlimited</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanPopup;
