import React, { useState, useEffect } from "react";
import useResumeStore from "../../../stores/resume/resumeStore";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { sendRequest } from "../../../utils/api.js";

const fadeInStyles = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}
`;

const monthToNumber = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const ResumeCreatorExperience = () => {
  const resume = useResumeStore((state) => state.resume);
  const updateResume = useResumeStore((state) => state.updateResume);

  const [searchParams, setSearchParams] = useSearchParams();
  const [experiences, setExperiences] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [unsavedExp, setUnsavedExp] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState(null);
  const [showImprovePopup, setShowImprovePopup] = useState(false);
  const [popupFormData, setPopupFormData] = useState({
    responsibilities: "",
    achievements: "",
    contextKeywords: "",
    tone: "",
    constraints: "",
  });
  const [isImproving, setIsImproving] = useState(false);
  const [typedDescription, setTypedDescription] = useState("");
  const [textFullyTyped, setTextFullyTyped] = useState(false);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1980 + 1 }, (_, i) => 1980 + i);
  const yearsOptions = years.map((year) => ({ value: String(year), label: String(year) }));
  const endYearsWithPresent = [{ value: "Present", label: "Present" }, ...yearsOptions];
  const monthsOptions = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  const buildDateString = (expObj) => {
    const { startMonth, startYear, endMonth, endYear } = expObj;
    if (!startMonth && !startYear && !endMonth && !endYear) return "";
    const startStr = [startMonth, startYear].filter(Boolean).join(" ");
    const endStr =
      endYear === "Present" ? "Present" : [endMonth, endYear].filter(Boolean).join(" ");
    return endStr ? `${startStr} - ${endStr}` : startStr;
  };

  useEffect(() => {
    if (!isInitialized && resume?.experiences) {
      setExperiences(resume.experiences);
      setUnsavedExp(resume.experiences[0]);
      setIsInitialized(true);
      setActiveTab(parseInt(searchParams.get("exp"), 10) || 0);
    }
  }, [resume.experiences, isInitialized, searchParams]);

  useEffect(() => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("tab", "experience");
      newParams.set("exp", activeTab);
      return newParams;
    });
    if (experiences[activeTab]) {
      setUnsavedExp({ ...experiences[activeTab] });
      setTypedDescription("");
      setTextFullyTyped(false);
    }
  }, [activeTab, experiences, setSearchParams]);

  useEffect(() => {
    if (unsavedExp && unsavedExp.date) {
      const [start, end] = unsavedExp.date.split(" - ");
      if (start && !unsavedExp.startYear && !unsavedExp.startMonth) {
        const startParts = start.split(" ");
        if (startParts.length === 2) {
          setUnsavedExp((prev) => ({
            ...prev,
            startMonth: startParts[0],
            startYear: startParts[1],
          }));
        } else if (startParts.length === 1) {
          setUnsavedExp((prev) => ({ ...prev, startYear: startParts[0] }));
        }
      }
      if (end && !unsavedExp.endYear && !unsavedExp.endMonth) {
        if (end === "Present") {
          setUnsavedExp((prev) => ({ ...prev, endYear: "Present" }));
        } else {
          const endParts = end.split(" ");
          if (endParts.length === 2) {
            setUnsavedExp((prev) => ({
              ...prev,
              endMonth: endParts[0],
              endYear: endParts[1],
            }));
          } else if (endParts.length === 1) {
            setUnsavedExp((prev) => ({ ...prev, endYear: endParts[0] }));
          }
        }
      }
    }
  }, [unsavedExp]);

  useEffect(() => {
    if (unsavedExp && typedDescription) {
      setUnsavedExp((prev) => ({ ...prev, description: typedDescription }));
    }
  }, [typedDescription, unsavedExp]);

  const handleSaveInfo = () => {
    if (!unsavedExp) return;
    const updated = { ...unsavedExp };
    updated.date = buildDateString(unsavedExp);
    updated.startYearNumeric = parseInt(unsavedExp.startYear) || 0;
    updated.startMonthNumeric = monthToNumber[unsavedExp.startMonth] || 0;
    if (unsavedExp.endYear === "Present") {
      updated.endYearNumeric = 9999;
      updated.endMonthNumeric = 12;
    } else {
      updated.endYearNumeric = parseInt(unsavedExp.endYear) || 0;
      updated.endMonthNumeric = monthToNumber[unsavedExp.endMonth] || 0;
    }
    const updatedExperiences = experiences.map((exp, idx) =>
      idx === activeTab
        ? {
            ...updated,
            saved: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
          }
        : exp
    );
    setExperiences(updatedExperiences);
    updateResume({ ...resume, experiences: updatedExperiences });
    toast.success("Experience saved successfully!");
  };

  const handleInputChange = (input) => {
    const dateFields = ["startMonth", "startYear", "endMonth", "endYear"];
    if (dateFields.includes(input.type)) {
      setUnsavedExp((prev) => ({ ...prev, [input.type]: input.value }));
    } else if (input.target) {
      const { name, value } = input.target;
      setUnsavedExp((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddExperience = () => {
    const newExperience = {
      title: "",
      company: "",
      date: "",
      location: "",
      description: "",
      saved: "Not saved yet",
      startYearNumeric: 0,
      startMonthNumeric: 0,
      endYearNumeric: 0,
      endMonthNumeric: 0,
    };
    setExperiences((prev) => [...prev, newExperience]);
    setUnsavedExp(newExperience);
    setActiveTab(experiences.length);
    setTypedDescription("");
    setTextFullyTyped(false);
  };

  const openImproveDescriptionPopup = () => setShowImprovePopup(true);
  const closeImproveDescriptionPopup = () => {
    setShowImprovePopup(false);
    setPopupFormData({
      responsibilities: "",
      achievements: "",
      contextKeywords: "",
      tone: "",
      constraints: "",
    });
  };

  const handleImproveDescriptionFromPopup = async (e) => {
    e.preventDefault();
    if (!unsavedExp) return;
    setIsImproving(true);
    closeImproveDescriptionPopup();
    try {
      const { responsibilities, achievements, contextKeywords, tone, constraints } = popupFormData;
      const combinedPrompt = `I want to enhance the following résumé work experience description to make it more impactful, 
      concise, and results-oriented. Please focus on clarifying achievements, highlighting measurable outcomes, and using active language.
      Here are the details you have to work with:

      Key Responsibilities: ${responsibilities}
      Major Achievements: ${achievements}
      Relevant Context or Industry Keywords: ${contextKeywords}
      Desired Tone or Style: ${tone}
      Length or Format Constraints: ${constraints}

      Original Description: ${unsavedExp.description}

      At the end, please provide an enhanced resume bullet list that emphasizes achievements, results, and relevant keywords.`;

      const payload = {
        experience_gap: combinedPrompt,
        exp_description: unsavedExp.description || "",
      };

      const response = await sendRequest(payload, "/create-experience");
      if (response.error) {
        toast.error(response.error);
        setIsImproving(false);
        return;
      }
      let newText = response.resume_data.experience_updated || "";
      if (newText.trim().startsWith("{")) {
        try {
          const maybeParsed = JSON.parse(newText);
          if (maybeParsed.experience_updated) {
            newText = maybeParsed.experience_updated;
          }
        } catch (e) {
          // Fallback to original if parse fails
        }
      }
      setTypedDescription("");
      setTextFullyTyped(false);
      const chunkSize = 10;
      const intervalTime = 100;
      let index = 0;
      const typeInterval = setInterval(() => {
        const nextChunk = newText.substring(index, index + chunkSize);
        index += chunkSize;
        setTypedDescription((prev) => prev + nextChunk);
        if (index >= newText.length) {
          clearInterval(typeInterval);
          setTextFullyTyped(true);
          setIsImproving(false);
          toast.success("Description improved successfully!");
        }
      }, intervalTime);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong while improving description.");
      setIsImproving(false);
    }
  };

  const handleDeleteExperience = () => {
    const updatedExperiences = experiences.filter((_, index) => index !== experienceToDelete);
    setExperiences(updatedExperiences);
    setShowDeleteModal(false);
    if (activeTab >= updatedExperiences.length) {
      setActiveTab(updatedExperiences.length - 1);
    }
    updateResume({ ...resume, experiences: updatedExperiences });
    toast.success("Experience deleted successfully!");
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleOpenDeleteModal = (index) => {
    setExperienceToDelete(index);
    setShowDeleteModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isImproving) return;
    handleSaveInfo();
  };

  const handlePopupInputChange = (e) => {
    const { name, value } = e.target;
    setPopupFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <style>{fadeInStyles}</style>
      <div className={`min-h-screen ${isImproving ? "opacity-50 pointer-events-none" : ""}`}>
        <ToastContainer position={"top-center"} autoClose={1000} hideProgressBar={true} />
        <div className="flex flex-wrap text-nowrap gap-4 mb-6">
          {experiences.map((exp, i) => (
            <div
              key={i}
              className="flex items-center gap-2 rounded-md border-[1px] border-gray-200 px-2 py-1"
            >
              <button
                onClick={() => handleTabChange(i)}
                className={`text-sm text-purple ${activeTab === i ? "font-bold" : ""}`}
              >
                {exp.title || `Experience ${i + 1}`}
              </button>
              <button
                type="button"
                onClick={() => handleOpenDeleteModal(i)}
                className="text-red-500 text-xs"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
        <Tooltip id="tooltip" />
        <form onSubmit={handleSubmit}>
          {unsavedExp && (
            <div key={activeTab} className="space-y-4">
              <div>
                <label htmlFor={`title-${activeTab}`} className="text-sm font-bold text-gray-700">
                  WHAT WAS YOUR ROLE AT THE COMPANY?
                </label>
                <input
                  required
                  type="text"
                  id={`title-${activeTab}`}
                  name="title"
                  value={unsavedExp.title || ""}
                  onChange={handleInputChange}
                  placeholder="Role"
                  className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
                />
              </div>
              <div>
                <label htmlFor={`company-${activeTab}`} className="text-sm font-bold text-gray-700">
                  COMPANY NAME
                </label>
                <input
                  type="text"
                  id={`company-${activeTab}`}
                  name="company"
                  value={unsavedExp.company || ""}
                  onChange={handleInputChange}
                  placeholder="Company Name"
                  className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm font-bold text-gray-700">
                  HOW LONG WERE YOU IN THE COMPANY?
                </label>
                <div className="flex flex-wrap gap-4">
                  <div>
                    <label className="block text-xs text-gray-600 mb-1">Start Month</label>
                    <Select
                      id={`startMonth-${activeTab}`}
                      name="startMonth"
                      options={monthsOptions}
                      value={
                        unsavedExp.startMonth
                          ? { value: unsavedExp.startMonth, label: unsavedExp.startMonth }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleInputChange({
                          type: "startMonth",
                          value: selectedOption?.value || "",
                        })
                      }
                      placeholder="Start Month"
                      classNamePrefix="react-select"
                    />
                  </div>
                  <div>
                    <label className="block text-xs text-gray-600 mb-1">Start Year</label>
                    <Select
                      id={`startYear-${activeTab}`}
                      name="startYear"
                      options={yearsOptions}
                      value={
                        unsavedExp.startYear
                          ? { value: unsavedExp.startYear, label: unsavedExp.startYear }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleInputChange({
                          type: "startYear",
                          value: selectedOption?.value || "",
                        })
                      }
                      placeholder="Start Year"
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-4 mt-2">
                  <div>
                    <label className="block text-xs text-gray-600 mb-1">End Month</label>
                    <Select
                      id={`endMonth-${activeTab}`}
                      name="endMonth"
                      options={monthsOptions}
                      value={
                        unsavedExp.endMonth
                          ? { value: unsavedExp.endMonth, label: unsavedExp.endMonth }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleInputChange({
                          type: "endMonth",
                          value: selectedOption?.value || "",
                        })
                      }
                      placeholder="End Month"
                      isDisabled={unsavedExp.endYear === "Present"}
                      classNamePrefix="react-select"
                    />
                  </div>
                  <div>
                    <label className="block text-xs text-gray-600 mb-1">End Year (or Present)</label>
                    <Select
                      id={`endYear-${activeTab}`}
                      name="endYear"
                      options={endYearsWithPresent}
                      value={
                        unsavedExp.endYear
                          ? { value: unsavedExp.endYear, label: unsavedExp.endYear }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleInputChange({
                          type: "endYear",
                          value: selectedOption?.value || "",
                        })
                      }
                      placeholder="End Year"
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor={`location-${activeTab}`} className="text-sm font-bold text-gray-700">
                  WHERE WAS THE COMPANY LOCATED?
                </label>
                <input
                  type="text"
                  id={`location-${activeTab}`}
                  name="location"
                  value={unsavedExp.location || ""}
                  onChange={handleInputChange}
                  placeholder="Location"
                  className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
                />
              </div>
              <div>
                <label htmlFor={`description-${activeTab}`} className="flex text-sm font-bold text-gray-700">
                  WHAT DID YOU DO AT THE COMPANY?
                  <button
                    type="button"
                    onClick={openImproveDescriptionPopup}
                    className="flex items-center border border-purple rounded-xl ms-2 pl-2 pr-2"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Tailor AI suggestions for this section"
                  >
                    <svg
                      className="mr-1"
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5775 9.69376L14.6451 10.4064C13.0806 10.9833 11.8474 12.2165 11.2705 13.7809L10.5578 15.7134C10.531 15.786 10.4617 15.8343 10.3843 15.8343C10.3068 15.8343 10.2376 15.786 10.2108 15.7134L9.49803 13.781C8.9211 12.2165 7.68784 10.9833 6.12347 10.4064L4.19097 9.69376C4.11832 9.66697 4.07007 9.5977 4.07007 9.52026C4.07007 9.44282 4.11832 9.37355 4.19097 9.34675L6.12358 8.63389C7.68795 8.05696 8.9211 6.82381 9.49803 5.25944L10.2108 3.32683C10.2376 3.25418 10.3068 3.20593 10.3843 3.20593C10.4617 3.20593 10.531 3.25418 10.5578 3.32683L11.2705 5.25944C11.8475 6.82381 13.0806 8.05707 14.645 8.63389L16.5776 9.34675C16.6503 9.37355 16.6985 9.44282 16.6985 9.52026C16.6985 9.5977 16.6501 9.66697 16.5775 9.69376Z"
                        fill="#3F33C0"
                      />
                      <path
                        d="M7.02037 15.089L6.10417 15.4269C5.36255 15.7004 4.778 16.2849 4.50462 17.0266L4.16665 17.9425C4.15402 17.9771 4.12112 17.9999 4.08442 17.9999C4.04771 17.9999 4.01482 17.9771 4.00219 17.9426L3.66433 17.0268C3.39084 16.2852 2.80617 15.7005 2.06456 15.427L1.14835 15.0891C1.11394 15.0765 1.09106 15.0436 1.09106 15.0069C1.09106 14.9702 1.11394 14.9373 1.14835 14.9247L2.06445 14.5867C2.80617 14.3132 3.39084 13.7286 3.66433 12.9868L4.00219 12.0708C4.01482 12.0364 4.04771 12.0136 4.08442 12.0136C4.12112 12.0136 4.15402 12.0364 4.16665 12.0708L4.50462 12.9869C4.77811 13.7287 5.36266 14.3132 6.10438 14.5866L7.02048 14.9246C7.0549 14.9372 7.07777 14.9701 7.07777 15.0068C7.07777 15.0435 7.0549 15.0764 7.02037 15.089Z"
                        fill="#3F33C0"
                      />
                      <path
                        d="M7.45795 4.80257L6.30561 5.22735C5.37285 5.57131 4.63745 6.30671 4.29327 7.23958L3.86839 8.39192C3.85238 8.43516 3.8111 8.46402 3.76492 8.46402C3.71874 8.46402 3.67746 8.43516 3.66145 8.39192L3.23656 7.23969C2.89261 6.30682 2.1572 5.57141 1.22422 5.22735L0.0721028 4.80257C0.0286451 4.78667 0 4.74528 0 4.6991C0 4.65292 0.028754 4.61153 0.0721028 4.59563L1.22433 4.17064C2.15709 3.82668 2.8925 3.09128 3.23656 2.15841L3.66145 1.00607C3.67746 0.962719 3.71874 0.933967 3.76492 0.933967C3.8111 0.933967 3.85249 0.962719 3.86839 1.00607L4.29338 2.15851C4.63734 3.09127 5.37263 3.82657 6.3055 4.17064L7.45795 4.59563C7.5013 4.61153 7.53005 4.65292 7.53005 4.6991C7.53005 4.74528 7.50141 4.78667 7.45795 4.80257Z"
                        fill="#3F33C0"
                      />
                    </svg>
                    Generate with AI
                  </button>
                </label>
                <textarea
                  id={`description-${activeTab}`}
                  name="description"
                  value={unsavedExp.description || ""}
                  onChange={handleInputChange}
                  placeholder="• Describe your tasks and achievements"
                  className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple ${
                    textFullyTyped ? "fade-in" : ""
                  }`}
                  rows={4}
                  disabled={isImproving}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-between mt-6">
            <button
              type="button"
              onClick={handleAddExperience}
              className="flex items-center px-3 -mx-3 gap-2 py-2 text-purple"
              disabled={isImproving}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 
                  16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 
                  8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 
                  5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 
                  15.1571 5.87827 16 8 16ZM9 5C9 4.73478 8.89464 4.48043 
                  8.70711 4.29289C8.51957 4.10536 8.26522 4 8 4C7.73478 
                  4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 
                  4.73478 7 5V7H5C4.73478 7 4.48043 7.10536 4.29289 
                  7.29289C4.10536 7.48043 4 7.73478 4 8C4 8.26522 
                  4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 
                  4.73478 9 5 9H7V11C7 11.2652 7.10536 11.5196 7.29289 
                  11.7071C7.48043 11.8946 7.73478 12 8 12C8.26522 
                  12 8.51957 11.8946 8.70711 11.7071C8.89464 
                  11.5196 9 11.2652 9 11V9H11C11.2652 9 11.5196 8.89464 
                  11.7071 8.70711C11.8946 8.51957 12 8.26522 
                  12 8C12 7.73478 11.89464 7.48043 11.7071 
                  7.29289C11.5196 7.10536 11.2652 7 11 
                  7H9V5Z"
                  fill="currentColor"
                />
              </svg>
              Add new
            </button>
            <button
              type="submit"
              className="px-6 py-3 bg-purple text-white rounded-md shadow-md focus:ring"
              disabled={isImproving}
            >
              Save info
            </button>
          </div>
          <p className="text-xs text-gray-400 text-right mt-3">
            Saved: {experiences[activeTab]?.saved || "Not saved yet"}
          </p>
        </form>
        {showDeleteModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96">
              <h3 className="text-lg font-bold mb-4">Delete Experience</h3>
              <p className="text-sm text-gray-600 mb-6">
                Are you sure you want to delete this experience? This action cannot be undone.
              </p>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteExperience}
                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {showImprovePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <form onSubmit={handleImproveDescriptionFromPopup} className="bg-white rounded-lg p-6 w-96 space-y-4 relative">
            <h3 className="text-lg font-bold mb-4">Tailor AI Suggestions</h3>
            <div>
              <label className="block text-sm font-bold text-gray-700">
                Key Responsibilities (Optional)
              </label>
              <textarea
                name="responsibilities"
                value={popupFormData.responsibilities}
                onChange={handlePopupInputChange}
                placeholder="• Managed social media accounts..."
                className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
                rows={2}
              />
            </div>
            <div>
              <label className="block text-sm font-bold text-gray-700">
                Major Achievements (Optional)
              </label>
              <textarea
                name="achievements"
                value={popupFormData.achievements}
                onChange={handlePopupInputChange}
                placeholder="• Increased engagement by 40%..."
                className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
                rows={2}
              />
            </div>
            <div>
              <label className="block text-sm font-bold text-gray-700">
                Relevant Context or Industry Keywords (Optional)
              </label>
              <input
                type="text"
                name="contextKeywords"
                value={popupFormData.contextKeywords}
                onChange={handlePopupInputChange}
                placeholder="e.g. digital marketing, ROI..."
                className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
              />
            </div>
            <div>
              <label className="block text-sm font-bold text-gray-700">
                Desired Tone or Style (Optional)
              </label>
              <input
                type="text"
                name="tone"
                value={popupFormData.tone}
                onChange={handlePopupInputChange}
                placeholder="e.g. concise, professional..."
                className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
              />
            </div>
            <div>
              <label className="block text-sm font-bold text-gray-700">
                Length or Format Constraints (Optional)
              </label>
              <input
                type="text"
                name="constraints"
                value={popupFormData.constraints}
                onChange={handlePopupInputChange}
                placeholder="e.g. keep each bullet to 1 line..."
                className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
              />
            </div>
            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                onClick={closeImproveDescriptionPopup}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
              >
                Cancel
              </button>
              <button type="submit" className="px-4 py-2 bg-purple text-white rounded-md">
                Improve
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ResumeCreatorExperience;
