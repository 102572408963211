const templates = [
  {
    sponsorship: "",
    last_name: "Williams",
    first_name: "Avery",
    linkedin: "www.linkedin.com/in/avery-williams-ux",
    title: "UX Designer",
    summary:
      "Enthusiastic UX Designer with over 4 years of experience creating intuitive user flows and impactful research insights for FinTech, eCommerce, and SaaS products.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "French", level: "Limited Working" }
    ],
    experiences: [
      {
        company: "Google",
        date: "January 2023 - Present (1 year)",
        location: "Remote, USA",
        title: "UX Designer",
        description:
          "Led end-to-end user research and design strategy for high-profile FinTech clients. Collaborated with product managers and engineering to refine wireframes and prototypes, improving user satisfaction by 35%. Introduced a new design system library in Figma to standardize UI components and accelerate development."
      },
      {
        title: "Product Designer",
        location: "New York, USA",
        company: "BlueSky Tech",
        date: "August 2021 - December 2022 (1 year 5 months)",
        description:
          "Collaborated with cross-functional teams to redesign a flagship mobile application, boosting retention by 40%. Conducted usability testing sessions, synthesizing findings into actionable design improvements. Mentored junior designers on best practices for prototyping and design thinking."
      }
    ],
    email: "avery.williams@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+1 202-555-0147",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Conducted user research and testing for a new telehealth platform, driving design from concept to launch. Implemented iterative design sprints that led to a 25% decrease in user drop-off during onboarding.",
        url: "",
        title: "TeleHealth UX Revamp"
      }
    ],
    gender: "",
    city: "Seattle",
    zip: "98101",
    skills: [
      {
        list: ["User Research", "Wireframing", "Prototyping", "Figma", "Design Systems"]
      }
    ],
    education: [
      {
        institution: "University of Washington",
        date: "2013 - 2017",
        degree: "Bachelor's degree, Human-Centered Design"
      }
    ],
    state: "Washington",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Nguyen",
    first_name: "Brandon",
    linkedin: "www.linkedin.com/in/brandon-nguyen-dev",
    title: "Full-Stack Developer",
    summary:
      "Full-stack engineer with a focus on scalable web applications and cloud integrations, bringing innovative solutions to global clients.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Vietnamese", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "Google",
        date: "June 2021 - Present (2 years 7 months)",
        location: "Remote, Germany",
        title: "Full-Stack Developer",
        description:
          "Develops and maintains web applications serving millions of users across Europe and Asia. Implements CI/CD pipelines for seamless deployment. Optimized back-end services to reduce latency by 30%, and integrated multiple microservices for a more robust architecture."
      },
      {
        title: "Front-End Developer",
        location: "Ho Chi Minh City, Vietnam",
        company: "Tech Vision",
        date: "January 2020 - May 2021 (1 year 5 months)",
        description:
          "Built responsive user interfaces with React and integrated RESTful APIs for enterprise clients. Enhanced page load performance by implementing code splitting and lazy loading. Collaborated with UX designers to achieve consistent branding and user-centric experiences."
      }
    ],
    email: "brandon.nguyen@example.com",
    ethnicity: "",
    website: "www.brandondev.com",
    github: "github.com/brandonnguyen",
    phone: "+49 30 1234567",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Developed an online platform to match volunteer teachers with remote students globally. Handled front-end implementation, integrated a secure payment gateway, and implemented analytics tracking to boost user engagement by 20%.",
        url: "https://globalclassroom.org",
        title: "GlobalClassroom"
      }
    ],
    gender: "",
    city: "Berlin",
    zip: "10115",
    skills: [
      {
        list: ["React", "Node.js", "MongoDB", "Docker", "AWS"]
      }
    ],
    education: [
      {
        institution: "RMIT University Vietnam",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Information Technology"
      }
    ],
    state: "Berlin",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Smith",
    first_name: "Daniel",
    linkedin: "www.linkedin.com/in/daniel-smith-datasci",
    title: "Data Scientist",
    summary:
      "Data Scientist passionate about predictive modeling and machine learning, with a strong track record of improving healthcare outcomes through AI-driven insights.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Spanish", level: "Intermediate" }
    ],
    experiences: [
      {
        company: "Analytics Now",
        date: "May 2022 - Present (1 year 8 months)",
        location: "Remote, Canada",
        title: "Data Scientist",
        description:
          "Developed machine learning models for predictive analytics in healthcare, resulting in a 20% reduction in hospitalization rates. Led a cross-functional team to deploy an end-to-end data pipeline on AWS, reducing data processing time by 45%. Provided actionable insights through data visualization dashboards."
      },
      {
        title: "Junior Data Analyst",
        location: "Toronto, Canada",
        company: "DataGen",
        date: "July 2020 - April 2022 (1 year 10 months)",
        description:
          "Managed data pipelines for high-volume ecommerce clients. Performed exploratory data analysis using Python and SQL. Automated data cleaning processes and built interactive dashboards to reveal customer purchasing patterns and growth opportunities."
      }
    ],
    email: "daniel.smith@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/dsmith-data",
    phone: "+1 416-555-0199",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Built and optimized a recommendation system for a major online retailer, increasing cross-sell conversions by 15%. Employed collaborative filtering and gradient boosting techniques to provide real-time suggestions to customers.",
        url: "",
        title: "E-Commerce Recommendation Engine"
      }
    ],
    gender: "",
    city: "Toronto",
    zip: "M5H 2N2",
    skills: [
      {
        list: ["Python", "TensorFlow", "SQL", "Pandas", "Statistical Analysis"]
      }
    ],
    education: [
      {
        institution: "University of Toronto",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Computer Science"
      }
    ],
    state: "Ontario",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Perez",
    first_name: "Olivia",
    linkedin: "www.linkedin.com/in/olivia-perez-product",
    title: "Product Manager",
    summary:
      "Product Manager with expertise in cross-functional leadership and roadmap execution, delivering exceptional user experiences in the travel industry.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "German", level: "Basic" }
    ],
    experiences: [
      {
        company: "GlobeTrek",
        date: "March 2023 - Present (10 months)",
        location: "Remote, Spain",
        title: "Product Manager",
        description:
          "Manages the product roadmap for a global travel application, coordinating with engineering, design, and marketing teams across multiple time zones. Implemented data-driven prioritization frameworks that increased feature adoption by 35%. Established key metrics to measure user satisfaction and retention."
      },
      {
        title: "Associate Product Manager",
        location: "Madrid, Spain",
        company: "InnovateNow",
        date: "January 2021 - February 2023 (2 years 2 months)",
        description:
          "Assisted in product strategy and go-to-market planning for a CRM platform, resulting in a 25% increase in adoption. Organized regular user feedback sessions to iterate on feature improvements. Owned backlog grooming and sprint planning, aligning closely with Agile best practices."
      }
    ],
    email: "olivia.perez@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+34 611 234 567",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Launched a new feature for real-time traveler tips based on geolocation data, boosting daily active users by 20%. Oversaw user story mapping and release strategies.",
        url: "https://www.globetrek.com/tips",
        title: "Real-Time Travel Tips"
      }
    ],
    gender: "",
    city: "Madrid",
    zip: "28001",
    skills: [
      {
        list: ["Product Roadmapping", "Agile Methodologies", "User Story Mapping", "A/B Testing"]
      }
    ],
    education: [
      {
        institution: "Complutense University of Madrid",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Business Administration"
      }
    ],
    state: "Madrid",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Kim",
    first_name: "Sophia",
    linkedin: "www.linkedin.com/in/sophia-kim-webdev",
    title: "Front-End Web Developer",
    summary:
      "Front-end specialist skilled in modern JavaScript frameworks, dedicated to crafting high-performance, user-centric web experiences.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Korean", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "Frontiers",
        date: "September 2022 - Present (1 year 4 months)",
        location: "Seoul, South Korea",
        title: "Front-End Web Developer",
        description:
          "Develops responsive and SEO-optimized websites for multiple SaaS clients, focusing on performance metrics. Migrated legacy codebases to modern frameworks, improving Time to Interactive by 40%. Collaborates with UX designers to refine layout and accessibility."
      },
      {
        title: "Junior Web Developer",
        location: "Remote, Global",
        company: "Dev & Co.",
        date: "June 2021 - August 2022 (1 year 3 months)",
        description:
          "Built dynamic web interfaces using React and Redux, delivering high-quality user experiences. Implemented automated testing using Jest and Cypress. Provided detailed code reviews and guidance for best practices."
      }
    ],
    email: "sophia.kim@example.com",
    ethnicity: "",
    website: "www.sophiakim.dev",
    github: "github.com/sophiakim",
    phone: "+82 10-9876-5432",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Created a personal portfolio showcasing 20+ single-page applications with advanced animations and transitions. Implemented SEO best practices to rank in top search results for 'frontend developer portfolio.'",
        url: "www.sophiakim.dev/portfolio",
        title: "Sophia's Portfolio"
      }
    ],
    gender: "",
    city: "Seoul",
    zip: "04524",
    skills: [
      {
        list: ["HTML", "CSS", "React", "Next.js", "SEO"]
      }
    ],
    education: [
      {
        institution: "Korea University",
        date: "2017 - 2021",
        degree: "Bachelor's degree, Computer Science"
      }
    ],
    state: "Seoul",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Johnson",
    first_name: "Emily",
    linkedin: "www.linkedin.com/in/emily-johnson-remote",
    title: "Digital Marketing Specialist",
    summary:
      "Results-driven marketing professional with a focus on digital advertising strategies, social media optimization, and lead generation for remote-first businesses.",
    languages: [{ language: "English", level: "Full Professional" }],
    experiences: [
      {
        company: "Nomad Marketing",
        date: "April 2022 - Present (1 year 9 months)",
        location: "Remote, United Kingdom",
        title: "Digital Marketing Specialist",
        description:
          "Built targeted digital campaigns for remote-first startups, increasing email open rates by 25% and conversion funnels by 15%. Pioneered AB testing strategies using Google Analytics and optimized paid ads across multiple platforms."
      },
      {
        title: "Social Media Coordinator",
        location: "London, UK",
        company: "BrandHive",
        date: "May 2020 - March 2022 (1 year 11 months)",
        description:
          "Managed social media presence for 5 international clients, growing engagement by 35%. Devised content calendars, tracked KPIs, and coordinated influencer partnerships, boosting brand awareness in emerging markets."
      }
    ],
    email: "emily.johnson@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+44 20 7946 0018",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Increased lead generation by 25% for an ecommerce startup through targeted social ads and landing page optimization. Implemented robust keyword analysis to ensure high-quality traffic.",
        url: "",
        title: "Ecommerce Growth Campaign"
      }
    ],
    gender: "",
    city: "London",
    zip: "EC1A 1AA",
    skills: [
      {
        list: ["SEO", "PPC Campaigns", "Google Analytics", "Copywriting", "Social Media Management"]
      }
    ],
    education: [
      {
        institution: "University of Manchester",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Marketing Management"
      }
    ],
    state: "England",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Garcia",
    first_name: "David",
    linkedin: "www.linkedin.com/in/david-garcia-cybersec",
    title: "Cybersecurity Analyst",
    summary:
      "Cybersecurity expert specializing in penetration testing, vulnerability management, and cloud infrastructure security.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Spanish", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "SecurePlus",
        date: "June 2022 - Present (1 year 7 months)",
        location: "Remote, Mexico",
        title: "Cybersecurity Analyst",
        description:
          "Conducts comprehensive vulnerability assessments and penetration tests for large-scale cloud systems. Streamlined incident response procedures, reducing average resolution time by 40%. Coordinates with DevOps to embed security best practices into CI/CD pipelines."
      },
      {
        title: "IT Security Intern",
        location: "Mexico City, Mexico",
        company: "SafeNet Corp",
        date: "January 2021 - May 2022 (1 year 5 months)",
        description:
          "Monitored network security incidents and performed risk assessments. Assisted in the development of automated threat detection scripts. Participated in regular security audits to ensure compliance with industry standards."
      }
    ],
    email: "david.garcia@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/dgarcia-sec",
    phone: "+52 55 1234 5678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Discovered and patched critical vulnerabilities in multiple client-facing applications, implementing advanced intrusion detection systems. Collaborated with developers to fortify code reviews and minimize threat surfaces.",
        url: "",
        title: "Security Patch Implementation"
      }
    ],
    gender: "",
    city: "Mexico City",
    zip: "01000",
    skills: [
      {
        list: ["Penetration Testing", "Network Security", "Cloud Security", "Kali Linux", "Python"]
      }
    ],
    education: [
      {
        institution: "Tecnológico de Monterrey",
        date: "2017 - 2021",
        degree: "Bachelor's degree, Computer Engineering"
      }
    ],
    state: "CDMX",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Brown",
    first_name: "Eliza",
    linkedin: "www.linkedin.com/in/eliza-brown-ai",
    title: "AI Engineer",
    summary:
      "AI Engineer experienced in designing, training, and deploying large-scale language and reinforcement learning models to automate complex tasks.",
    languages: [{ language: "English", level: "Full Professional" }],
    experiences: [
      {
        company: "NeuralNet Inc.",
        date: "February 2023 - Present (11 months)",
        location: "Remote, USA",
        title: "AI Engineer",
        description:
          "Developing advanced language models for customer support chatbots, cutting average response times by 50%. Works with cross-functional teams to identify automation opportunities. Implements MLOps best practices for continuous model improvements."
      },
      {
        title: "Machine Learning Researcher",
        location: "Boston, MA",
        company: "DataWise",
        date: "July 2020 - January 2023 (2 years 7 months)",
        description:
          "Researched and implemented deep reinforcement learning algorithms for real-time decision-making systems. Built custom neural architectures that increased model accuracy by 15%. Published findings in peer-reviewed AI journals."
      }
    ],
    email: "eliza.brown@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/elizab-ai",
    phone: "+1 617-555-1234",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Implemented a speech recognition model achieving 95% accuracy on a proprietary dataset of 500k audio samples. Integrated the model into a voice-driven application for real-time transcription.",
        url: "",
        title: "Speech Recognition Model"
      }
    ],
    gender: "",
    city: "Boston",
    zip: "02108",
    skills: [
      {
        list: ["Python", "PyTorch", "NLP", "Reinforcement Learning"]
      }
    ],
    education: [
      {
        institution: "Massachusetts Institute of Technology",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Artificial Intelligence"
      }
    ],
    state: "Massachusetts",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Ivanova",
    first_name: "Tatiana",
    linkedin: "www.linkedin.com/in/tatiana-ivanova-vr",
    title: "VR Developer",
    summary:
      "VR Developer with 3+ years of experience creating immersive 3D simulations for training, education, and gaming applications.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Russian", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "ImmersiveWorld",
        date: "January 2022 - Present (2 years)",
        location: "Remote, Estonia",
        title: "VR Developer",
        description:
          "Builds cutting-edge VR simulations for training and education. Specializes in 3D modeling, user interaction, and physics simulation. Introduced a robust QA process for VR builds, reducing environment bugs by 25%."
      },
      {
        title: "Game Developer",
        location: "St. Petersburg, Russia",
        company: "PixelPlay Studios",
        date: "March 2020 - December 2021 (1 year 10 months)",
        description:
          "Created immersive gameplay experiences using Unity and C#. Engineered AI-driven NPC behaviors and integrated advanced rendering techniques for next-gen visuals."
      }
    ],
    email: "tatiana.ivanova@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/tivanovaVR",
    phone: "+372 5123 4567",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Developed an interactive VR museum tour using Unity and Oculus SDK, featuring realistic environmental interactions and multi-language support.",
        url: "",
        title: "VR Museum Experience"
      }
    ],
    gender: "",
    city: "Tallinn",
    zip: "10111",
    skills: [
      {
        list: ["Unity", "C#", "3D Modeling", "Oculus SDK", "Game Design"]
      }
    ],
    education: [
      {
        institution: "ITMO University",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Computer Science"
      }
    ],
    state: "Harju",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Adams",
    first_name: "Trevor",
    linkedin: "www.linkedin.com/in/trevor-adams-cloud",
    title: "Cloud Solutions Architect",
    summary:
      "Cloud Solutions Architect highly proficient in AWS, Terraform, and microservices, delivering scalable and cost-effective infrastructures.",
    languages: [{ language: "English", level: "Full Professional" }],
    experiences: [
      {
        company: "SkyHighCloud",
        date: "October 2022 - Present (1 year 3 months)",
        location: "Remote, Australia",
        title: "Cloud Solutions Architect",
        description:
          "Architects and maintains AWS-based cloud solutions for multiple microservice projects. Introduced cost-optimization strategies that saved 20% on monthly cloud spend. Mentors junior engineers on infrastructure as code best practices."
      },
      {
        title: "DevOps Engineer",
        location: "Sydney, Australia",
        company: "Oceanic Tech",
        date: "June 2020 - September 2022 (2 years 4 months)",
        description:
          "Managed CI/CD pipelines and automated infrastructure with Terraform and Kubernetes. Led the deployment of a new microservices architecture that improved service resilience and reduced downtime by 40%."
      }
    ],
    email: "trevor.adams@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/trevoradams",
    phone: "+61 2 1234 5678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Implemented a multi-region failover strategy, ensuring near-zero downtime. Automated failover testing with Terraform scripts, verifying DR readiness monthly.",
        url: "",
        title: "High Availability Cloud Architecture"
      }
    ],
    gender: "",
    city: "Sydney",
    zip: "2000",
    skills: [
      {
        list: ["AWS", "Terraform", "Kubernetes", "CI/CD", "Microservices"]
      }
    ],
    education: [
      {
        institution: "University of New South Wales",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Information Systems"
      }
    ],
    state: "New South Wales",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Miller",
    first_name: "Jordan",
    linkedin: "www.linkedin.com/in/jordan-miller-analytics",
    title: "Marketing Data Analyst",
    summary:
      "Marketing Data Analyst specializing in conversion optimization, user funnel analysis, and actionable insights for SaaS solutions.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "French", level: "Basic" }
    ],
    experiences: [
      {
        company: "MarketWise",
        date: "September 2021 - Present (2 years 4 months)",
        location: "Remote, France",
        title: "Marketing Data Analyst",
        description:
          "Drives marketing analytics strategies focused on funnel optimization, boosting conversions by 15%. Coordinates with product teams to define KPIs and measure campaign performance. Automated data extraction from multiple marketing channels to streamline reporting."
      },
      {
        title: "Digital Analyst",
        location: "San Francisco, CA",
        company: "TechVerse",
        date: "June 2019 - August 2021 (2 years 3 months)",
        description:
          "Analyzed user behavior data for enterprise SaaS, producing insights that improved upsell rates by 15%. Collaborated with marketing and product stakeholders to refine messaging and user experience. Introduced advanced segmentation techniques to pinpoint churn risks."
      }
    ],
    email: "jordan.miller@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+33 1 23 45 67 89",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Built an automated real-time dashboard for marketing campaign performance tracking using Tableau and custom SQL scripts. Reduced manual reporting time by 70%.",
        url: "",
        title: "Real-Time Dashboard"
      }
    ],
    gender: "",
    city: "Paris",
    zip: "75001",
    skills: [
      {
        list: ["Google Analytics", "SQL", "Excel", "Tableau", "Data Visualization"]
      }
    ],
    education: [
      {
        institution: "UC Berkeley",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Business Analytics"
      }
    ],
    state: "Île-de-France",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Chen",
    first_name: "Lily",
    linkedin: "www.linkedin.com/in/lily-chen-content",
    title: "Content Writer",
    summary:
      "Creative Content Writer skilled in SEO optimization, blogging, and social media strategy to drive brand storytelling.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Mandarin", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "RoamVerse",
        date: "May 2022 - Present (1 year 8 months)",
        location: "Remote, Singapore",
        title: "Content Writer",
        description:
          "Writes and curates digital nomad guides, increasing user engagement by 30%. Optimized site content via keyword research, boosting organic traffic by 25%. Developed editorial calendars to maintain consistent publishing schedules."
      },
      {
        title: "Copywriter",
        location: "Beijing, China",
        company: "Global Media",
        date: "August 2020 - April 2022 (1 year 9 months)",
        description:
          "Produced marketing copy for global brands across multiple channels. Collaborated with designers to create visually engaging campaigns. Leveraged analytics to refine tone and style for diverse audiences."
      }
    ],
    email: "lily.chen@example.com",
    ethnicity: "",
    website: "www.lily-chen-writes.com",
    github: "",
    phone: "+65 9123 4567",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Led a cross-country content campaign promoting sustainable travel, attracting 100k+ monthly readers. Featured interviews with eco-travel influencers and curated an interactive blog series.",
        url: "www.roamverse.com/sustainability",
        title: "Sustainable Travel Campaign"
      }
    ],
    gender: "",
    city: "Singapore",
    zip: "048583",
    skills: [
      {
        list: ["Content Strategy", "SEO Writing", "Blogging", "Social Media"]
      }
    ],
    education: [
      {
        institution: "National University of Singapore",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Communications"
      }
    ],
    state: "Singapore",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Walter",
    first_name: "Zara",
    linkedin: "www.linkedin.com/in/zara-walter-design",
    title: "Visual Designer",
    summary:
      "Visual Designer passionate about creating compelling brand identities, impactful marketing materials, and engaging user interfaces.",
    languages: [{ language: "English", level: "Full Professional" }],
    experiences: [
      {
        company: "PixelForge",
        date: "November 2022 - Present (1 year 2 months)",
        location: "Remote, USA",
        title: "Visual Designer",
        description:
          "Designs modern brand identities and websites for tech startups. Incorporates user feedback to refine aesthetics and usability. Successfully led design overhauls that boosted client brand recognition by 40%."
      },
      {
        title: "Graphic Designer",
        location: "Austin, TX",
        company: "Brandly",
        date: "June 2020 - October 2022 (2 years 5 months)",
        description:
          "Created visual concepts for marketing campaigns and promotional materials. Coordinated with print vendors and digital channels. Simplified design workflows to speed up project turnaround by 25%."
      }
    ],
    email: "zara.walter@example.com",
    ethnicity: "",
    website: "www.zarawalter.com",
    github: "",
    phone: "+1 512-555-8923",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Oversaw complete rebranding for a healthtech startup, including a new logo, brand guidelines, and promotional graphics. Utilized Illustrator and Figma to maintain design consistency.",
        url: "www.zarawalter.com/healthtech-rebrand",
        title: "HealthTech Rebrand"
      }
    ],
    gender: "",
    city: "Austin",
    zip: "73301",
    skills: [
      {
        list: ["Adobe Creative Suite", "Branding", "UI Design", "Illustration"]
      }
    ],
    education: [
      {
        institution: "Rhode Island School of Design",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Graphic Design"
      }
    ],
    state: "Texas",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Mustafa",
    first_name: "Ahmed",
    linkedin: "www.linkedin.com/in/ahmed-mustafa-blockchain",
    title: "Blockchain Developer",
    summary:
      "Blockchain Developer focused on secure and scalable decentralized applications, specializing in smart contracts and DeFi.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Arabic", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "BlockLabs",
        date: "February 2022 - Present (1 year 11 months)",
        location: "Remote, UAE",
        title: "Blockchain Developer",
        description:
          "Develops and audits smart contracts for decentralized finance applications, ensuring security and performance. Integrated multiple third-party oracles to enhance data reliability. Implemented a robust testing suite, reducing contract vulnerabilities by 80%."
      },
      {
        title: "Software Engineer",
        location: "Dubai, UAE",
        company: "Tech Innovations LLC",
        date: "August 2020 - January 2022 (1 year 6 months)",
        description:
          "Built enterprise microservices with Java and Spring Boot. Migrated monolithic solutions to scalable architectures, enabling better fault tolerance. Part of a core team that improved CI/CD efficiency by 25%."
      }
    ],
    email: "ahmed.mustafa@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/ahmedmustafa",
    phone: "+971 50 123 4567",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Launched a DeFi staking platform with an audited token smart contract. Configured yield farming mechanisms and built a governance model for community-driven updates.",
        url: "",
        title: "DeFi Staking Platform"
      }
    ],
    gender: "",
    city: "Dubai",
    zip: "",
    skills: [
      {
        list: ["Solidity", "Ethereum", "Smart Contracts", "Java", "Spring Boot"]
      }
    ],
    education: [
      {
        institution: "American University in Dubai",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Computer Science"
      }
    ],
    state: "Dubai",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Foster",
    first_name: "Jacob",
    linkedin: "www.linkedin.com/in/jacob-foster-sysadmin",
    title: "Systems Administrator",
    summary:
      "Systems Administrator experienced in managing Linux-based environments, ensuring high availability and robust security protocols.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Italian", level: "Basic" }
    ],
    experiences: [
      {
        company: "GlobalSys",
        date: "July 2022 - Present (1 year 6 months)",
        location: "Remote, Italy",
        title: "Systems Administrator",
        description:
          "Administers and optimizes Linux-based servers for global clients, achieving 99.9% uptime. Automated patch management using Ansible, reducing manual interventions by 60%. Implements monitoring tools (Nagios, Prometheus) to proactively detect service degradations."
      },
      {
        title: "IT Specialist",
        location: "Rome, Italy",
        company: "EuroTech",
        date: "February 2020 - June 2022 (2 years 5 months)",
        description:
          "Provided IT solutions and support for 200+ employees across Europe. Deployed virtualization solutions to consolidate hardware usage by 30%. Created documentation to standardize operational procedures."
      }
    ],
    email: "jacob.foster@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+39 06 1234 5678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Migrated legacy systems to a cloud-based infrastructure using VMware and AWS, cutting operational costs by 30%. Devised backup and disaster recovery plans to ensure data integrity.",
        url: "",
        title: "Cloud Migration"
      }
    ],
    gender: "",
    city: "Rome",
    zip: "00100",
    skills: [
      {
        list: ["Linux Administration", "Bash Scripting", "Virtualization", "Cloud Computing"]
      }
    ],
    education: [
      {
        institution: "University of London",
        date: "2014 - 2018",
        degree: "Bachelor's degree, Information Technology"
      }
    ],
    state: "Lazio",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Hernandez",
    first_name: "Penelope",
    linkedin: "www.linkedin.com/in/penelope-hernandez-datasci",
    title: "Data Scientist",
    summary:
      "Data Scientist devoted to creating data-driven solutions for financial forecasting and time-series modeling, enhancing predictive accuracy.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Spanish", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "Insightful Data",
        date: "August 2023 - Present (5 months)",
        location: "Remote, Argentina",
        title: "Data Scientist",
        description:
          "Develops time-series models for financial forecasting, reducing errors in revenue predictions by 18%. Implements advanced statistical techniques and Python-based frameworks. Provides data-driven insights to executive teams, guiding strategic decisions."
      },
      {
        title: "Data Analyst",
        location: "Buenos Aires, Argentina",
        company: "Analytix Co.",
        date: "January 2021 - July 2023 (2 years 7 months)",
        description:
          "Performed data cleaning, EDA, and provided actionable insights via dashboards. Automated reporting processes, freeing up analysts' time by 40%. Collaborated with engineering to deploy dashboards company-wide."
      }
    ],
    email: "penelope.hernandez@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/phernandez",
    phone: "+54 911 1234 5678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Implemented a deep learning model to predict stock prices, improving accuracy by 10% over baseline ARIMA methods. Used LSTM networks to analyze complex seasonality patterns.",
        url: "",
        title: "Stock Price Predictor"
      }
    ],
    gender: "",
    city: "Buenos Aires",
    zip: "C1000",
    skills: [
      {
        list: ["Python", "R", "Statistics", "Machine Learning", "Time-Series Analysis"]
      }
    ],
    education: [
      {
        institution: "University of Buenos Aires",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Statistics"
      }
    ],
    state: "Buenos Aires",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Okafor",
    first_name: "Chinedu",
    linkedin: "www.linkedin.com/in/chinedu-okafor-mobile",
    title: "Mobile Developer",
    summary:
      "Mobile Developer adept at cross-platform app creation, specializing in Flutter and Kotlin for seamless user experiences.",
    languages: [{ language: "English", level: "Full Professional" }],
    experiences: [
      {
        company: "AppDash",
        date: "December 2022 - Present (1 year 1 month)",
        location: "Remote, Nigeria",
        title: "Mobile Developer",
        description:
          "Leads development of cross-platform mobile apps using Flutter. Optimized app architecture to reduce crash rates by 30%. Oversees performance profiling and code reviews, ensuring maintainable, high-quality code."
      },
      {
        title: "Android Developer",
        location: "Lagos, Nigeria",
        company: "TechNinjas",
        date: "September 2019 - November 2022 (3 years 3 months)",
        description:
          "Developed native Android applications with Kotlin, integrating secure payment gateways and Google services. Implemented push notifications and real-time chat features. Conducted user testing to refine UI flows."
      }
    ],
    email: "chinedu.okafor@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/chinedu-okafor",
    phone: "+234 701 234 5678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Built a fintech app enabling instant peer-to-peer payments, with over 10,000 downloads in the first month. Implemented biometric authentication and offline caching for remote areas.",
        url: "",
        title: "PeerPay"
      }
    ],
    gender: "",
    city: "Lagos",
    zip: "",
    skills: [
      {
        list: ["Flutter", "Android", "Kotlin", "Firebase", "UI/UX"]
      }
    ],
    education: [
      {
        institution: "University of Lagos",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Software Engineering"
      }
    ],
    state: "Lagos",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Suzuki",
    first_name: "Haruto",
    linkedin: "www.linkedin.com/in/haruto-suzuki-robotics",
    title: "Robotics Engineer",
    summary:
      "Robotics Engineer with deep expertise in motion planning, control algorithms, and autonomous systems for industrial automation.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Japanese", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "RoboTech",
        date: "January 2023 - Present (1 year)",
        location: "Remote, Japan",
        title: "Robotics Engineer",
        description:
          "Develops advanced robotics solutions for warehouse automation, focusing on motion planning. Achieved 25% faster order fulfillment by optimizing robot path algorithms. Coordinates with hardware teams to integrate sensor fusion techniques."
      },
      {
        title: "Mechatronics Intern",
        location: "Tokyo, Japan",
        company: "Futura Robotics",
        date: "April 2021 - December 2022 (1 year 9 months)",
        description:
          "Assisted in designing robotic arms and control systems for manufacturing lines. Implemented robust PLC integration and tested new mechanical prototypes. Documented all findings to guide future research and development."
      }
    ],
    email: "haruto.suzuki@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/harutosuzuki",
    phone: "+81 3-1234-5678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Implemented a computer vision system for automated picking robots, improving pick accuracy by 25%. Integrated OpenCV and ROS for real-time object detection.",
        url: "",
        title: "Computer Vision for Picking Robots"
      }
    ],
    gender: "",
    city: "Tokyo",
    zip: "100-0001",
    skills: [
      {
        list: ["C++", "ROS", "Computer Vision", "Mechanical Design"]
      }
    ],
    education: [
      {
        institution: "University of Tokyo",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Mechanical Engineering"
      }
    ],
    state: "Tokyo",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Roshan",
    first_name: "Nisha",
    linkedin: "www.linkedin.com/in/nisha-roshan-dataeng",
    title: "Data Engineer",
    summary:
      "Data Engineer specialized in building high-volume data pipelines, ETL processes, and cloud-based data lakes to drive real-time analytics.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Hindi", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "Datamorph",
        date: "February 2022 - Present (1 year 11 months)",
        location: "Remote, India",
        title: "Data Engineer",
        description:
          "Builds scalable ETL pipelines processing millions of records daily. Implements Spark clusters on AWS EMR to handle big data workflows. Collaborates with Data Science teams to integrate ML models into production, reducing inference latency by 30%."
      },
      {
        title: "ETL Developer",
        location: "Bangalore, India",
        company: "TechFlow",
        date: "June 2020 - January 2022 (1 year 8 months)",
        description:
          "Developed ingestion frameworks to unify on-premises and cloud data sources. Streamlined data transformations with Kafka, improving real-time analytics. Maintained robust documentation for data lineage and governance."
      }
    ],
    email: "nisha.roshan@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/nishar",
    phone: "+91 98765 43210",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Migrated legacy data warehouse to a modern data lake solution using Spark and AWS EMR, increasing data processing efficiency by 40%. Implemented robust monitoring and alerting with CloudWatch.",
        url: "",
        title: "Data Lake Migration"
      }
    ],
    gender: "",
    city: "Bangalore",
    zip: "560001",
    skills: [
      {
        list: ["Python", "Spark", "AWS", "Kafka", "ETL"]
      }
    ],
    education: [
      {
        institution: "Indian Institute of Technology",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Computer Science"
      }
    ],
    state: "Karnataka",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Andersen",
    first_name: "Jonas",
    linkedin: "www.linkedin.com/in/jonas-andersen-business",
    title: "Startup Consultant",
    summary:
      "Startup Consultant guiding early-stage ventures with pitch decks, market research, and strategic growth initiatives.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Danish", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "StartupHQ",
        date: "September 2021 - Present (2 years 4 months)",
        location: "Remote, Denmark",
        title: "Startup Consultant",
        description:
          "Advises early-stage startups on fundraising strategies, pitch deck creation, and product-market fit. Helped clients secure over $4M in seed funding collectively. Conducts growth experiments to identify scalable revenue channels."
      },
      {
        title: "Business Analyst",
        location: "Copenhagen, Denmark",
        company: "Nordic Ventures",
        date: "January 2019 - August 2021 (2 years 8 months)",
        description:
          "Researched market trends and performed due diligence on startup investments. Prepared detailed financial models and competitor analyses. Collaborated with portfolio companies to refine go-to-market strategies."
      }
    ],
    email: "jonas.andersen@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+45 12 34 56 78",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Coached a cohort of 15 startups, leading to a total of $3 million in seed funding. Organized demo days and investor networking events to maximize deal flow.",
        url: "",
        title: "Startup Accelerator"
      }
    ],
    gender: "",
    city: "Copenhagen",
    zip: "1050",
    skills: [
      {
        list: ["Business Strategy", "Financial Analysis", "Growth Hacking", "Venture Capital"]
      }
    ],
    education: [
      {
        institution: "Copenhagen Business School",
        date: "2014 - 2018",
        degree: "Bachelor's degree, International Business"
      }
    ],
    state: "Capital Region",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Vasquez",
    first_name: "Marisol",
    linkedin: "www.linkedin.com/in/marisol-vasquez-project",
    title: "Scrum Master",
    summary:
      "Scrum Master adept at guiding global software teams to deliver high-quality products on time through Agile frameworks.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Spanish", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "AgileGurus",
        date: "June 2022 - Present (1 year 7 months)",
        location: "Remote, Colombia",
        title: "Scrum Master",
        description:
          "Oversees Agile ceremonies and project delivery for multiple international software projects. Introduced new sprint retrospective formats that increased team morale. Monitors sprint metrics and identifies process bottlenecks."
      },
      {
        title: "Project Coordinator",
        location: "Bogotá, Colombia",
        company: "SoftSolutions",
        date: "February 2020 - May 2022 (2 years 4 months)",
        description:
          "Coordinated cross-functional teams across multiple time zones, tracking project deliverables. Deployed Kanban boards and Gantt charts to improve transparency. Acted as a liaison between stakeholders, ensuring alignment on scope."
      }
    ],
    email: "marisol.vasquez@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+57 1 2345678",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Implemented Agile frameworks for a mid-sized financial tech company, reducing release cycles by 20%. Provided training sessions for team leads on Scrum and Kanban processes.",
        url: "",
        title: "Agile Transformation"
      }
    ],
    gender: "",
    city: "Bogotá",
    zip: "110111",
    skills: [
      {
        list: ["Scrum", "Kanban", "Project Management", "Team Leadership"]
      }
    ],
    education: [
      {
        institution: "Universidad Nacional de Colombia",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Industrial Engineering"
      }
    ],
    state: "Bogotá",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Rossi",
    first_name: "Matteo",
    linkedin: "www.linkedin.com/in/matteo-rossi-ar",
    title: "Augmented Reality Developer",
    summary:
      "Augmented Reality Developer crafting immersive AR solutions for retail and training, bridging virtual and physical worlds.",
    languages: [
      { language: "English", level: "Full Professional" },
      { language: "Italian", level: "Native or Bilingual" }
    ],
    experiences: [
      {
        company: "Augmentix",
        date: "March 2022 - Present (1 year 10 months)",
        location: "Remote, Italy",
        title: "Augmented Reality Developer",
        description:
          "Designs AR experiences for retail clients to enhance product visualization. Deployed ARCore/ARKit solutions with advanced plane detection. Collaborates with marketing teams to measure user engagement with AR features."
      },
      {
        title: "Software Engineer",
        location: "Milan, Italy",
        company: "FutureTech",
        date: "October 2019 - February 2022 (2 years 5 months)",
        description:
          "Developed prototypes for AR-based training solutions. Optimized 3D models to reduce rendering overhead. Integrated custom shaders to achieve realistic lighting effects."
      }
    ],
    email: "matteo.rossi@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+39 02 1234567",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Created an interactive AR catalog for a furniture company, allowing users to virtually place items in their homes. Implemented real-time scaling and color customization features.",
        url: "",
        title: "AR Furniture Catalog"
      }
    ],
    gender: "",
    city: "Milan",
    zip: "20121",
    skills: [
      {
        list: ["Unity", "ARCore", "ARKit", "3D Modeling", "C#"]
      }
    ],
    education: [
      {
        institution: "Politecnico di Milano",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Computer Engineering"
      }
    ],
    state: "Lombardy",
    address: ""
  },

  // --- NEW HIGH-DEMAND ROLES BELOW ---

  {
    sponsorship: "",
    last_name: "Baker",
    first_name: "Hannah",
    linkedin: "www.linkedin.com/in/hannah-baker-prompt",
    title: "Prompt Engineer",
    summary:
      "Prompt Engineer specialized in refining natural language prompts for AI models, maximizing performance and accuracy in generative tasks.",
    languages: [
      { language: "English", level: "Full Professional" }
    ],
    experiences: [
      {
        company: "OpenAI Startup Fund",
        date: "May 2022 - Present (1 year 8 months)",
        location: "Remote, USA",
        title: "Prompt Engineer",
        description:
          "Designs, tests, and iterates on natural language prompts for AI chatbots and writing assistants. Works closely with data scientists to reduce hallucination rates and improve context retention. Regularly tunes large language models to handle complex multi-turn dialogues."
      },
      {
        title: "NLP Research Associate",
        location: "San Francisco, CA",
        company: "AI Innovations Lab",
        date: "January 2021 - April 2022 (1 year 4 months)",
        description:
          "Conducted research on advanced language model architectures. Created custom corpora and devised specialized prompts to increase model factual correctness. Published a paper on mitigating bias in generative models."
      }
    ],
    email: "hannah.baker@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/hbaker-prompts",
    phone: "+1 415-555-0123",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Led the design of specialized prompts for a healthcare chatbot, reducing misinformation by 70%. Devised a robust evaluation framework for prompt-driven AI solutions.",
        url: "",
        title: "Healthcare Chatbot Prompt Suite"
      }
    ],
    gender: "",
    city: "San Francisco",
    zip: "94103",
    skills: [
      {
        list: ["NLP", "Language Models", "Prompt Design", "Data Analysis", "Python"]
      }
    ],
    education: [
      {
        institution: "Stanford University",
        date: "2016 - 2020",
        degree: "Bachelor's degree, Linguistics"
      }
    ],
    state: "California",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Green",
    first_name: "Alicia",
    linkedin: "www.linkedin.com/in/alicia-green-salesforce",
    title: "Salesforce Developer",
    summary:
      "Certified Salesforce Developer with extensive experience in Apex, Lightning components, and CRM optimization, driving operational efficiency.",
    languages: [
      { language: "English", level: "Full Professional" }
    ],
    experiences: [
      {
        company: "CRM Boosters",
        date: "April 2021 - Present (2 years 9 months)",
        location: "Remote, USA",
        title: "Salesforce Developer",
        description:
          "Builds custom Apex classes and triggers, automating business processes for finance and healthcare clients. Integrates third-party APIs to extend Salesforce functionality. Collaborates with admin teams to implement dynamic Lightning pages and flows."
      },
      {
        title: "Salesforce Administrator",
        location: "Chicago, IL",
        company: "Cloudify Inc.",
        date: "January 2019 - March 2021 (2 years 3 months)",
        description:
          "Managed user permissions, roles, and profiles. Created reports and dashboards for real-time KPI tracking. Supported large-scale data migrations, ensuring data integrity throughout the process."
      }
    ],
    email: "alicia.green@example.com",
    ethnicity: "",
    website: "",
    github: "github.com/alicodesforce",
    phone: "+1 312-555-7890",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Implemented a complex custom billing and invoicing solution in Salesforce for a healthcare provider. Reduced manual billing tasks by 70% and improved overall data consistency.",
        url: "",
        title: "Salesforce Billing Automation"
      }
    ],
    gender: "",
    city: "Chicago",
    zip: "60601",
    skills: [
      {
        list: [
          "Apex",
          "Lightning Web Components",
          "SOQL",
          "Salesforce Administration",
          "Process Automation"
        ]
      }
    ],
    education: [
      {
        institution: "DePaul University",
        date: "2015 - 2019",
        degree: "Bachelor's degree, Information Systems"
      }
    ],
    state: "Illinois",
    address: ""
  },
  {
    sponsorship: "",
    last_name: "Turner",
    first_name: "Maxwell",
    linkedin: "www.linkedin.com/in/maxwell-turner-programmanager",
    title: "Technical Program Manager",
    summary:
      "Technical Program Manager known for orchestrating cross-functional teams, aligning business goals with innovative engineering solutions, and delivering on-time projects.",
    languages: [
      { language: "English", level: "Full Professional" }
    ],
    experiences: [
      {
        company: "Infinity Tech",
        date: "July 2022 - Present (1 year 6 months)",
        location: "Remote, Canada",
        title: "Technical Program Manager",
        description:
          "Oversees strategic planning and execution of multiple product lines, coordinating with engineering, QA, design, and marketing teams. Established key KPIs to track project health, reducing delivery delays by 30%. Implements Agile frameworks to streamline release cycles."
      },
      {
        title: "Project Manager",
        location: "Toronto, Canada",
        company: "NextGen Solutions",
        date: "March 2019 - June 2022 (3 years 4 months)",
        description:
          "Managed large-scale software integration projects for Fortune 500 clients. Introduced risk management processes that led to a 20% decrease in production incidents. Championed team retrospectives and continuous improvement initiatives."
      }
    ],
    email: "maxwell.turner@example.com",
    ethnicity: "",
    website: "",
    github: "",
    phone: "+1 647-555-4578",
    authorization: "",
    lgbtq: "",
    projects: [
      {
        description:
          "Rolled out a multi-phased ERP system upgrade across 8 global offices, coordinating 50+ stakeholders. Achieved consistent communication and deadlines via a centralized project management tool.",
        url: "",
        title: "Global ERP Implementation"
      }
    ],
    gender: "",
    city: "Toronto",
    zip: "M5B 1T8",
    skills: [
      {
        list: [
          "Agile Project Management",
          "Risk Management",
          "Cross-functional Leadership",
          "Scrum",
          "Roadmapping"
        ]
      }
    ],
    education: [
      {
        institution: "University of Waterloo",
        date: "2014 - 2018",
        degree: "Bachelor's degree, Systems Design Engineering"
      }
    ],
    state: "Ontario",
    address: ""
  }
];

// Export the templates array so you can import it in other parts of your React app.
export default templates;
