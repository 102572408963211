import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../Container/Container";
import logoLightPurple from "../../assets/logoLightPurple.svg";
import {
  auth,
  provider,
  checkUserExists,
  signInWithPopup,
} from "../../utils/firebase.js";
import { useAuth } from "../../context/AuthContext";
import Error from "../Error/Error.jsx";
import useResumeStore from "../../stores/resume/resumeStore.js";
import { setUser } from "../../stores/resume/userStore.js";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState(null);
  const initializeResume = useResumeStore((state) => state.initializeResume);

  useEffect(() => {
    if (!auth.currentUser) {
      localStorage.removeItem("user");
    }
  }, []);

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result?.user;

      if (!user) {
        console.error("No user returned from signInWithPopup");
        setError("Login failed. Please try again.");
        return;
      }

      setUser({
        uid: user.uid || "",
        displayName: user.displayName || "Anonymous",
        email: user.email || "No email provided",
        photoURL: user.photoURL || "",
      });

      const token = await user.getIdToken();
      const refreshToken = user.refreshToken;
      const extensionId = process.env.REACT_APP_EXTENSION_ID;

      if (window.chrome?.runtime?.sendMessage) {
        await window.chrome.runtime.sendMessage(extensionId, {
          action: "login",
          token,
          refreshToken,
        });
      } else {
        console.warn("Chrome extension not available");
      }

      login(); // Update your context/auth state
      const userExists = await checkUserExists(user.uid);
      initializeResume();
      navigate(userExists ? "/dashboard" : "/resume");
    } catch (error) {
      setError("An error occurred during login. Please try again.");
    }
  };

  return (
    <Container className="min-h-screen flex flex-col">
      {/* Top bar or logo row (optional) */}
      <div className="absolute top-0 p-4 flex justify-between items-center">
        <img
          src={logoLightPurple}
          alt="Logo"
          className="h-10 w-10 animate-spin-slow"
        />

      </div>

      {/* Main content */}
      <div className="flex flex-1">
        {/* Left Section - Marketing / Big Title */}
        <div className="hidden md:flex flex-col justify-center w-1/2 p-8 bg-gradient-to-br from-[#3F33C0] to-[#6C5CE7] text-white">
          <h2 className="text-3xl font-bold mb-4">
            Unlock fast interviews and embrace 
            <br />
            Your last job searches
          </h2>
          <p className="mb-6">
            Join Jobbyo and get streamlined job matching,
            interview invites, and save time with smart features
            built for your success!
          </p>
          <ul className="space-y-2 list-disc list-inside">
            <li>2x More Qualified Job Matches</li>
            <li>60% Time Savings in Job Searches</li>
            <li>50% More Interview Invites</li>
          </ul>
        </div>

        {/* Right Section - Login Form */}
        <div className="flex flex-col justify-center items-center w-full md:w-1/2 bg-white p-8">
          {/* If there's an error, display it here */}
          {error && <Error message={error} />}
          <img src={logoLightPurple} alt="Logo" className="h-90 w-90 mb-8"/>
          <h3 className="text-3xl font-bold text-gray-800 mb-6">Welcome to Jobbyo</h3>

          {/* Google Sign In Button */}
          <button
            onClick={handleGoogleLogin}
            className="relative flex items-center justify-center w-full sm:w-80 h-10 my-2 bg-white border border-gray-300 rounded-md cursor-pointer hover:shadow-md transition duration-300 ease-in-out"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
              alt="Google logo"
              className="w-5 h-5 mr-2"
            />
            <span className="text-gray-700 font-medium">Sign up with Google</span>
          </button>
        {/* Email & Password Login (optional)
          <div className="w-full sm:w-80 my-4 flex items-center">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-gray-500">OR</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // Your standard login code goes here
            }}
            className="w-full sm:w-80 flex flex-col space-y-3"
          >
            <input
              type="email"
              placeholder="Email"
              className="h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <input
              type="password"
              placeholder="Password"
              className="h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <button
              type="submit"
              className="h-10 w-full bg-indigo-500 text-white font-semibold rounded-md hover:bg-indigo-600 transition"
            >
              Sign Up
            </button>
          </form>
 */}
          {/* Extra links */}
          <p className="text-sm text-gray-500 mt-6">
            Already a member?{" "}
            <button
              type="button"
              onClick={handleGoogleLogin}
              className="text-indigo-600 hover:underline"
            >
              Sign in now
            </button>
          </p>

          <p className="text-xs text-gray-400 mt-2">
            By continuing, you agree to the Jobbyo{" "}
            <a href="#" className="underline">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="#" className="underline">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Login;
