import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { Fragment } from "react";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";
import useFeedbackModalStore from "../../stores/resume/modalsStore";

const FeedbackAndSupport = () => {
  const [feedbackType, setFeedbackType] = useState("");
  const [details, setDetails] = useState("");
  const [subscribeEmail, setSubscribeEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isOpen, closeModal } = useFeedbackModalStore();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validations
    if (!feedbackType) {
      toast.warning("Please select a feedback type.");
      return;
    }
    if (!details.trim()) {
      toast.warning("Please provide more details.");
      return;
    }

    try {
      setIsSubmitting(true);
      // Send email using EmailJS
      await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      );

      toast.success("Thanks for your feedback! Email sent successfully.");
      // Clear fields
      setFeedbackType("");
      setDetails("");
      setSubscribeEmail(false);
      // Close modal after successful submission
      closeModal();
    } catch (error) {
      console.error("EmailJS error:", error);
      toast.error("Oops, something went wrong while sending your feedback.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      transition
      className="fixed inset-0 z-[10000] flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
    >
      <DialogBackdrop className="fixed inset-0 bg-white/50 duration-300 ease-out data-[closed]:opacity-0" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="max-w-md w-full rounded-lg bg-white p-6 relative">
          <DialogTitle className="text-lg font-semibold">Feedback</DialogTitle>
          <form onSubmit={handleSubmit}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              What would you like to tell us?
            </label>
            <div className="space-y-2 mb-4">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="feedbackType"
                  value="Bug Report"
                  checked={feedbackType === "Bug Report"}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  disabled={isSubmitting}
                />
                <span>Bug Report</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="feedbackType"
                  value="Feature Request"
                  checked={feedbackType === "Feature Request"}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  disabled={isSubmitting}
                />
                <span>Feature Request</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="feedbackType"
                  value="User Experience Feedback"
                  checked={feedbackType === "User Experience Feedback"}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  disabled={isSubmitting}
                />
                <span>User Experience Feedback</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="feedbackType"
                  value="Subscription and Membership"
                  checked={feedbackType === "Subscription and Membership"}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  disabled={isSubmitting}
                />
                <span>Subscription and Membership</span>
              </label>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="radio"
                  name="feedbackType"
                  value="Others"
                  checked={feedbackType === "Others"}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  disabled={isSubmitting}
                />
                <span>Others</span>
              </label>
            </div>

            <label className="block text-sm font-medium text-gray-700 mb-1">
              Can you give us more details?
            </label>
            <textarea
              className="border border-gray-300 rounded w-full p-2 text-sm mb-2"
              rows="4"
              placeholder="Please describe your experience..."
              value={details}
              name="details" // Important: name attribute for EmailJS
              onChange={(e) => setDetails(e.target.value)}
              disabled={isSubmitting}
            />

            <label className="flex items-center space-x-2 mb-2 cursor-pointer">
              <input
                type="checkbox"
                checked={subscribeEmail}
                onChange={() => setSubscribeEmail(!subscribeEmail)}
                name="subscribeEmail" // Name attribute for EmailJS
                disabled={isSubmitting}
              />
              <span>Yes, I'd like to receive follow-up support via email.</span>
            </label>

            <button
              type="submit"
              className="bg-purple text-white px-4 py-2 rounded disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Submit"}
            </button>
          </form>

          <div className="mt-4 border-t pt-2 text-sm text-gray-600">
            <strong>Want instant support?</strong> Join our{" "}
            <a
              href="https://discord.gg/..."
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Discord community
            </a>{" "}
            for timely support and access to exclusive career resources.
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default FeedbackAndSupport;
