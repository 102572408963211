import React from "react";
import { formatDatePosted, getWebsiteLogo } from "../../../utils/helpers";
import ReactMarkdown from "react-markdown";

const UserDashboardList = ({ list, userPlan, onMoreInfoClick, handleApplyClick }) => {

  const formatScoreAsPercentage = (s) => Math.round(s * 10);

  const isEarlyApplicant = (datePosted) => {
    const date = new Date(datePosted);
    if (isNaN(date.getTime())) return false;
    const today = new Date();
    const diffTime = today - date;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 1;
  };

  const getUnblurLimit = (plan) => {
    if (!plan) return 2;
    switch (plan.toUpperCase()) {
      case "STARTER": return 5;
      case "GROWTH": return 15;
      case "PREMIUM": return Infinity;
      default: return 2;
    }
  };

  const unblurLimit = getUnblurLimit(userPlan);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mx-auto">
      {list.map((job, index) => {
        const isUnblurred = index < unblurLimit;
        return (
          <div key={index} className="flex flex-col gap-4 border border-gray-200 rounded-lg shadow-md p-4 mb-4">
            <div className="flex items-center space-x-4">
              <div className="flex-1 space-y-2">
                <div className="flex gap-3 items-center">
                  <div className="min-w-12">
                    <img
                      className="w-12 h-12 rounded-lg"
                      src={getWebsiteLogo(job.site)}
                      alt="Company Logo"
                    />
                  </div>
                  <div>
                    <div className="flex items-center gap-3">
                      <div className="text-xs text-gray-500 bg-gray-200 px-2 py-0.5 rounded-full">
                        {formatDatePosted(job.date_posted)}
                      </div>
                      <div className="text-xs text-green-600 bg-green-50 px-2 py-0.5 rounded-full">
                        {job.is_remote ? "Remote" : "On-site"}
                      </div>
                      {isEarlyApplicant(job.date_posted) && (
                        <div className="text-xs text-green-600 bg-green-50 px-2 py-0.5 rounded-full">
                          Be an early applicant
                        </div>
                      )}
                    </div>
                    <h2 className="text-lg font-semibold text-gray-900">
                      {job.title}
                    </h2>
                    <p className="text-sm text-gray-500">
                      {isUnblurred ? (
                        <> {job.company} {job.company_industry ? ` / ${job.company_industry}` : ""}</>
                      ) : (
                        <span className="blur-sm">"**********"</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex items-center text-sm text-gray-500 space-x-4">
                  <div className="flex items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-3">
                      <path fillRule="evenodd" d="m9.69 18.933.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" clipRule="evenodd" />
                    </svg>
                    {job.location || "N/A"}
                  </div>
                </div>
                <div className="text-xs line-clamp-2 text-gray-700">
                  {isUnblurred ? (
                    <ReactMarkdown>{job.description}</ReactMarkdown>
                  ) : (
                    <span className="blur-sm">
                      ********** ***** ******** ***** ******* *** ***
                      ** ***** **** ****** ****** ********** ********** ***** ******** ***** ******* *** ***
                      ** ***** **** ****** ****** ********** ********** ***** ******** ***** ******* *** ***
                      ** ***** **** ****** ****** **********
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center min-w-[100px]">
                <div className="relative w-16 h-16">
                  <svg
                    className="absolute inset-0 w-full h-full text-gray-100"
                    viewBox="0 0 36 36"
                  >
                    <circle
                      className="stroke-current"
                      cx="18"
                      cy="18"
                      r="16"
                      strokeWidth="2"
                      fill="none"
                    ></circle>
                    <circle
                      className={
                        formatScoreAsPercentage(job["Relevance Score"]) > 50 ? "stroke-green-600" : 
                        formatScoreAsPercentage(job["Relevance Score"]) > 15 ? "stroke-yellow-400"
                        : "stroke-red-600"
                      }
                      cx="18"
                      cy="18"
                      r="16"
                      strokeWidth="2"
                      fill="none"
                      strokeDasharray="100"
                      strokeDashoffset={Math.abs(formatScoreAsPercentage(job["Relevance Score"]) - 100)}
                      strokeLinecap="round"
                      transform="rotate(-90 18 18)"
                    ></circle>
                  </svg>
                  <span
                    className={`absolute inset-0 flex items-center 
                      justify-center font-bold ${
                        formatScoreAsPercentage(job["Relevance Score"]) > 50 ? "text-green-600"
                        : formatScoreAsPercentage(job["Relevance Score"]) > 15 ? "text-yellow-500"
                        : "text-red-600"
                      }`}
                  >
                    {formatScoreAsPercentage(job["Relevance Score"])}%
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-2 border-t border-gray-200 pt-3 flex-wrap">
              {isUnblurred ? (
                <>
                  <button onClick={() => handleApplyClick(job)} className="bg-purple text-white text-xs font-medium py-2 px-3 rounded">
                    Apply
                  </button>
                  <button className="border border-purple text-purple text-xs font-medium py-2 px-3 rounded" onClick={() => onMoreInfoClick(job)}>
                    More Info
                  </button>
                </>
              ) : (
                <div className="text-xs text-gray-500 italic">
                  Upgrade your plan to unlock
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserDashboardList;
