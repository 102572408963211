import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { addUserData } from "../../utils/firebase";
import useResumeStore from "../../stores/resume/resumeStore";

import templates from "../../utils/templates";

// Utility to check if we want to label something as "big tech"
const isBigTech = (tmpl) => {
  const company = tmpl.experiences[0]?.company?.toLowerCase() || "";
  return company.includes("google") || company.includes("microsoft");
};

const ResumeSamples = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  // Resume store actions
  const setResume = useResumeStore((state) => state.setResume);
  const setLoadingResume = useResumeStore((state) => state.setLoadingResume);

  // Local component states
  const [isUploading, setIsUploading] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // Fake user ID for demonstration
  const userId = "sampleUser";

  const handleSelectSample = async (index) => {
    try {
      setIsUploading(true);
      setSelectedTemplateIndex(index);

      let chosenTemplate = templates[index];

      // 1) If big tech, autofill some city/state for demonstration
      if (isBigTech(chosenTemplate)) {
        const company = chosenTemplate.experiences[0]?.company?.toLowerCase();
        if (company.includes("google")) {
          chosenTemplate = {
            ...chosenTemplate,
            city: "Mountain View",
            state: "CA",
          };
        } else if (company.includes("microsoft")) {
          chosenTemplate = {
            ...chosenTemplate,
            city: "Redmond",
            state: "WA",
          };
        }
      }

      // 2) Merge user data
      const userData = {
        ...chosenTemplate,
        uid: userId,
      };

      // Save data in Firebase
      await addUserData(userId, userData);

      // Save in resume store
      setResume(userData);
      setLoadingResume(false);

      // Close the modal, then navigate
      onClose();
      navigate("/resume/edit");
    } catch (error) {
      console.error("Error loading template:", error);
      setIsUploading(false);
    }
  };

  // Filter templates by search term
  const filteredTemplates = templates.filter((tmpl) => {
    const title = tmpl.experiences[0]?.title?.toLowerCase() || "";
    const firstName = tmpl.first_name.toLowerCase();
    const lastName = tmpl.last_name.toLowerCase();
    const city = tmpl.city.toLowerCase();
    const state = tmpl.state.toLowerCase();
    const company = tmpl.experiences[0]?.company?.toLowerCase() || "";

    return (
      title.includes(searchTerm.toLowerCase()) ||
      firstName.includes(searchTerm.toLowerCase()) ||
      lastName.includes(searchTerm.toLowerCase()) ||
      city.includes(searchTerm.toLowerCase()) ||
      state.includes(searchTerm.toLowerCase()) ||
      company.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />

      {/* Centered container */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xl bg-white w-full p-6 rounded shadow-lg relative">
          {/* "X" close button */}
          <button
          onClick={onClose}
          className="absolute pr-2 top-2 right-2 text-gray-400 hover:text-gray-600 text-2xl font-bold"
        >
          &times;
        </button>

          {/* Header / Title */}
          <div className="mb-6">
            <Dialog.Title className="text-xl font-bold text-purple">
              Search resume samples by job title or skill
            </Dialog.Title>
          </div>

          {/* Search + Dropdown */}
          <div className="relative max-w-md w-full mb-6">
            <input
              type="text"
              className="border border-gray-300 rounded h-10 px-3 w-full text-sm 
                  focus:outline-none focus:border-purple 
                  placeholder:text-gray-400"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="e.g. Software Engineer, Sales..."
            />

            {/* Only show the dropdown if user has typed something */}
            {searchTerm.trim() !== "" && (
              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow mt-1">
                {/* Loading message if user is selecting a template */}
                {isUploading && (
                  <p className="text-purple text-sm p-2">
                    Loading your chosen template...
                  </p>
                )}

                {/* Filtered results */}
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map((tmpl, idx) => {
                    const isSelected = selectedTemplateIndex === idx;
                    const bigTech = isBigTech(tmpl);

                    return (
                      <div
                        key={idx}
                        onClick={() => handleSelectSample(idx)}
                        className={`cursor-pointer p-3 border-b last:border-b-0 
                                   border-gray-200 hover:bg-gray-50 transition 
                                   flex items-start gap-3 ${
                                     isSelected
                                       ? "bg-purple-50 border-l-4 border-purple"
                                       : ""
                                   }`}
                      >
                        {/* Premium icon if big tech */}
                        {bigTech && (
                          <div className="pt-1 text-yellow-500">👑</div>
                        )}

                        <div>
                          {/* Job Title in Bold */}
                          <h2 className="text-sm font-bold text-gray-700 mb-1">
                            {tmpl.experiences[0]?.title || "No title"}
                          </h2>
                          {/* Name, location */}
                          <p className="text-xs text-gray-500">
                            {tmpl.first_name} {tmpl.last_name} {" • "}
                            {tmpl.city}, {tmpl.state}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  // If typed something but no matches
                  <div className="p-3 text-gray-400">
                    No resume samples match your search.
                  </div>
                )}
              </div>
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ResumeSamples;