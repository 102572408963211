import React, { useState } from "react";
import { getWebsiteLogo } from "../../../utils/helpers";
import ReactMarkdown from "react-markdown";

const UserDashboardMoreInfo = ({ job, handleApplyClick, closeMoreInfo }) => {
  const [showMoreCompanyInfo, setShowMoreCompanyInfo] = useState(false);

  return (
    
    <div className="fixed inset-0 flex justify-end z-40">
      <div
        className="absolute inset-0 bg-black bg-opacity-40"
        onClick={closeMoreInfo}
      />
      <div className="relative bg-white w-full max-w-md h-full shadow-xl overflow-auto">
        <button
          onClick={closeMoreInfo}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 text-2xl font-bold"
        >
          &times;
        </button>
        <div className="p-6 pt-10 px-8">
          <div className="mb-4 flex items-center gap-3">
            <img className="w-12 h-12 rounded-lg" src={getWebsiteLogo(job.site)} alt="Job Logo" />
            <div>
              <h3 className="text-xl font-semibold">
                {job.title}
              </h3>
              <p className="text-gray-500 text-sm">
                {job.company || (
                  <span className="italic">No company name</span>
                )}
              </p>
            </div>
          </div>
          <div className="mb-2 text-sm">
            <strong>Location:</strong>{" "}
            {job.location || "N/A"}
          </div>
          {job.company_industry && (
            <div className="mb-2 text-sm">
              <strong>Industry:</strong>{" "}
              {job.company_industry}
            </div>
          )}
          {(job.company_num_employees ||
            job.company_revenue ||
            job.company_description ||
            job.emails ||
            job.company_url_direct) && (
            <div className="mt-4">
              <button
                className="text-sm text-purple hover:underline"
                onClick={() =>
                  setShowMoreCompanyInfo(!showMoreCompanyInfo)
                }
              >
                {showMoreCompanyInfo
                  ? "Hide info about the company"
                  : "More about the company"}
              </button>
              {showMoreCompanyInfo && (
                <div className="mt-4 text-sm text-gray-700 space-y-2">
                  <div className="flex gap-2 items-center">
                    {job.company_logo && (
                      <img className="w-12 h-12 rounded-lg" alt={job.title} src={job.company_logo} />
                    )}
                    {job.company_description && (
                      <p>{job.company_description}</p>
                    )}
                  </div>
                  {job.company_num_employees && (
                    <p>
                      <strong>Employees:</strong>{" "}
                      {job.company_num_employees}
                    </p>
                  )}
                  {job.company_revenue && (
                    <p>
                      <strong>Revenue:</strong>{" "}
                      {job.company_revenue}
                    </p>
                  )}
                  {job.emails && (
                    <p>
                      <b>Emails:</b> {job.emails}
                    </p>
                  )}
                  {job.company_url_direct && (
                    <p>
                      <strong>Website:</strong>{" "}
                      <a
                        className="text-purple"
                        href={job.company_url_direct}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {job.company_url_direct}
                      </a>
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          {job.job_level && (
            <div className="mb-2 text-sm">
              <strong>Job Level:</strong> {job.job_level}
            </div>
          )}
          <div className="my-4 border-t border-gray-200"></div>
          <h4 className="text-lg font-bold mb-2">Description</h4>
          <div className="text-sm text-gray-700 space-y-2">
            <ReactMarkdown>{job.description}</ReactMarkdown>
          </div>
          <div className="mt-6">
            <button
              className="inline-block bg-purple text-white text-sm font-medium py-2 px-4 rounded"
              onClick={() => handleApplyClick(job)}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardMoreInfo;
