import React from "react";

const UserDashboardPlaceholder = ({ loadingJobs, messages, messageIndex }) => {
  return (
    <div className="flex mb-20 flex-col items-center justify-center bg-white w-full grow">
      <div className="flex flex-col items-center justify-center space-y-6">
        <h2 key={messageIndex} className="text-2xl font-semibold text-center transition-opacity duration-500 text-[#3F33C0]">
          {messages[messageIndex]}
        </h2>
        <div className="flex space-x-10 items-center">
          <div className="flex flex-col items-center">
            <img src="https://cdn.worldvectorlogo.com/logos/linkedin-icon.svg" alt="LinkedIn Logo" className="h-12 w-12 object-contain" />
            <span className="mt-2 text-gray-500 text-sm">LinkedIn</span>
            <div className="mt-2 flex items-center space-x-2">
              {!loadingJobs.includes("linkedin") ? (
                <div className="text-green-500 text-xl font-bold">
                  ✔
                </div>
              ) : (
                <div className="h-4 w-4 rounded-full border-2 border-blue-500 border-t-transparent animate-spin"></div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              src="https://static-00.iconduck.com/assets.00/glassdoor-icon-2048x2048-4di6xoda.png"
              alt="Glassdoor Logo"
              className="h-12 w-12 object-contain"
            />
            <span className="mt-2 text-gray-500 text-sm">
              Glassdoor
            </span>
            <div className="mt-2 flex items-center space-x-2">
              {!loadingJobs.includes("glassdoor") ? (
                <div className="text-green-500 text-xl font-bold">
                  ✔
                </div>
              ) : (
                <div className="h-4 w-4 rounded-full border-2 border-blue-500 border-t-transparent animate-spin"></div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThz8Qi-G6jIHt6TmCOguWjOKGYYQPB1afpSQ&s" alt="Indeed Logo" className="h-12 w-12 object-contain" />
            <span className="mt-2 text-gray-500 text-sm">Indeed</span>
            <div className="mt-2 flex items-center space-x-2">
              {!loadingJobs.includes("indeed") ? (
                <div className="text-green-500 text-xl font-bold">
                  ✔
                </div>
              ) : (
                <div className="h-4 w-4 rounded-full border-2 border-blue-500 border-t-transparent animate-spin"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardPlaceholder;
