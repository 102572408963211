import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation";
import FeedbackAndSupport from "./Dashboard/FeedbackAndSupport";
import useJobsStore from "../stores/resume/jobsStore";

export default function Layout() {
  const location = useLocation();
  const deleteSelectedJob = useJobsStore((state) => state.deleteSelectedJob);
  const selectedJob = useJobsStore((state) => state.selectedJob);
  const isAuthPage = location.pathname === "/login" || location.pathname === "/signup";

  useEffect(() => {
    if(location.pathname !== "/custom-cv" && selectedJob !== null) {
      deleteSelectedJob();
    }
  }, [location.pathname, selectedJob, deleteSelectedJob]);

  return (
    <>
      {<FeedbackAndSupport />}
      <div className={`flex min-h-screen ${isAuthPage ? "bg-[#3F33C0]" : "bg-white"}`}>
        {/* Sidebar */}
        {!isAuthPage && <Navigation />}
        
        {/* Page content */}
        <div className={`flex-grow w-full`}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
