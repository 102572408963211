// src/components/Profile Settings/Settings.jsx

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUser,
  fetchProductsData,
  fetchUserSubscriptionsData,
  deleteUserFromFirestore,
  auth,
} from "../../utils/firebase";
import ProductList from "./Payment.jsx";
import useJobsStore from "../../stores/resume/jobsStore.js";
import useUserStore from "../../stores/resume/userStore.js";
import useResumeStore from "../../stores/resume/resumeStore.js";
import {
  reauthenticateWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

const Settings = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [products, setProducts] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState(new Set());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPlanPopup, setShowPlanPopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const clearJobs = useJobsStore((state) => state.clearJobs);
  const deleteUser = useUserStore((state) => state.deleteUser);
  const deleteResume = useResumeStore((state) => state.deleteResume);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (!storedUser?.uid) {
          setError("No user found in local storage.");
          setLoading(false);
          return;
        }

        const user = await getUser(storedUser.uid);
        setUserData(user);

        // Fetch products
        const productsData = await fetchProductsData();
        setProducts(productsData);

        // Fetch user subscriptions
        const subscribedProducts = await fetchUserSubscriptionsData();
        setUserSubscriptions(subscribedProducts);

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load user or product data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Plan popups
  const handleOpenPlanPopup = () => {
    setShowPlanPopup(true);
  };

  const handleClosePlanPopup = () => {
    setShowPlanPopup(false);
  };

  const currentPlan = products.find((product) =>
    userSubscriptions?.has(product.id)
  );

  const handleManageSubscription = async () => {
    // Manage subscription logic here if needed
  };

  const handleDeleteAccount = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDeleteAccount = async () => {
    try {
      // 1. Delete from Auth (requires recent login)
      if (!auth.currentUser) {
        alert("No current user. Please log in again.");
        return;
      }

      await auth.currentUser.delete();

      // 2. Remove user data from Firestore
      if (userData?.uid) {
        await deleteUserFromFirestore(userData.uid);
      }

      alert("Account deleted successfully. You will be logged out.");

      // Clear local stores
      deleteUser();
      deleteResume();
      clearJobs();

      // Redirect
      navigate("/login");
    } catch (error) {
      console.error("Error deleting account:", error);

      // Check if reauthentication is needed
      if (error.code === "auth/requires-recent-login") {
        alert("Please re-authenticate to delete your account.");

        try {
          // Example reauth with Google (adjust if you use another provider)
          const provider = new GoogleAuthProvider();
          await reauthenticateWithPopup(auth.currentUser, provider);

          // After successful reauth, try deleting again
          await auth.currentUser.delete();

          if (userData?.uid) {
            await deleteUserFromFirestore(userData.uid);
          }

          alert("Account deleted successfully. You will be logged out.");

          deleteUser();
          deleteResume();
          clearJobs();
          navigate("/login");
        } catch (reauthError) {
          console.error("Reauthentication failed:", reauthError);
          alert(
            "We couldn't reauthenticate you. Please log in again or try later."
          );
        }
      } else {
        alert(
          "An error occurred while deleting your account. Please re-authenticate or try again."
        );
      }
    } finally {
      setShowDeleteModal(false);
    }
  };

  if (loading)
    return (
      <div className="absolute w-full h-full flex items-center justify-center">
        {/* ...Loading Spinner... */}
      </div>
    );

  if (error) return <div>{error}</div>;

  // Optional: add a small cache-buster to avoid stale cached images
  const photoURLWithCacheBust = userData?.photoURL
    ? `${userData.photoURL}?cacheBust=${Date.now()}`
    : null;

  return (
    <div className="container flex flex-col mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Settings</h1>

      {/* User Info */}
      <div className="bg-white p-4 rounded shadow flex items-center space-x-4 mb-6">
        {photoURLWithCacheBust ? (
          <img
            src={photoURLWithCacheBust}
            alt={userData.displayName || "User Avatar"}
            referrerPolicy="no-referrer"
            className="w-16 h-16 rounded-full object-cover"
            onLoad={() => console.log("Profile image loaded.")}
            onError={(e) => {
              console.error("Profile image failed to load:", photoURLWithCacheBust);
              // Optionally fall back to a default avatar
              e.currentTarget.src = "/defaultAvatar.png";
            }}
          />
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
            <span className="text-gray-600">No Photo</span>
          </div>
        )}
        <div>
          <p className="text-lg font-medium">
            {userData?.displayName || "Unknown User"}
          </p>
          <p className="text-sm text-gray-600">
            {userData?.email || "No Email Provided"}
          </p>
        </div>
      </div>

      {/* Current Plan & Manage Subscription */}
      <div className="bg-white p-4 shadow rounded-md mb-6">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-base font-bold">Current Plan</h2>
          <a
            href="https://jobbyo.ai/terms-of-service"
            className="text-sm underline text-black hover:text-gray-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
        <div className="flex items-center space-x-3 mb-2">
          <span className="text-[#3F33C0] font-extrabold">
            {currentPlan ? currentPlan?.name?.toUpperCase() : "jobbyo FREE"}
          </span>
          <button
            onClick={handleOpenPlanPopup}
            className="bg-gray-200 text-gray-700 px-2 py-1 rounded text-xs hover:bg-gray-300"
          >
            View Detail
          </button>
        </div>
        <hr className="my-2 border-gray-300" />
        <p className="text-sm text-gray-700">
          Unlock unlimited access to all features with jobbyo Turbo
        </p>
        <button
          onClick={handleOpenPlanPopup}
          className="bg-purple text-white px-4 py-2 rounded mt-3 text-sm hover:from-teal-500 hover:to-blue-600"
        >
          {currentPlan ? "Upgrade Now" : "Subscribe"}
        </button>
        {currentPlan && (
          <button
            onClick={handleManageSubscription}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded mt-3 text-sm font-semibold hover:bg-gray-300 ml-3"
          >
            Manage Subscription
          </button>
        )}
      </div>

      {/* Delete Account Button */}
      <div className="bg-white p-4 rounded shadow flex justify-between items-center mb-6">
        <p className="text-sm text-gray-700">
          Delete your account and all associated data permanently.
        </p>
        <button
          onClick={handleDeleteAccount}
          className="bg-red-600 text-white px-4 py-2 rounded text-sm font-semibold hover:bg-red-700"
        >
          Delete Account
        </button>
      </div>

      {/* Plan Popup */}
      {showPlanPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white rounded shadow-lg p-4 w-full max-w-3xl relative overflow-auto">
            <button
              onClick={handleClosePlanPopup}
              className="absolute pr-2 top-2 right-2 text-gray-400 hover:text-gray-600 text-2xl font-bold"
            >
              &times;
            </button>

            <div className="text-center mb-6">
              <h2 className="text-2xl font-extrabold text-gray-800">
                Accelerate Your Job Search With{" "}
                <span className="text-purple">jobbyo TURBO</span>
              </h2>
              <p className="text-md text-gray-600">
                Get 3x More Interviews with Unlimited Access to the jobbyo TURBO
              </p>
            </div>
            <ProductList />

            <div className="mt-8 bg-white p-4 rounded-lg shadow-sm">
              <h4 className="text-xl font-bold text-center mb-2">
                jobbyo Turbo
              </h4>
              <p className="text-center text-sm text-gray-600 mb-4">
                Trusted by 100,000+ job seekers, our tools leverage methods from
                top experts and advanced AI to give you the best chance at
                interview success.
              </p>
              <div className="overflow-x-auto">
                <table className="min-w-full text-sm text-left text-gray-700 border-collapse">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 bg-gray-100 font-bold"></th>
                      <th className="py-2 px-4 bg-gray-100 font-bold">
                        Free Plan
                        {!currentPlan?.name?.toUpperCase() && (
                          <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                            Current
                          </span>
                        )}
                      </th>
                      <th className="py-2 px-4 bg-gray-100 font-bold">
                        Starter
                        {currentPlan?.name?.toUpperCase() === "STARTER" && (
                          <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                            Current
                          </span>
                        )}
                      </th>
                      <th className="py-2 px-4 bg-gray-100 font-bold">
                        Growth
                        {currentPlan?.name?.toUpperCase() === "GROWTH" && (
                          <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                            Current
                          </span>
                        )}
                      </th>
                      <th className="py-2 px-4 bg-gray-100 font-bold">
                        Premium
                        {currentPlan?.name?.toUpperCase() === "PREMIUM" && (
                          <span className="ml-1 inline-block text-xs px-2 py-0.5 rounded bg-purple text-white">
                            Current
                          </span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b">
                      <td className="py-2 px-4">
                        AI Custom Resume Generation
                      </td>
                      <td className="py-2 px-4">1/day</td>
                      <td className="py-2 px-4">5/day</td>
                      <td className="py-2 px-4">15/day</td>
                      <td className="py-2 px-4">Unlimited</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4">
                        AI Resume Builder Enhancement
                      </td>
                      <td className="py-2 px-4">1/day</td>
                      <td className="py-2 px-4">5/day</td>
                      <td className="py-2 px-4">15/day</td>
                      <td className="py-2 px-4">Unlimited</td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4">Instant Job Alerts</td>
                      <td className="py-2 px-4">✖</td>
                      <td className="py-2 px-4">✖</td>
                      <td className="py-2 px-4">✖</td>
                      <td className="py-2 px-4">Unlimited</td>
                    </tr>
                    <tr>
                      <td className="py-2 px-4">
                        Exclusive Career Coach Support 1-1 monthly
                      </td>
                      <td className="py-2 px-4">✖</td>
                      <td className="py-2 px-4">✖</td>
                      <td className="py-2 px-4">✖</td>
                      <td className="py-2 px-4">Unlimited</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Account Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h3 className="text-lg font-bold mb-4">Delete Account</h3>
            <p className="text-sm text-gray-600 mb-6">
              Are you sure you want to delete your account? All your data will
              be removed and this action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDeleteAccount}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;