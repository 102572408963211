import { create } from "zustand";
import useUserStore from "./userStore";
import {
  checkUserExists,
  getUserCVData,
  updateUserData,
  deleteUserCVData,
} from "../../utils/firebase";

const useResumeStore = create((set, get) => ({
  resume: {},
  resumeTailored: {},
  highlightedItems: new Set(),
  loadingResume: false,
  isSaved: true,
  setIsSaved: (saved) => set(() => ({ isSaved: saved })),

  setLoading: (loading) => set({ loadingResume: loading }),

  setResume: (newResume) => {
    set({
      resume: newResume,
      resumeTailored: { ...newResume },
      isSaved: true,
    });

    const userStore = useUserStore.getState();
    if (!userStore) {
      console.warn("User store is not available yet; skipping setResume user updates.");
      return;
    }
    const { user, updateUser, setUser } = userStore;
    if (user) {
      updateUser({ cv: newResume });
    } else {
      setUser({ cv: newResume });
    }
  },

  initializeResume: async (userId) => {
    try {
      get().setLoading(true);

      const userStore = useUserStore.getState();
      if (!userStore) {
        console.warn("User store not available; skipping resume initialization.");
        return null;
      }

      let cv = {};
      const { user, setUser } = userStore;

      userId = userId || user?.uid;
      if (!userId) {
        console.warn("No user ID found; skipping resume initialization.");
        return null;
      }
      if (await checkUserExists(userId)) {
        const fetchedCV = await getUserCVData(userId);
        cv = fetchedCV || {};
      } else {
        return null;
      }

      const currentResume = get().resume;
      if (JSON.stringify(currentResume) !== JSON.stringify(cv)) {
        set({
          resume: cv,
          resumeTailored: { ...cv },
          isSaved: true,
        });

        if (user) {
          useUserStore.getState().updateUser({ cv });
        } else {
          setUser({ cv });
        }
      }
    } catch (error) {
      console.error("Failed to initialize resume:", error);
    } finally {
      get().setLoading(false);
    }
  },


  updateResume: async (updatedCV) => {
    // 1) Get user from userStore
    const userStore = useUserStore.getState();
    if (!userStore) {
      console.warn("User store not available; skipping updateResume user updates.");
      return;
    }
    const { user, updateUser, setUser } = userStore;
    const userId = user?.uid || null;

    try {
      get().setLoading(true);

      const currentResume = get().resume;
      if (JSON.stringify(currentResume) !== JSON.stringify(updatedCV)) {
        if (userId) {
          await updateUserData(userId, { cv: updatedCV });
        }

        if (user) {
          updateUser({ cv: updatedCV });
        } else {
          setUser({ cv: updatedCV });
        }

        set({
          resume: updatedCV,
          resumeTailored: { ...updatedCV },
          isSaved: true,
        });
      }
    } catch (error) {
      console.error("Failed to update resume:", error);
    } finally {
      get().setLoading(false);
    }
  },


  deleteResume: async () => {
    const userStore = useUserStore.getState();
    if (!userStore) {
      console.warn("User store not available; skipping deleteResume.");
      return;
    }
    const { user, updateUser } = userStore;
    const userId = user?.uid || null;

    try {
      get().setLoading(true);

      set({
        resume: {},
        resumeTailored: {},
        highlightedItems: new Set(),
        isSaved: true,
      });

      if (userId) {
        await deleteUserCVData(userId);
      }

      if (user) {
        updateUser({ cv: {} });
      } else {
        useUserStore.getState().setUser({ cv: {} });
      }
    } catch (error) {
      console.error("Failed to delete resume:", error);
    } finally {
      get().setLoading(false);
    }
  },

  addHighlightedItem: (item) =>
    set((state) => ({
      highlightedItems: new Set(state.highlightedItems).add(item),
    })),

  removeHighlightedItem: (item) =>
    set((state) => {
      const updated = new Set(state.highlightedItems);
      updated.delete(item);
      return { highlightedItems: updated };
    }),

  setResumeTailored: (tailoredCV) => {
    set({ resumeTailored: tailoredCV });
  },

  resetResumeTailored: () => {
    set({ resumeTailored: {} });
  },
}));

export default useResumeStore;
export const { setResume, deleteResume } = useResumeStore.getState();
