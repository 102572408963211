import editIcon from "../../assets/editIcon.svg";
import useResumeStore from "../../stores/resume/resumeStore.js";
import { useEffect, useState } from "react";
import { addUserData } from "../../utils/firebase.js";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadResume } from "./uploadResume.jsx";
import useUserStore from "../../stores/resume/userStore.js";

function formatText(str) {
  if (!str) return "";
  return str.replace(/\r?\n/g, "<br/>");
}

function toNumericMonth(month) {
  const map = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  return map[month] || 0;
}

function parseDateFields(item) {
  if (typeof item.startYearNumeric === "number" && typeof item.endYearNumeric === "number") {
    return;
  }
  if (!item.date) {
    item.startYearNumeric = 0;
    item.startMonthNumeric = 0;
    item.endYearNumeric = 0;
    item.endMonthNumeric = 0;
    return;
  }
  const [startRaw, endRaw] = item.date.split("-").map((p) => p.trim());
  let startMonth = 0;
  let startYear = 0;
  let endMonth = 0;
  let endYear = 0;
  if (startRaw) {
    const parts = startRaw.split(" ");
    if (parts.length === 2) {
      startMonth = toNumericMonth(parts[0]);
      startYear = parseInt(parts[1]) || 0;
    } else {
      startYear = parseInt(parts[0]) || 0;
    }
  }
  if (endRaw?.toLowerCase() === "present") {
    endMonth = 12;
    endYear = 9999;
  } else if (endRaw) {
    const parts = endRaw.split(" ");
    if (parts.length === 2) {
      endMonth = toNumericMonth(parts[0]);
      endYear = parseInt(parts[1]) || 0;
    } else {
      endYear = parseInt(parts[0]) || 0;
    }
  }
  item.startYearNumeric = item.startYearNumeric || startYear;
  item.startMonthNumeric = item.startMonthNumeric || startMonth;
  item.endYearNumeric = item.endYearNumeric || endYear;
  item.endMonthNumeric = item.endMonthNumeric || endMonth;
}

function sortItemsByDateDesc(array) {
  return array
    .slice()
    .map((item) => {
      parseDateFields(item);
      return item;
    })
    .sort((a, b) => {
      if (b.endYearNumeric !== a.endYearNumeric) return b.endYearNumeric - a.endYearNumeric;
      if (b.endMonthNumeric !== a.endMonthNumeric) return b.endMonthNumeric - a.endMonthNumeric;
      if (b.startYearNumeric !== a.startYearNumeric) return b.startYearNumeric - a.startYearNumeric;
      return b.startMonthNumeric - a.startMonthNumeric;
    });
}

const placeholderResume = {
  first_name: "Placeholder for",
  last_name: "Name",
  title: "Your Desired Job Title",
  summary: "Brief summary about your professional background and interests here.",
  email: "you@example.com",
  phone: "123-456-7890",
  linkedin: "linkedin.com/in/yourprofile",
  github: "github.com/yourgithub",
  address: "1234 Example Street, City, Country",
  experiences: [
    {
      title: "Position Title",
      date: "2020 - 2022",
      company: "Company Name",
      description: "Achievements and responsibilities.\n- Led a small team\n- Improved processes",
    },
  ],
  projects: [
    {
      title: "Project Title",
      url: "https://example.com",
      description: "Project details.\n- Feature A\n- Feature B",
    },
  ],
  education: [
    {
      degree: "Your Degree",
      institution: "Your University",
      date: "2016 - 2020",
    },
  ],
  skills: [
    {
      list: ["Skill 1", "Skill 2", "Skill 3"],
    },
  ],
};

export const ResumePreview = ({ readOnly = false, persistent = true }) => {
  const { resume, resumeTailored, loadingResume, highlightedItems, setResume } = useResumeStore();
  const { user } = useUserStore((state) => state.user);
  const isResumeEmpty = !resume || !Object.keys(resume).length;
  const resumeToUse = isResumeEmpty ? placeholderResume : persistent ? resume : resumeTailored;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTab, setCurrentTab] = useState(new URLSearchParams(location.search).get("tab") || "contact");
  const [isUploading, setIsUploading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const { title, summary, email, phone, linkedin, github, address } = resumeToUse || {};
  const experiences = resumeToUse?.experiences || [];
  const projects = resumeToUse?.projects?.filter((p) => p.title) || [];
  const education = resumeToUse?.education || [];
  const skills = resumeToUse?.skills || [];

  const sortedExperiences = sortItemsByDateDesc(experiences);
  const sortedEducation = sortItemsByDateDesc(education);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab") || "contact";
    setCurrentTab(tab);
  }, [location.search]);

  const handleSubmit = async () => {
    if (!isResumeEmpty && (!resume.title || !resume.country)) {
      toast.warning("Please fill job title and country");
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("tab", "contact");
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    } else {
      setLoading(true);
      try {
        await addUserData(user?.uid, user);
      } catch (error) {
        console.error("Error adding user data:", error);
      } finally {
        setLoading(false);
      }
      navigate("/dashboard", { state: { fetchJobsOnLoad: true } });
    }
  };

  const skillClass = (skill) =>
    `px-2 py-1 rounded bg-gray-200 text-sm ${
      highlightedItems?.has(skill) ? "text-white bg-indigo-500 shadow-lg shadow-indigo-500/50" : ""
    }`;

  const handleEditClick = () => {
    setIsEditing((prev) => !prev);
    const element = document.getElementById("resumePreview");
    setTimeout(() => {
      if (element) element.focus();
    }, 100);
  };

  const handleFileUpload = async (e) => {
    try {
      setIsUploading(true);
      const file = e.target.files[0];
      if (!file) {
        setIsUploading(false);
        return;
      }
      const userData = await uploadResume(file);
      await addUserData(userData.uid, userData);
      setResume(userData.cv);
      setIsUploading(false);
      navigate("/resume/edit");
    } catch (error) {
      setIsUploading(false);
    }
  };

  /**
   * Before sending the resume to the server for PDF generation,
   * remove all "\n" from the summary, experiences, and projects descriptions.
   */
  const handleDownloadClick = async () => {
    if (!resume || !Object.keys(resume).length) {
      navigate("/resume");
      return;
    }

    // Create a deep copy of the resume to strip out newlines
    const cleanedResume = JSON.parse(JSON.stringify(resume));

    // Remove '\n' from summary
    if (cleanedResume.summary) {
      cleanedResume.summary = cleanedResume.summary.replace(/\n/g, "");
    }

    // Remove '\n' from experiences
    if (cleanedResume.experiences && Array.isArray(cleanedResume.experiences)) {
      cleanedResume.experiences = cleanedResume.experiences.map((exp) => {
        if (exp.description) {
          exp.description = exp.description.replace(/\n/g, "");
        }
        return exp;
      });
    }

    // Remove '\n' from projects
    if (cleanedResume.projects && Array.isArray(cleanedResume.projects)) {
      cleanedResume.projects = cleanedResume.projects.map((proj) => {
        if (proj.description) {
          proj.description = proj.description.replace(/\n/g, "");
        }
        return proj;
      });
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/generate_resume`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ resume_data: cleanedResume }),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      const base64Data = data.pdf_base64;
      const binaryData = atob(base64Data);
      const byteNumbers = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteNumbers[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "updated_resume.pdf";
      link.click();
    } catch (error) {
      // Handle error (e.g., show a toast)
    }
  };

  return (
    <div className="flex flex-col grow max-w-4xl w-full relative">
      {isUploading && (
        <div className="absolute inset-0 bg-white bg-opacity-80 z-50 flex items-center justify-center flex-col">
          <svg
            aria-hidden="true"
            className="inline w-10 h-10 text-gray-200 animate-spin fill-purple"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 
              50 100.591C22.3858 100.591 0 78.2051 
              0 50.5908C0 22.9766 22.3858 0.59082 
              50 0.59082C77.6142 0.59082 100 
              22.9766 100 50.5908ZM9.08144 
              50.5908C9.08144 73.1895 27.4013 
              91.5094 50 91.5094C72.5987 
              91.5094 90.9186 73.1895 
              90.9186 50.5908C90.9186 27.9921 
              72.5987 9.67226 50 9.67226C27.4013 
              9.67226 9.08144 27.9921 
              9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 
              38.4038 97.8624 35.9116 97.0079 
              33.5539C95.2932 28.8227 92.871 
              24.3692 89.8167 20.348C85.8452 
              15.1192 80.8826 10.7238 75.2124 
              7.41289C69.5422 4.10194 63.2754 
              1.94025 56.7698 1.05124C51.7666 
              0.367541 46.6976 0.446843 41.7345 
              1.27873C39.2613 1.69328 37.813 
              4.19778 38.4501 6.62326C39.0873 
              9.04874 41.5694 10.4717 44.0505 
              10.1071C47.8511 9.54855 51.7191 
              9.52689 55.5402 10.0491C60.8642 
              10.7766 65.9928 12.5457 70.6331 
              15.2552C75.2735 17.9648 79.3347 
              21.5619 82.5849 25.841C84.9175 
              28.9121 86.7997 32.2913 88.1811 
              35.8758C89.083 38.2158 91.5421 
              39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <p className="text-lg font-semibold text-gray-700 mt-4">
            Uploading and analyzing your new resume...
          </p>
        </div>
      )}
      <div className="flex justify-between items-center mb-5">
        <div>
          {isResumeEmpty ? (
            <h2 className="text-2xl font-extrabold text-purple">
              Make sure to fill in the Experience, Education, and Skills sections to create your resume.
            </h2>
          ) : (
            <>
              <h2 className="text-2xl font-extrabold text-purple">Preview CV</h2>
              <p className="text-sm text-gray-600" style={{ fontSize: "13px" }}>
                This isn't the resume style.
              </p>
              <p className="text-sm text-gray-600" style={{ fontSize: "13px" }}>
                Our PDF style is made to pass any ATS system
              </p>
            </>
          )}
        </div>
      </div>
      <div className="w-full relative grow">
        {loadingResume ? (
          <div className="absolute w-full h-full flex items-center justify-center">
            <svg className="w-12 h-12 text-gray-300 animate-spin" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762" stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-purple" ></path></svg>
          </div>
        ) : (
          <>
            {!readOnly && (
              <div className="absolute top-0 right-0 m-4">
                <div className="relative inline-block text-left">
                  <button
                    onClick={() => setMenuOpen((prev) => !prev)}
                    className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple"
                  >
                    <svg
                      className="w-6 h-6 text-gray-600"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="5" r="1.5" />
                      <circle cx="12" cy="12" r="1.5" />
                      <circle cx="12" cy="19" r="1.5" />
                    </svg>
                  </button>
                  {menuOpen && (
                    <div
                      className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-md shadow-lg z-10"
                      onBlur={() => setMenuOpen(false)}
                    >
                      <button
                        onClick={handleDownloadClick}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Download
                      </button>
                      <label
                        htmlFor="replaceCV"
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      >
                        Replace CV
                      </label>
                      <input
                        id="replaceCV"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        className="hidden"
                        onChange={handleFileUpload}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!persistent && (
              <div className="flex justify-end absolute right-0 mt-6 me-6">
                <img
                  src={editIcon}
                  alt="Edit Icon"
                  className="h-6 w-6 cursor-pointer"
                  onClick={handleEditClick}
                />
              </div>
            )}
            <div
              id="resumePreview"
              onBlur={() => setIsEditing(false)}
              contentEditable={isEditing}
              className="min-h-full pl-8 pr-8 text-gray-700 pt-6 py-8 overflow-y-auto bg-white border-2 border-dashed border-gray-300 rounded-lg"
            >
              <h2 id="previewName" className="text-2xl font-bold text-indigo-600 mb-2">
                {`${resumeToUse?.first_name || ""} ${resumeToUse?.last_name || ""}`}
              </h2>
              {title && (
                <p
                  id="previewJobTitle"
                  className="text-lg italic text-gray-500 mb-4"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></p>
              )}
              {summary && (
                <p
                  id="previewSummary"
                  className="text-sm pb-4"
                  dangerouslySetInnerHTML={{
                    __html: formatText(summary),
                  }}
                ></p>
              )}
              {(email || phone || linkedin || github) && (
                <div className="border-b-[1px] border-gray-300 mb-4 pb-4">
                  <h3 className="text-lg font-bold text-indigo-600 mb-3">Contact Information</h3>
                  <p className="text-sm mb-2">{email}</p>
                  <p className="text-sm mb-2">{phone}</p>
                  <p className="text-sm mb-2">{linkedin}</p>
                  <p className="text-sm mb-2">{github}</p>
                  <p className="text-sm">{address}</p>
                </div>
              )}
              {sortedExperiences.length > 0 && (
                <div className="border-b-[1px] border-gray-300 mb-4">
                  <h3 className="text-lg font-bold text-indigo-600 mb-3">Experience</h3>
                  {sortedExperiences.map((exp, index) => (
                    <div key={index} className="mb-4">
                      <p className="text-sm font-semibold">
                        {exp.title}{" "}
                        <span className="text-gray-500">
                          {exp.date === "undefined - undefined" || exp.date}
                        </span>
                      </p>
                      <p className="text-sm italic text-gray-600">{exp.company}</p>
                      <p
                        className="text-sm"
                        dangerouslySetInnerHTML={{
                          __html: formatText(exp.description),
                        }}
                      ></p>
                    </div>
                  ))}
                </div>
              )}
              {projects?.length > 0 && (
                <div className="border-b-[1px] border-gray-300 mb-4">
                  <h3 className="text-lg font-bold text-indigo-600 mb-3">Projects</h3>
                  {projects.map((proj, index) => (
                    <div key={index} className="mb-4">
                      <div className="text-sm font-semibold">{proj.title}</div>
                      <a
                        href={proj.url}
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-xs text-purple"
                      >
                        {proj.url}
                      </a>
                      <p
                        className="text-sm mt-3"
                        dangerouslySetInnerHTML={{
                          __html: formatText(proj.description),
                        }}
                      ></p>
                    </div>
                  ))}
                </div>
              )}
              {sortedEducation?.length > 0 && (
                <div className="mb-6">
                  <h3 className="text-lg font-bold text-indigo-600 mb-3">Education</h3>
                  {sortedEducation.map((edu, index) => (
                    <p key={index} className="text-sm mb-2">
                      <strong>{edu.degree}</strong> - {edu.institution}{" "}
                      {edu.date === "undefined - undefined" || edu.date}
                    </p>
                  ))}
                </div>
              )}
              {skills[0]?.list?.length > 0 && (
                <div>
                  <h3 className="text-lg font-bold text-indigo-600 mb-3">Skills</h3>
                  <ul className="flex flex-wrap gap-2">
                    {skills[0]?.list.map((skill, index) => (
                      <li key={index} className={skillClass(skill)}>
                        {skill}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
