export const getWebsiteLogo = (site) => {
  if (site === "indeed") {
    return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThz8Qi-G6jIHt6TmCOguWjOKGYYQPB1afpSQ&s";
  }
  if (site === "glassdoor") {
    return "https://static-00.iconduck.com/assets.00/glassdoor-icon-2048x2048-4di6xoda.png";
  }
  if (site === "linkedin") {
    return "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png";
  }
  if (site === "google") {
    return "https://img.icons8.com/?size=512&id=17949&format=png";
  }
  if (site === "zip_recruiter") {
    return "https://play-lh.googleusercontent.com/Z2MTVQ1XP6rIgBusW8ebjDWASv40-H4TxUEl5FaCN78gt_goEZwrlDjo25tGDc8Oe9s";
  }
  return "";
};

export const formatDatePosted = (datePosted) => {
  const date = new Date(datePosted);
  if (isNaN(date.getTime())) return "N/A";
  const today = new Date();
  const diffTime = today - date;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 0) return "Today";
  if (diffDays === 1) return "1 day ago";
  if (diffDays < 7) return `${diffDays} days ago`;
  if (diffDays < 14) return "Last week";
  if (diffDays < 30) {
    const weeks = Math.floor(diffDays / 7);
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  }
  if (diffDays < 365) {
    const months = Math.floor(diffDays / 30);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  }
  const years = Math.floor(diffDays / 365);
  return `${years} year${years > 1 ? "s" : ""} ago`;
};