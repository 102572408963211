import React, { useEffect, useState } from "react";
import { onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  fetchProductsData,
  fetchUserSubscriptionsData,
  createCheckoutSession,
  fetchFirstActiveUserSubscriptionDoc,
  getProductPriceId
} from "../../utils/firebase";
import Stripe from 'stripe';

const stripeXX= new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userSubscriptions, setUserSubscriptions] = useState(new Set());

  const auth = getAuth();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await fetchProductsData();
        setProducts(productsData);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products.");
      } finally {
        setLoading(false);
      }
    };

    const fetchUserSubscriptions = async () => {
      try {
        const subscribedProducts = await fetchUserSubscriptionsData();
        setUserSubscriptions(subscribedProducts);
      } catch (err) {
        console.error("Error fetching user subscriptions:", err);
      }
    };

    fetchProducts();
    fetchUserSubscriptions();
  }, [auth]);

  const handleSubscribe = async (productId) => {
    setLoading(true);
    try {
      if (userSubscriptions.size > 1) {
        alert("You are already subscribed to multiple plans. Please contact Admin.");
        return;
      } 
      else if (userSubscriptions.size === 1) {
        const confirmChange = window.confirm(
          "You are already subscribed to a plan. Do you want to continue?"
        );
        if (!confirmChange) {
          return;
        } else {
          const userSubscriptionDoc = await fetchFirstActiveUserSubscriptionDoc();
          const subscription_id = userSubscriptionDoc.id;
          const new_price_id = await getProductPriceId(productId);
          const item_id = userSubscriptionDoc.data().items[0].id;

          await stripeXX.subscriptions.update(subscription_id, {
            items: [{ id: item_id, price: new_price_id }],
          });
          window.location.reload();
        }
      } 
      else {
        const checkoutSessionsRef = await createCheckoutSession(productId);
        onSnapshot(checkoutSessionsRef, (snap) => {
          const { error, url } = snap.data();
          if (error) {
            alert(`An error occurred: ${error.message}`);
          }
          if (url) {
            window.location.assign(url);
          }
        });
      }
    } catch (err) {
      console.error("Error handling subscription:", err);
      alert("An error occurred while attempting to subscribe or update your plan.");
    } finally {
      setLoading(false);
    }
  };

  function discount(productPrice) {
    const numericPrice = parseInt(productPrice, 10);
    let finalPrice = numericPrice + numericPrice * 0.3;
    return "$" + finalPrice.toString() + "/mo";
  }

  if (loading) {
    return <p>Loading... Please wait.</p>;
  }

  if (error) {
    return <p className="text-red-600 font-semibold">{error}</p>;
  }

  return (
    <div className="flex flex-wrap gap-6 justify-center">
      {products.map((product) => {
        const isSubscribed = userSubscriptions?.has(product.id);
        const isMostPopular = product.mostPopular === true;

        return (
          <div
            key={product.id}
            className="bg-white p-4 rounded-md shadow w-full md:w-1/3 lg:w-1/4 relative border border-purple"
          >
            {isMostPopular && (
              <span className="absolute top-2 right-2 bg-purple text-white text-xs px-1 py-1 rounded">
                Most Popular
              </span>
            )}
            <h3 className="text-lg font-bold text-gray-800">{product.name}</h3>
            <div className="mt-1 text-sm text-gray-500 line-through">
              {discount(product.metadata.company)}
            </div>
            <div className="flex items-baseline space-x-2 mt-1">
              <p className="text-2xl font-extrabold text-gray-900">
                {"$" + product.metadata.company}
                <span className="text-base font-bold">/mo</span>
              </p>
              {isMostPopular && (
                <span className="bg-purple text-white text-xs px-1 py-1 rounded">
                  Save 25%
                </span>
              )}
            </div>
            <p className="text-xs text-purple mt-1">
              {product.metadata.tagline}
            </p>

            {isSubscribed ? (
              <div className="text-purple px-2 py-1 mt-1 w-full font-bold">
                You are subscribed
              </div>
            ) : (
              <button
                onClick={() => handleSubscribe(product.id)}
                className="bg-purple text-white px-4 py-2 rounded mt-4 w-full font-bold hover:bg-purple"
              >
                Upgrade Now
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;
