import React, { useState, useEffect } from "react";
import useResumeStore from "../../../stores/resume/resumeStore";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import countryList from "../../../static/countryList.js";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { sendRequest } from "../../../utils/api.js";

// Fade-in animation styles for the typed text
const fadeInStyles = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}
`;

const ResumeCreatorContact = () => {
  const resume = useResumeStore((state) => state.resume);
  const updateResume = useResumeStore((state) => state.updateResume);
  const setIsSavedGlobal = useResumeStore((state) => state.setIsSaved);
  const isSaved = useResumeStore((state) => state.isSaved);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    linkedin: "",
    country: "",
    title: "",
    summary: "",
  });

  // States for the typing effect in the summary field
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [typedSummary, setTypedSummary] = useState("");
  const [textFullyTyped, setTextFullyTyped] = useState(false);

  const requiredFields = [
    "first_name",
    "last_name",
    "title",
    "summary",
    "phone",
    "email",
    "linkedin",
    "country",
  ];

  const [highlight, setHighlight] = useState({});
  const [countries, setCountries] = useState([]);

  /**
   * Load existing résumé data from store into local form state.
   */
  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...resume }));
  }, [resume]);

  /**
   * Whenever formData.summary changes externally (e.g., from store),
   * update our typedSummary so it stays in sync if the user navigates away and back.
   */
  useEffect(() => {
    setTypedSummary(formData.summary || "");
  }, [formData.summary]);

  /**
   * Highlight fields that are required but empty.
   */
  useEffect(() => {
    const newHighlight = {};
    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field].trim() === "") {
        newHighlight[field] = true;
      } else {
        newHighlight[field] = false;
      }
    });
    setHighlight(newHighlight);
  }, [formData]);

  /**
   * Fetch country list and build options for react-select
   */
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        // Here we use the local static countryList
        const data = countryList;
        const countryOptions = data.map((country) => ({
          value: country.value,
          label: country.name,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  /**
   * Updates formData whenever input fields change.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Mark as not saved if user is editing
    if (isSaved) {
      setIsSavedGlobal(false);
    }
  };

  /**
   * React-select handler for country field
   */
  const handleCountryChange = (selectedOption) => {
    setFormData((prev) => ({ ...prev, country: selectedOption?.value || "" }));
    if (isSaved) {
      setIsSavedGlobal(false);
    }
  };

  /**
   * Calls your backend to generate an AI-driven summary,
   * then types it out chunk-by-chunk for a neat effect.
   */
  const handleAiSummary = async () => {
    try {
      setIsGeneratingSummary(true);
      setTypedSummary("");
      setTextFullyTyped(false);

      // You can customize what "resume_data" includes:
      // For example, it might contain the user's personal info from this form.
      // "resume_description" is the user’s existing summary text (if any).
      const payload = {
        resume_data: resume,
        resume_description: formData.summary || "",
      };

      // Make the request to /generate-resume-summary
      const response = await sendRequest(payload, "/generate-resume-summary");

      if (response.error) {
        toast.error(response.error);
        setIsGeneratingSummary(false);
        return;
      }

      // The backend should respond with { resume_summary: "..." }
      let newText = response.resume_summary || "";

      let index = 0;
      const chunkSize = 10;
      const intervalTime = 100;

      const typeInterval = setInterval(() => {
        const nextChunk = newText.substring(index, index + chunkSize);
        index += chunkSize;
        setTypedSummary((prev) => prev + nextChunk);

        if (index >= newText.length) {
          clearInterval(typeInterval);
          setTextFullyTyped(true);
          setIsGeneratingSummary(false);
          toast.success("Summary generated successfully!");
        }
      }, intervalTime);

    } catch (err) {
      console.error(err);
      toast.error("Something went wrong while generating the summary.");
      setIsGeneratingSummary(false);
    }
  };

  /**
   * Save the data (you might POST it or just update the store).
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateResume({
      ...formData,
      summary: typedSummary, // ensure we save the typed summary
    });
    toast.success("Contact Info saved successfully!");
  };

  // For react-select red highlight if country is missing
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: highlight.country ? "red" : provided.borderColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: highlight.country ? "red" : provided.borderColor,
      },
    }),
  };

  return (
    <>
      {/* Include our fade-in CSS keyframes */}
      <style>{fadeInStyles}</style>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar />
      <Tooltip id="tooltip" />

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex gap-3">
          <div className="grow">
            <label htmlFor="first_name" className="text-sm font-bold text-gray-700">
              FIRST NAME
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              placeholder="First Name"
              className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple
                ${highlight.first_name ? "border-red-500" : ""}`}
            />
          </div>

          <div className="grow">
            <label htmlFor="last_name" className="text-sm font-bold text-gray-700">
              LAST NAME
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              placeholder="Last Name"
              className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple
                ${highlight.last_name ? "border-red-500" : ""}`}
            />
          </div>
        </div>

        <div>
          <label htmlFor="title" className="text-sm font-bold text-gray-700">
            JOB TITLE
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="Job Title"
            className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple
              ${highlight.title ? "border-red-500" : ""}`}
          />
        </div>

        <div>
          <label htmlFor="summary" className="flex text-sm font-bold text-gray-700">
            WRITE A PROFESSIONAL SUMMARY
            <button
              type="button"
              onClick={handleAiSummary}
              className="flex items-center border border-purple rounded-xl ms-2 pl-2 pr-2"
              data-tooltip-id="tooltip"
              data-tooltip-content="Tailor AI suggestions for this section"
              disabled={isGeneratingSummary}
            >
              <svg
                className="mr-1"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5775 9.69376L14.6451 10.4064C13.0806 10.9833 11.8474 12.2165 11.2705 13.7809L10.5578 15.7134C10.531 15.786 
                    10.4617 15.8343 10.3843 15.8343C10.3068 15.8343 10.2376 15.786 10.2108 15.7134L9.49803 13.781C8.9211 12.2165 
                    7.68784 10.9833 6.12347 10.4064L4.19097 9.69376C4.11832 9.66697 4.07007 9.5977 4.07007 9.52026C4.07007 
                    9.44282 4.11832 9.37355 4.19097 9.34675L6.12358 8.63389C7.68795 8.05696 8.9211 6.82381 9.49803 5.25944L10.2108 
                    3.32683C10.2376 3.25418 10.3068 3.20593 10.3843 3.20593C10.4617 3.20593 10.531 3.25418 10.5578 3.32683L11.2705 
                    5.25944C11.8475 6.82381 13.0806 8.05707 14.645 8.63389L16.5776 9.34675C16.6503 9.37355 16.6985 9.44282 16.6985 
                    9.52026C16.6985 9.5977 16.6501 9.66697 16.5775 9.69376Z"
                  fill="#3F33C0"
                />
                <path
                  d="M7.02037 15.089L6.10417 15.4269C5.36255 15.7004 4.778 16.2849 4.50462 17.0266L4.16665 17.9425C4.15402 17.9771 
                    4.12112 17.9999 4.08442 17.9999C4.04771 17.9999 4.01482 17.9771 4.00219 17.9426L3.66433 17.0268C3.39084 16.2852 
                    2.80617 15.7005 2.06456 15.427L1.14835 15.0891C1.11394 15.0765 1.09106 15.0436 1.09106 
                    15.0069C1.09106 14.9702 1.11394 14.9373 1.14835 14.9247L2.06445 14.5867C2.80617 14.3132 
                    3.39084 13.7286 3.66433 12.9868L4.00219 12.0708C4.01482 12.0364 4.04771 12.0136 
                    4.08442 12.0136C4.12112 12.0136 4.15402 12.0364 4.16665 12.0708L4.50462 12.9869C4.77811 
                    13.7287 5.36266 14.3132 6.10438 14.5866L7.02048 14.9246C7.0549 14.9372 7.07777 14.9701 
                    7.07777 15.0068C7.07777 15.0435 7.0549 15.0764 7.02037 15.089Z"
                  fill="#3F33C0"
                />
                <path
                  d="M7.45795 4.80257L6.30561 5.22735C5.37285 5.57131 4.63745 6.30671 4.29327 7.23958L3.86839 
                    8.39192C3.85238 8.43516 3.8111 8.46402 3.76492 8.46402C3.71874 8.46402 3.67746 
                    8.43516 3.66145 8.39192L3.23656 7.23969C2.89261 6.30682 2.1572 5.57141 1.22422 
                    5.22735L0.0721028 4.80257C0.0286451 4.78667 0 4.74528 0 4.6991C0 4.65292 0.028754 
                    4.61153 0.0721028 4.59563L1.22433 4.17064C2.15709 3.82668 2.8925 3.09128 
                    3.23656 2.15841L3.66145 1.00607C3.67746 0.962719 3.71874 0.933967 3.76492 
                    0.933967C3.8111 0.933967 3.85249 0.962719 3.86839 1.00607L4.29338 2.15851C4.63734 
                    3.09127 5.37263 3.82657 6.3055 4.17064L7.45795 4.59563C7.5013 4.61153 7.53005 
                    4.65292 7.53005 4.6991C7.53005 4.74528 7.50141 4.78667 7.45795 4.80257Z"
                  fill="#3F33C0"
                />
              </svg>
              Generate with AI
            </button>
          </label>
          <textarea
            id="summary"
            name="summary"
            placeholder="Write your summary or use AI to generate one"
            className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple ${
              textFullyTyped ? "fade-in" : ""
            }`}
            rows={3}
            disabled={isGeneratingSummary}
            value={typedSummary}
            onChange={(e) => {
              setTypedSummary(e.target.value);
              setFormData((prev) => ({ ...prev, summary: e.target.value }));
              setIsSavedGlobal(false);
            }}
          />
        </div>

        <div>
          <label htmlFor="phone" className="text-sm font-bold text-gray-700">
            PHONE NUMBER
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone Number"
            className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple
              ${highlight.phone ? "border-red-500" : ""}`}
          />
        </div>

        <div>
          <label htmlFor="email" className="text-sm font-bold text-gray-700">
            EMAIL
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            className={`mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple
              ${highlight.email ? "border-red-500" : ""}`}
          />
        </div>

        <div>
          <label htmlFor="linkedin" className="text-sm font-bold text-gray-700">
            LINKEDIN
          </label>
          <input
            type="text"
            id="linkedin"
            name="linkedin"
            value={formData.linkedin}
            onChange={handleInputChange}
            placeholder="LinkedIn"
            className="mt-1 w-full p-2 border rounded-md text focus:ring-purple focus:border-purple"
          />
        </div>

        <div>
          <label htmlFor="country" className="text-sm font-bold text-gray-700">
            COUNTRY
          </label>
          <Select
            id="country"
            options={countries || []}
            value={countries.find((option) => option.value === formData.country) || null}
            onChange={handleCountryChange}
            placeholder="Select a country"
            styles={customSelectStyles}
          />
        </div>

        <div className="flex flex-col items-end justify-between gap-3 mt-6">
          <button
            type="submit"
            className="px-6 py-3 bg-purple text-white rounded-md shadow-md hover:bg-purple focus:ring focus:ring-purple"
            disabled={isGeneratingSummary} 
          >
            Save info
          </button>
          <div className="text-xs flex gap-2 text-gray-500">
            <span className={isSaved ? "text-green-500" : "text-orange-500"}>●</span>
            {isSaved ? "Saved" : "Not saved"}
          </div>
        </div>
      </form>
    </>
  );
};

export default ResumeCreatorContact;
