import { create } from 'zustand';

const useUserStore = create((set, get) => ({
  user: JSON.parse(localStorage.getItem('user')) || null,

  setUser: (newUser) => {
    set({ user: newUser });
    localStorage.setItem('user', JSON.stringify(newUser));
  },

  updateUser: (updatedFields) => {
    const currentUser = get().user || {};
    const updatedUser = { ...currentUser, ...updatedFields };
    set({ user: updatedUser });
    localStorage.setItem('user', JSON.stringify(updatedUser));
  },

  getUser: () => get().user,

  deleteUser: () => {
    set({ user: null });
    localStorage.removeItem('user');
  },
}));

export default useUserStore;
export const { setUser } = useUserStore.getState();