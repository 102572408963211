// src/components/Resume/EditResume.jsx
import React, { useEffect } from "react";
import "./styles.css";
import ResumeCreator from "./ResumeCreator.jsx";
import { ResumePreview } from "./ResumePreview.jsx";
import { useNavigate } from "react-router-dom";
import useResumeStore from "../../stores/resume/resumeStore.js";

const EditResume = () => {
  const navigate = useNavigate();
  const resume = useResumeStore((state) => state.resume);
  const isSaved = useResumeStore((state) => state.isSaved);

  useEffect(() => {
    if (!resume || !Object.keys(resume).length) {
      navigate("/resume");
    }
  }, [resume, navigate]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isSaved) {
        e.preventDefault();
        e.returnValue =
          "Are you sure you want to leave? All your information won't be saved.";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isSaved]);

  return (
    <div className="flex w-full h-full bg-white rounded-lg shadow-md overflow-hidden">
      <div className="w-[40%]">
        <ResumeCreator />
      </div>
      <div className="w-[60%] p-5 bg-gray-50 flex flex-col">
        <ResumePreview />
      </div>
    </div>
  );
};

export default EditResume;
