import React from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import linkedin_instructions from '../../assets/linkedin-instruction.webp';
const LinkedinUploadModal = ({
  isOpen,
  onClose,
  onFileUpload,
  isUploading,
  loadingMessage,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 flex w-screen items-center justify-center p-4"
    >
      
      {/* BACKDROP */}
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="w-full max-w-md rounded-lg bg-white p-6 relative">
          <button
            onClick={onClose}
            className="absolute pr-2 top-2 right-2 text-gray-400 hover:text-gray-600 text-2xl font-bold"
          >
            &times;
          </button>
          <DialogTitle className="text-lg font-semibold mb-4">
            Upload your LinkedIn profile
          </DialogTitle>

          {/* The instructions section */}
          <div className="space-y-4">
            <p>
              <strong>Step 1:</strong> If you haven’t already, save your
              LinkedIn profile as a PDF.
            </p>
            <div className="border rounded-lg p-3">
              {/* The screenshot or some placeholder image */}
              <img
                src={linkedin_instructions}
                alt="LinkedIn Save to PDF instructions"
                className="w-full h-auto"
              />
            </div>
            <p>
              <strong>Step 2:</strong> Come back here and upload it:
            </p>
          </div>

          {/* The Upload button */}
          <div className="mt-4">
            <label
              htmlFor="linkedin-pdf-upload"
              className={`inline-flex items-center px-4 py-2 bg-purple text-white rounded cursor-pointer ${
                isUploading ? "opacity-70 pointer-events-none" : ""
              }`}
            >
              Upload your saved LinkedIn PDF
            </label>
            <input
              id="linkedin-pdf-upload"
              type="file"
              accept=".pdf"
              onChange={onFileUpload}
              className="hidden"
            />
          </div>

          {/* Optional “loading” overlay if user is uploading */}
          {isUploading && (
            <div className="absolute inset-0 bg-white/80 flex flex-col items-center justify-center z-10">
              <svg
                aria-hidden="true"
                className="w-10 h-10 text-gray-200 animate-spin fill-purple"
                viewBox="0 0 100 101"
                fill="none"
              >
                <path d="M100 50.5908C100 78.2051 77.6142 ... (etc) ..." />
                <path d="M93.9676 39.0409C96.393 ... (etc) ... fill='currentFill'" />
              </svg>
              <p className="text-lg font-semibold text-gray-700 mt-4">
                {loadingMessage}
              </p>
            </div>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default LinkedinUploadModal;