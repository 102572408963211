import React, { useState, useEffect, useMemo } from "react";
import Button from "../../Elements/ButtonAI.jsx";
import countryList from "../../../static/countryList.js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import useResumeStore from "../../../stores/resume/resumeStore.js";
import Select from "react-select";
import useJobsStore from "../../../stores/resume/jobsStore.js";
import { seniorityOptions } from "../jobOptions.js";
import {
  auth,
  getUser,
  fetchProductsData,
  fetchUserSubscriptionsData,
  checkAndIncrementDailyCvUse,
} from "../../../utils/firebase";
import { onAuthStateChanged } from "firebase/auth";
import PlanPopup from "../../Profile Settings/PaymentPopup.jsx";
import UserDashboardPlaceholder from "./UserDashboardPlaceholder.jsx";
import UserDashboardList from "./UserDashboardList.jsx";
import UserDashboardMoreInfo from "./UserDashboardMoreInfo.jsx";

const generateSalaryRanges = () => {
  const ranges = [];
  for (let i = 5; i < 300; i += 5) {
    const start = i;
    const end = i + 5;
    ranges.push({
      label: `${start}k - ${end}k`,
      value: { min: start * 1000, max: end * 1000 },
    });
  }
  return ranges;
};
const salaryRangeOptions = generateSalaryRanges();

const UserDashboard = () => {
  const navigate = useNavigate();
  const resume = useResumeStore((state) => state.resume);
  const jobs = useJobsStore((state) => state.jobs);
  const setSelectedJob = useJobsStore((state) => state.setSelectedJob);
  const setJobs = useJobsStore((state) => state.setJobs);

  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userPlan, setUserPlan] = useState("FREE");

  const [selectedJobForInfo, setSelectedJobForInfo] = useState(null);
  const [applyPopupJob, setApplyPopupJob] = useState(null);

  const [jobTitle, setJobTitle] = useState(resume?.title || "");
  const [locationState, setLocationState] = useState(resume?.country || "");
  const [selectedSeniority, setSelectedSeniority] = useState(null);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isRemotePreferred, setIsRemotePreferred] = useState(false);

  const [countries, setCountries] = useState([]);
  const [sortBy, setSortBy] = useState("recommended-desc");
  const [loading, setLoading] = useState(true);
  const [loadingJobs, setLoadingJobs] = useState([]);
  const [error, setError] = useState(null);
  const [messageIndex, setMessageIndex] = useState(0);
  const [isPlanPopupOpen, setIsPlanPopupOpen] = useState(false);

  // NEW STATE: Whether user has exceeded daily usage
  const [exceededDailyLimit, setExceededDailyLimit] = useState(false);

  const messages = [
    "We're diving into your resume to unlock your full potential.",
    "We're analyzing your skills to find the perfect match for you.",
    "Exploring the best opportunities across the internet—just for you.",
    "We're connecting the dots to find jobs that align with your goals.",
    "Searching every corner of the web to bring top roles to your doorstep.",
    "Curating opportunities that match your unique profile.",
    "Matching your resume with the most relevant and exciting roles.",
    "Hang tight! We're ensuring no great opportunity is missed.",
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setLoading(true);
      try {
        if (firebaseUser) {
          const user = await getUser(firebaseUser.uid);
          setUserData(user);

          const [productsData, subscribedProducts] = await Promise.all([
            fetchProductsData(),
            fetchUserSubscriptionsData(),
          ]);
          const currentPlan = productsData.find((p) =>
            subscribedProducts?.has(p.id)
          );
          setUserPlan(currentPlan?.name || "FREE");
        } else {
          setUserData(null);
        }
      } catch (err) {
        console.error("Error loading user/product data:", err);
        setError("Failed to load user or product data.");
      } finally {
        setLoading(false);
        setIsDataLoaded(true);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!loading && !userData) {
      navigate("/login");
    }
  }, [loading, userData, navigate]);

  useEffect(() => {
    if (resume) {
      setJobTitle(resume.title || "");
      setLocationState(resume.country || "");
    }
  }, [resume]);

  useEffect(() => {
    setCountries(countryList.map(({ value, name }) => ({ value, label: name })));
  }, []);

  useEffect(() => {
    if (!jobs?.jobLists?.length && resume?.title && resume?.country) {
      fetchJobs();
    }
  }, [jobs]);

  const fetchJobsFromSources = async (
    sources,
    jobTitleVal,
    locationVal,
    summaryVal,
    descVal,
    minVal,
    maxVal,
    remoteVal,
    levelVal,
    industryVal
  ) => {
    const jobLists = [];
    await Promise.all(
      sources.map(async (site) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_JOBS_URL}/jobs`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              job_title: jobTitleVal,
              location: locationVal,
              salary_min: minVal,
              salary_max: maxVal,
              results_count: 30,
              job_summary: summaryVal || "",
              job_description: descVal || "",
              site_name: site,
              remote: remoteVal || false,
              job_level: levelVal || "",
              industry: industryVal || "",
            }),
          });
          if (!response.ok) {
            if (response.status === 500) {
              toast.error(
                "Failed to fetch jobs. Check if you filled Job Title or Location correctly."
              );
            } else if (response.status === 429) {
              toast.error("You have reached your daily request limit.");
            } else {
              toast.error(`Failed to fetch from ${site.toUpperCase()}.`);
            }
            throw new Error(`Failed to fetch jobs from ${site}`);
          }
          const { jobs } = await response.json();
          jobLists.push({ site, list: jobs });
        } catch (error) {
          console.error(`Error fetching jobs from ${site}:`, error);
        } finally {
          setLoadingJobs((prevLoadingJobs) =>
            prevLoadingJobs.filter((source) => source !== site)
          );
        }
      })
    );
    return jobLists;
  };

  const fetchJobs = async () => {
    if (!resume || !Object.keys(resume)?.length) {
      navigate("/resume");
      return;
    }
    if (!jobTitle.trim() || !locationState.trim()) {
      toast.warning("Job Title and Location are required.");
      return;
    }
    try {
      setLoading(true);
      const jobSources = ["indeed", "linkedin", "zip_recruiter", "glassdoor", "google"];
      setLoadingJobs(jobSources);

      // (1) Possibly fetch suggestions from /match-job
      let fetchedSuggestion = null;
      try {
        const matchRes = await fetch(`${process.env.REACT_APP_BASE_URL}/match-job`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            resume_data: resume,
          }),
        });
        if (matchRes.ok) {
          const data = await matchRes.json();
          const suggestions = data?.resume_data?.job_suggestions;
          if (Array.isArray(suggestions) && suggestions.length > 0) {
            fetchedSuggestion = suggestions[0];
          }
        }
      } catch (err) {
        console.error("Failed to match job suggestions from /match-job:", err);
      }

      const summaryText = fetchedSuggestion?.job_summary || "";
      const descText = fetchedSuggestion?.job_description || "";

      const min = selectedSalaryRange?.value?.min || 0;
      const max = selectedSalaryRange?.value?.max || 0;
      const levelVal = selectedSeniority?.value || "";
      const industryVal = selectedIndustry?.value || "";

      const jobLists = await fetchJobsFromSources(
        jobSources,
        jobTitle,
        locationState,
        summaryText,
        descText,
        min,
        max,
        isRemotePreferred,
        levelVal,
        industryVal
      );
      setJobs({
        location: locationState,
        job_title: jobTitle,
        jobLists,
      });
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setError("Error fetching jobs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const sortedJobLists = useMemo(() => {
    if (!jobs?.jobLists) return [];
    const allJobs = jobs.jobLists.flatMap((group) => group.list || []);
    const filtered = allJobs.filter((job) => {
      const score = parseFloat(job["Relevance Score"]) || 0;
      return score > 0.1;
    });
    const sorted = [...filtered];
    switch (sortBy) {
      case "recommended-desc":
        sorted.sort((a, b) => b["Relevance Score"] - a["Relevance Score"]);
        break;
      case "recommended-asc":
        sorted.sort((a, b) => a["Relevance Score"] - b["Relevance Score"]);
        break;
      case "recent-desc":
        sorted.sort((a, b) => new Date(b.date_posted) - new Date(a.date_posted));
        break;
      case "recent-asc":
        sorted.sort((a, b) => new Date(a.date_posted) - new Date(b.date_posted));
        break;
      default:
        break;
    }
    // limit to 30
    return sorted.slice(0, 30);
  }, [jobs, sortBy]);

  const handleApplyClick = (job) => {
    setApplyPopupJob(job);
  };

  const closeApplyPopup = () => {
    setApplyPopupJob(null);
  };

  const closeMoreInfo = () => {
    setSelectedJobForInfo(null);
  };

  const confirmApplyAndCustomCV = (job) => {
    if (!job) {
      toast.error("No job data available to proceed.");
      return;
    }
    setSelectedJob(job);
    localStorage.setItem("selectedJob", JSON.stringify(job));
    navigate("/custom-cv");
  };

  useEffect(() => {
    const checkPlanUsageIfPopup = async () => {
      if (!applyPopupJob) {
        setExceededDailyLimit(false);
        return;
      }
      if (!auth.currentUser?.uid) {
        setExceededDailyLimit(true);
        toast.error("Please log in first.");
        return;
      }
      try {
        const { allowed, remaining } = await checkAndIncrementDailyCvUse(
          auth.currentUser.uid,
          userPlan
        );
        if (!allowed) {
          setExceededDailyLimit(true);
        } else {
          setExceededDailyLimit(false);
        }
      } catch (err) {
        console.error("Error verifying usage:", err);
        setExceededDailyLimit(true);
      }
    };

    checkPlanUsageIfPopup();
  }, [applyPopupJob]);

  const sortDirectionIcon = (bool) =>
    bool ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="size-6"
      >
        <path
          fillRule="evenodd"
          d="M2.25 4.5A.75.75 0 0 1 3 3.75h14.25a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Zm0 4.5A.75.75 0 0 1 3 8.25h9.75a.75.75 0  
            0 1 0 1.5H3A.75.75 0 0 1 2.25 9Zm15-.75A.75.75 0 0 1 18  
            9v10.19l2.47-2.47a.75.75 0 1 1 1.06 1.06l-3.75  
            3.75a.75.75 0 0 1-1.06 0l-3.75-3.75a.75.75  
            0 1 1 1.06-1.06l2.47 2.47V9a.75.75 0 0 1  
            .75-.75Zm-15 5.25a.75.75 0 0 1  
            .75-.75h9.75a.75.75 0 0  
            1 0 1.5H3a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="size-6"
      >
        <path
          fillRule="evenodd"
          d="M2.25 4.5A.75.75 0 0 1 3  
            3.75h14.25a.75.75 0 0 1 0  
            1.5H3a.75.75 0 0 1-.75-.75Zm14.47  
            3.97a.75.75 0 0 1  
            1.06 0l3.75 3.75a.75.75 0 1 1-1.06  
            1.06L18 10.81V21a.75.75 0 0 1-1.5  
            0V10.81l-2.47 2.47a.75.75 0 1  
            1-1.06-1.06l3.75-3.75ZM2.25  
            9A.75.75 0 0 1 3  
            8.25h9.75a.75.75 0 0 1 0  
            1.5H3A.75.75 0 0 1 2.25  
            9Zm0 4.5a.75.75 0 0  
            1 .75-.75h5.25a.75.75 0 0 1  
            0 1.5H3a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </svg>
    );

  if (!isDataLoaded) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin fill-purple"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 
             100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 
             0.59082 50 0.59082C77.6142 0.59082 100 
             22.9766 100 50.5908ZM9.08144 
             50.5908C9.08144 73.1895 27.4013 
             91.5094 50 91.5094C72.5987 91.5094 
             90.9186 73.1895 90.9186 
             50.5908C90.9186 27.9921 72.5987 
             9.67226 50 9.67226C27.4013 
             9.67226 9.08144 27.9921 
             9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 
               35.9116 97.0079 33.5539C95.2932 
               28.8227 92.871 
               24.3692 89.8167 20.348C85.8452 
               15.1192 80.8826 10.7238 
               75.2124 7.41289C69.5422 4.10194 
               63.2754 1.94025 56.7698 
               1.05124C51.7666 0.367541 
               46.6976 0.446843 41.7345 
               1.27873C39.2613 1.69328 
               37.813 4.19778 38.4501 
               6.62326C39.0873 9.04874 
               41.5694 10.4717 44.0505 
               10.1071C47.8511 9.54855 
               51.7191 9.52689 55.5402 
               10.0491C60.8642 10.7766 
               65.9928 12.5457 70.6331 
               15.2552C75.2735 17.9648 
               79.3347 21.5619 82.5849 
               25.841C84.9175 28.9121 
               86.7997 32.2913 88.1811 
               35.8758C89.083 38.2158 
               91.5421 39.6781 
               93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen flex flex-col">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        draggable
        pauseOnHover
        theme="colored"
      />

      {applyPopupJob && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40 p-4">
          <div className="bg-white rounded-md p-6 max-w-sm w-full relative">
            <button
              onClick={closeApplyPopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
            <h2 className="text-lg text-purple font-bold mb-4">
              Enhance Your Resume Instantly!
            </h2>
            {exceededDailyLimit ? (
              <p className="text-sm text-gray-700 mb-6">
                You have exceeded your daily AI resume limit. Try again
                tomorrow or upgrade your plan.
              </p>
            ) : (
              <p className="text-sm text-gray-700 mb-6">
                Boost your interview chances with our AI-Generated resume
                service. Available for <strong>FREE</strong> for a limited time!
              </p>
            )}
            <div className="flex flex-col justify-start gap-2">
              {exceededDailyLimit ? (
                <button
                  disabled
                  className="bg-gray-400 text-white px-4 py-2 rounded-md cursor-not-allowed"
                >
                  custom resume limit exceed
                </button>
              ) : (
                <button
                  onClick={() => confirmApplyAndCustomCV(applyPopupJob)}
                  className="bg-purple text-white px-4 py-2 rounded-md"
                >
                  Yes, generate custom resume
                </button>
              )}
              {exceededDailyLimit ? ( 
              <a
                href={applyPopupJob.job_url}
                target="_blank"
                rel="noreferrer"
                className="border flex justify-center border-purple text-purple px-4 py-2 rounded-md"
              >
                Apply Now
              </a> 
              ) : (
              <a
                href={applyPopupJob.job_url}
                target="_blank"
                rel="noreferrer"
                className="border flex justify-center border-purple text-purple px-4 py-2 rounded-md"
              >
                No, Apply Now
              </a>
              )}
            </div>
          </div>
        </div>
      )}

      {selectedJobForInfo && (
        <UserDashboardMoreInfo
          job={selectedJobForInfo}
          handleApplyClick={(job) => handleApplyClick(job)}
        />
      )}


      <div className="flex flex-col items-center w-full min-h-screen">
        <div className="flex flex-wrap justify-center gap-6 w-full px-6 py-6 border-b border-gray-200">
          <div className="flex flex-col w-[200px]">
            <label className="text-sm text-gray-600 mb-1">Job Title *</label>
            <input
              className={`border ${
                !jobTitle.trim() ? "border-red-300" : "border-gray-300"
              } focus:outline-none rounded h-10 px-3 w-full`}
              type="text"
              placeholder="e.g. Frontend Engineer"
              value={jobTitle}
              onChange={(evt) => setJobTitle(evt.target.value)}
            />
          </div>

          <div className="flex flex-col w-[200px]">
            <label className="text-sm text-gray-600 mb-1">Location *</label>
            <Select
              id="country"
              className={`${
                !locationState.trim() ? "border border-red-300" : ""
              }`}
              options={countries || []}
              value={
                countries.find((option) => option.value === locationState) ||
                null
              }
              onChange={(evt) => setLocationState(evt.value)}
              placeholder="Select country"
            />
          </div>

          <div className="flex flex-col w-[200px]">
            <label className="text-sm text-gray-600 mb-1">Seniority</label>
            <Select
              className="w-full"
              options={seniorityOptions}
              value={selectedSeniority}
              onChange={(val) => setSelectedSeniority(val)}
              placeholder="Level"
            />
          </div>

          <div className="flex flex-col w-[100px]">
            <label className="text-sm text-gray-600 mb-1">Salary (Year)</label>
            <Select
              className="w-full"
              options={salaryRangeOptions}
              value={selectedSalaryRange}
              onChange={(val) => setSelectedSalaryRange(val)}
              placeholder="Range"
            />
          </div>

          <div className="flex flex-col w-[180px]">
            <label className="text-sm text-gray-600 mb-1">Industry</label>
            <Select
              className="w-full"
              options={[
                { value: "technology", label: "Technology" },
                { value: "healthcare", label: "Healthcare" },
                { value: "finance", label: "Finance" },
                { value: "education", label: "Education" },
                { value: "real_estate", label: "Real Estate" },
                { value: "retail", label: "Retail" },
                { value: "manufacturing", label: "Manufacturing" },
                { value: "hospitality", label: "Hospitality" },
                { value: "government", label: "Government" },
                { value: "non_profit", label: "Non-Profit" },
                { value: "agriculture", label: "Agriculture" },
                { value: "construction", label: "Construction" },
                { value: "transportation", label: "Transportation" },
                { value: "logistics", label: "Logistics" },
                { value: "entertainment", label: "Entertainment" },
                { value: "media", label: "Media" },
                { value: "energy", label: "Energy" },
                { value: "utilities", label: "Utilities" },
                { value: "telecommunications", label: "Telecommunications" },
                { value: "automotive", label: "Automotive" },
                { value: "aerospace", label: "Aerospace" },
                { value: "pharmaceuticals", label: "Pharmaceuticals" },
                { value: "insurance", label: "Insurance" },
                { value: "legal", label: "Legal" },
                { value: "arts_and_culture", label: "Arts and Culture" },
                { value: "sports", label: "Sports" },
                { value: "tourism", label: "Tourism" },
                { value: "food_and_beverage", label: "Food and Beverage" },
                { value: "chemicals", label: "Chemicals" },
                { value: "electronics", label: "Electronics" },
                { value: "fashion", label: "Fashion" },
              ]}
              value={selectedIndustry}
              onChange={(val) => setSelectedIndustry(val)}
              placeholder="Select industry"
            />
          </div>

          <div className="flex flex-col justify-end">
            <label className="text-sm text-gray-600 mb-3">Remote?</label>
            <input
              type="checkbox"
              checked={isRemotePreferred}
              onChange={(e) => setIsRemotePreferred(e.target.checked)}
              className="w-5 h-5 mb-2 accent-purple"
            />
          </div>

          <div className="flex mb-1 flex-col justify-end">
            <Button loading={loadingJobs?.length} action={fetchJobs} text="Find me a job" />
          </div>
        </div>

        {Boolean(!sortedJobLists?.length && !loadingJobs?.length) ? (
          <div className="bg-gray-50 flex items-center justify-center grow w-full">
            <div className="max-w-4xl pb-20 w-full text-center flex flex-col items-center">
              <div className="text-4xl font-semibold text-[#6861BA] mb-4 flex items-center justify-center">
                🥸
              </div>
              <h2 className="text-2xl font-bold text-[#3F33C0]">
                Complete Your Resume for Perfect Matches
              </h2>
              <p className="text-[#6861BA] mt-2">
                Your profile is nearly done! Confirm a few details to get
                the most accurate job matches and start your applications.
              </p>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                <div className="bg-white p-4 rounded-md shadow-md flex items-center gap-4">
                  <div className="flex-1">
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-xs text-green-600 bg-green-50 px-1 rounded">
                        Be an early applicant
                      </div>
                      <div className="text-xs text-pink-600 bg-pink-200 px-1 rounded">
                        strong match
                      </div>
                    </div>
                    <div className="h-4 bg-[#6861BA]/20 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-[#6861BA]/20 rounded w-1/2 mb-2"></div>
                    <div className="h-3 bg-[#6861BA]/20 rounded w-full mb-2"></div>
                    <div className="h-3 bg-[#6861BA]/20 rounded w-2/3 mb-2"></div>
                    <div className="border-t pt-3 flex gap-2">
                      <div className="h-8 w-16 bg-[#6861BA]/20 rounded"></div>
                      <div className="h-8 w-20 bg-[#6861BA]/20 rounded"></div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center min-w-[100px]">
                    <div className="relative w-16 h-16">
                      <svg
                        className="absolute inset-0 w-full h-full text-gray-100"
                        viewBox="0 0 36 36"
                      >
                        <circle
                          className="stroke-current"
                          cx="18"
                          cy="18"
                          r="16"
                          strokeWidth="2"
                          fill="none"
                        ></circle>
                        <circle
                          className="stroke-green-600"
                          cx="18"
                          cy="18"
                          r="16"
                          strokeWidth="2"
                          fill="none"
                          strokeDasharray="100"
                          strokeDashoffset="20"
                          strokeLinecap="round"
                          transform="rotate(-90 18 18)"
                        ></circle>
                      </svg>
                      <span className="absolute inset-0 flex items-center justify-center font-bold text-green-600">
                        80%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-md shadow-md flex items-center gap-4">
                  <div className="flex-1">
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-xs text-green-600 bg-green-50 px-1 rounded">
                        Be an early applicant
                      </div>
                      <div className="text-xs text-sky-600 bg-sky-200 px-1 rounded">
                        industry match
                      </div>
                    </div>
                    <div className="h-4 bg-[#6861BA]/20 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-[#6861BA]/20 rounded w-1/2 mb-2"></div>
                    <div className="h-3 bg-[#6861BA]/20 rounded w-full mb-2"></div>
                    <div className="h-3 bg-[#6861BA]/20 rounded w-2/3 mb-2"></div>
                    <div className="border-t pt-3 flex gap-2">
                      <div className="h-8 w-16 bg-[#6861BA]/20 rounded"></div>
                      <div className="h-8 w-20 bg-[#6861BA]/20 rounded"></div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center min-w-[100px]">
                    <div className="relative w-16 h-16">
                      <svg
                        className="absolute inset-0 w-full h-full text-gray-100"
                        viewBox="0 0 36 36"
                      >
                        <circle
                          className="stroke-current"
                          cx="18"
                          cy="18"
                          r="16"
                          strokeWidth="2"
                          fill="none"
                        ></circle>
                        <circle
                          className="stroke-green-600"
                          cx="18"
                          cy="18"
                          r="16"
                          strokeWidth="2"
                          fill="none"
                          strokeDasharray="100"
                          strokeDashoffset="5"
                          strokeLinecap="round"
                          transform="rotate(-90 18 18)"
                        ></circle>
                      </svg>
                      <span className="absolute inset-0 flex items-center justify-center font-bold text-green-600">
                        95%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-6xl w-full px-4 grow flex flex-col">
            {!!(sortedJobLists?.length > 0 && !loadingJobs?.length) && (
              <div className="mt-7 flex justify-end space-x-4 mb-4">
                <button
                  className="px-2 text-green-900 rounded-full text-sm mt-2 bg-green-300"
                  onClick={() => setIsPlanPopupOpen(true)}
                >
                  get hired faster with turbo ⚡
                </button>
                <button
                  className={`flex gap-2 text-sm items-center px-3 py-2 rounded ${
                    sortBy.includes("recommended")
                      ? "bg-purple text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() =>
                    setSortBy((prevSort) =>
                      prevSort === "recommended-desc"
                        ? "recommended-asc"
                        : "recommended-desc"
                    )
                  }
                >
                  Recommended {sortDirectionIcon(sortBy === "recommended-desc")}
                </button>
                <button
                  className={`flex gap-2 text-sm items-center px-3 py-2 rounded ${
                    sortBy.includes("recent")
                      ? "bg-purple text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() =>
                    setSortBy((prevSort) =>
                      prevSort === "recent-desc" ? "recent-asc" : "recent-desc"
                    )
                  }
                >
                  Most Recent {sortDirectionIcon(sortBy === "recent-desc")}
                </button>
              </div>
            )}

            {error && <p className="text-center text-red-500">{error}</p>}

            {Boolean(loadingJobs?.length > 0) && (
              <UserDashboardPlaceholder
                loadingJobs={loadingJobs}
                messages={messages}
                messageIndex={messageIndex}
              />
            )}

            {loadingJobs?.length === 0 && (
              <UserDashboardList
                list={sortedJobLists}
                userPlan={userPlan}
                onMoreInfoClick={(job) => setSelectedJobForInfo(job)}
                handleApplyClick={(job) => handleApplyClick(job)}
              />
            )}
          </div>
        )}
      </div>

      <PlanPopup
        show={isPlanPopupOpen}
        onClose={() => setIsPlanPopupOpen(false)}
        currentPlan={{ name: userPlan }}
      />
    </div>
  );
};

export default UserDashboard;
