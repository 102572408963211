import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { ResumePreview } from "./ResumePreview.jsx";
import useResumeStore from "../../stores/resume/resumeStore.js";
import cloneDeep from "lodash/cloneDeep";
import useJobsStore from "../../stores/resume/jobsStore.js";
import { useNavigate } from "react-router-dom";
import { ResumeTailorPanel } from "./ResumeTailorPanel.jsx";

const CustomResume = () => {
  const location = useLocation();
  const { resume, resumeTailored } = useResumeStore();
  const initializeResume = useResumeStore((state) => state.initializeResume);
  const selectedJob = useJobsStore((state) => state.selectedJob);
  const jobAnalyzedRef = useRef(false);
  const tailorPanelRef = useRef();

  const [customResume, setCustomResume] = useState(cloneDeep(resume));

  const [downloading, setDownLoading] = useState(false);
  const [analysing, setAnalysing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [jobDescription, setJobDescription] = useState("");
  const [showAnalysisPanel, setShowAnalysisPanel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const description = queryParams.get("description");
    if (description) {
      setJobDescription(description);
      console.log("Description from extension:", description);
    }
  }, [location.search]);

  useEffect(() => {
    if (localStorage.getItem("boardJobDescription")) {
      setJobDescription(localStorage.getItem("boardJobDescription"));
    }
  }, []);

  useEffect(() => {
    // Initialize resume if available
    initializeResume && initializeResume();
  }, [initializeResume]);

  useEffect(() => {
    if (resume) {
      setCustomResume(cloneDeep(resume));
    }
  }, [resume]);

  useEffect(() => {
    if (customResume && selectedJob && !jobAnalyzedRef.current) {
      jobAnalyzedRef.current = true;
      window.scrollTo({ top: 0 });
    }
  }, [resume, selectedJob]);

  const togglePopup = () => {
    if (!resume || !Object.keys(resume).length) {
      navigate("/resume");
      return;
    }
    setShowPopup(!showPopup);
  };

  const handleAnalyse = () => {
    setShowPopup(false);
    tailorPanelRef.current.analyse(jobDescription);
  };

  function removeMarkTags(obj) {
    if (typeof obj === "string") {
      // Remove <mark> and </mark> tags
      return obj.replace(/<\/?mark>/g, "");
    } else if (Array.isArray(obj)) {
      // Iterate over array elements
      return obj.map((item) => removeMarkTags(item));
    } else if (typeof obj === "object" && obj !== null) {
      // Iterate over object properties
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, removeMarkTags(value)])
      );
    }
    // Return other types as is
    return obj;
  }
  /*
  const handleDownloadClick = async () => {
    if (!resume || !Object.keys(resume).length) {
      navigate("/resume");
      return;
    }

    // Create a deep copy of the resume to strip out newlines
    const cleanedResume = JSON.parse(JSON.stringify(resume));

    // Remove '\n' from summary
    if (cleanedResume.summary) {
      cleanedResume.summary = cleanedResume.summary.replace(/\n/g, "");
    }

    // Remove '\n' from experiences
    if (cleanedResume.experiences && Array.isArray(cleanedResume.experiences)) {
      cleanedResume.experiences = cleanedResume.experiences.map((exp) => {
        if (exp.description) {
          exp.description = exp.description.replace(/\n/g, "");
        }
        return exp;
      });
    }

    // Remove '\n' from projects
    if (cleanedResume.projects && Array.isArray(cleanedResume.projects)) {
      cleanedResume.projects = cleanedResume.projects.map((proj) => {
        if (proj.description) {
          proj.description = proj.description.replace(/\n/g, "");
        }
        return proj;
      });
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/generate_resume`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ resume_data: cleanedResume }),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      const base64Data = data.pdf_base64;
      const binaryData = atob(base64Data);
      const byteNumbers = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteNumbers[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "updated_resume.pdf";
      link.click();
    } catch (error) {
      // Handle error (e.g., show a toast)
    }
  }; */
  const handleDownloadPDF = async () => {
    if (!resume || !Object.keys(resume).length) {
      navigate("/resume");
      return;
    }
    setDownLoading(true);
    try {
      // ---- Fixed backtick here ----
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/generate_resume`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ resume_data: removeMarkTags(resumeTailored) }),
      });

      if (!response.ok) {
        setDownLoading(false);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("PDF generation response:", data);

      const base64Data = data.pdf_base64;

      // Decode the base64 string
      const binaryData = atob(base64Data);

      // Convert binary string to ArrayBuffer
      const byteNumbers = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteNumbers[i] = binaryData.charCodeAt(i);
      }

      // Create a Blob object
      const blob = new Blob([byteNumbers], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.download = "updated_resume.pdf"; // Replace with desired filename
      link.click();

      // Revoke the URL to release memory
      URL.revokeObjectURL(url);

      navigate("/dashboard");
      selectedJob?.job_url && window.open(selectedJob.job_url, "_blank");

      setDownLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setDownLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-full">
      <div className="flex sticky justify-end py-3 w-full px-6 gap-3 shadow bg-white top-0 z-10">
        <button
          onClick={togglePopup}
          className="px-4 py-2 bg-purple text-sm text-white rounded-md shadow-md hover:bg-purple focus:ring focus:ring-purple"
        >
          Tailor CV to a Job
        </button>
        {!analysing && (
          <button
            onClick={() => handleDownloadPDF()}
            className="px-4 py-2 bg-purple text-sm text-white rounded-md shadow-md hover:bg-purple focus:ring focus:ring-purple"
          >
            Download {selectedJob?.job_url ? "and Apply" : ""}
          </button>
        )}
      </div>
      <div className="flex w-full bg-white rounded-lg shadow-md overflow-hidden grow">
        <div
          className={`min-h-full transition-all duration-500 ease-in-out ${
            showAnalysisPanel ? "w-[40%] opacity-100" : "w-0 opacity-0 overflow-hidden"
          } bg-white border-r-2 border-gray`}
        >
          <ResumeTailorPanel
            ref={tailorPanelRef}
            showAnalysisPanel={showAnalysisPanel}
            setShowAnalysisPanel={setShowAnalysisPanel}
          />
        </div>
        <div
          className={`transition-all duration-500 ease-in-out flex flex-col p-5 items-center ${
            showAnalysisPanel ? "w-[60%]" : "w-[100%]"
          } bg-gray-50`}
        >
          <ResumePreview readOnly={true} persistent={false} />
        </div>
      </div>
      {showPopup && (
        <div className="popup-overlay fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="popup-content w-full max-w-lg p-6 bg-white shadow-lg border border-gray-300">
          <h3 className="text-lg font-semibold mb-3">Enter Job Description</h3>
          
          <textarea
            className="w-full resize-none p-3 focus:outline-none border border-gray-300 mb-4"
            disabled={analysing}
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Paste the job description here..."
            rows="10"
            cols="50"
          />
      
          <div className="flex justify-end space-x-3">
            <button
              disabled={analysing}
              className={`${
                analysing ? 'bg-purple/50' : 'bg-purple'
              } text-white px-4 py-2 shadow-sm rounded-md`}
              onClick={handleAnalyse}
            >
              {analysing ? "Analizing..." : "Analyse job"}
            </button>
      
            {!analysing && (
              <button
                className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 shadow-sm rounded-md"
                onClick={togglePopup}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
      
      )}
    </div>
  );
};

export default CustomResume;
