import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { v4 as uuidv4 } from "uuid"; 

const firebaseConfig = {
  apiKey: "AIzaSyDmAVvW60ypN8PQv7Rgf_LeI05RkOICME8",
  authDomain: "gigshub-ff21e.firebaseapp.com",
  projectId: "gigshub-ff21e",
  storageBucket: "gigshub-ff21e.firebasestorage.app",
  messagingSenderId: "390721411415",
  appId: "1:390721411415:web:1452cdfe1b079b6f544612",
  measurementId: "G-1WKWMLX4SP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore and Auth
const database = getFirestore(app);
const auth = getAuth(app);

// Set auth persistence to local
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Error setting persistence:", error);
});

const provider = new GoogleAuthProvider();

/** Check if user document already exists */
async function checkUserExists(userId) {
  try {
    const userDoc = doc(database, "users", userId);
    const snapshot = await getDoc(userDoc);
    if (snapshot.exists()) {
      return true;
    } else {
      console.log("User does not exist or no users collection in the database.");
      return false;
    }
  } catch (error) {
    console.error("Error checking user:", error);
    return false;
  }
}

/** Create or update a user document */
const addUserData = async (userId, data) => {
  try {
    if (!userId) throw new Error("User ID is undefined");
    if (!data || typeof data !== "object") throw new Error("Invalid data");

    const sanitizedData = JSON.parse(JSON.stringify(data));
    const userDocRef = doc(database, "users", userId);
    const snapshot = await getDoc(userDocRef);

    if (!snapshot.exists()) {
      // Create new document if it doesn't exist
      await setDoc(userDocRef, sanitizedData);
    } else {
      // Update the existing document
      await updateDoc(userDocRef, sanitizedData);
    }
  } catch (error) {
    console.error("Error adding/updating user data:", error);
  }
};

/** Update user data in Firestore */
const updateUserData = async (userId, data) => {
  try {
    if (!userId) throw new Error("User ID is undefined");
    if (!data || typeof data !== "object") throw new Error("Invalid data");

    const sanitizedData = JSON.parse(JSON.stringify(data));
    const userDocRef = doc(database, "users", userId);

    await updateDoc(userDocRef, sanitizedData);
    console.log("User data updated successfully.");
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};

/** Get user's CV data (if stored) */
const getUserCVData = async (userId) => {
  try {
    if (!userId) throw new Error("User ID is undefined");

    const cvDoc = doc(database, "users", userId);
    const snapshot = await getDoc(cvDoc);

    if (snapshot.exists()) {
      return snapshot.data().cv;
    } else {
      console.log("No user data found for this user.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

/** Delete user's CV data (set cv=null) */
export const deleteUserCVData = async (userId) => {
  try {
    const userCVRef = doc(database, "users", userId);
    await updateDoc(userCVRef, { cv: null });
  } catch (error) {
    console.error("Error deleting user CV data:", error);
    throw error;
  }
};

/** Fetch active products from Firestore */
const fetchProductsData = async () => {
  try {
    const productsRef = collection(database, "products");
    const snapshot = await getDocs(productsRef);

    const productsData = snapshot.docs
      .filter((doc) => doc.data().active) // only active products
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

    return productsData;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

/** Fetch user's active subscriptions (by productId) */
const fetchUserSubscriptionsData = async () => {
  const userId = auth.currentUser?.uid;
  if (userId) {
    const subscriptionsRef = collection(database, "users", userId, "subscriptions");
    const q = query(subscriptionsRef, where("status", "==", "active"));
    const subscriptionSnapshot = await getDocs(q);
    const subscribedProducts = new Set();
    subscriptionSnapshot.forEach((doc) => {
      subscribedProducts.add(doc.data().product.id);
    });
    return subscribedProducts;
  }
  return new Set();
};

/** Fetch the first active subscription document for the user */
const fetchFirstActiveUserSubscriptionDoc = async () => {
  const userId = auth.currentUser?.uid;
  if (userId) {
    const subscriptionsRef = collection(database, "users", userId, "subscriptions");
    const q = query(subscriptionsRef, where("status", "==", "active"));
    const subscriptionSnapshot = await getDocs(q);
    const subscriptionDoc = subscriptionSnapshot.docs[0];
    return subscriptionDoc; // If none found, returns undefined
  }
  return null;
};

/** Get the Price ID of a product for subscription handling */
const getProductPriceId = async (productId) => {
  const priceRef = collection(database, "products", productId, "prices");
  const priceSnapshot = await getDocs(priceRef);
  return priceSnapshot.docs[0].id;
};

/** Create a new Stripe checkout session in Firestore */
const createCheckoutSession = async (productId) => {
  const priceId = await getProductPriceId(productId);
  const userId = auth.currentUser.uid;
  const checkoutSessionsRef = collection(database, "users", userId, "checkout_sessions");

  const document = {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  };
  const docRef = await addDoc(checkoutSessionsRef, document);
  console.log("Document written with ID: ", docRef.id);

  return docRef;
};

/** Get complete user object from Firestore */
const getUser = async (userId) => {
  try {
    if (!userId) throw new Error("User ID is undefined");
    const cvDoc = doc(database, "users", userId);
    const snapshot = await getDoc(cvDoc);

    if (snapshot.exists()) {
      return snapshot.data();
    } else {
      console.log("No user data found for this user.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

/** Delete user document from Firestore */
const deleteUserFromFirestore = async (userId) => {
  try {
    const userDocRef = doc(database, "users", userId);
    await deleteDoc(userDocRef);
    console.log("User document removed from Firestore.");
  } catch (error) {
    console.error("Error deleting user document:", error);
  }
};


const checkAndIncrementDailyCvUse = async (userId, plan) => {
  try {
    if (!userId) throw new Error("No userId provided.");

    const userDocRef = doc(database, "users", userId);
    const snapshot = await getDoc(userDocRef);
    if (!snapshot.exists()) {
      throw new Error("User document not found in Firestore.");
    }

    const userData = snapshot.data() || {};
    const cvUsage = userData.cvUsage || {};
    const prevCount = cvUsage.count || 0;
    const prevDate = cvUsage.lastUsedDate || null;

    const today = new Date().toISOString().split("T")[0];
    let usageCount = prevCount;
    let lastUsedDate = prevDate;

    // Reset usage if it's a new day
    if (lastUsedDate !== today) {
      usageCount = 0;
      lastUsedDate = today;
    }

    // Determine daily limit based on plan
    let dailyLimit = 1;
    switch ((plan || "").toUpperCase()) {
      case "STARTER":
        dailyLimit = 5;
        break;
      case "GROWTH":
        dailyLimit = 15;
        break;
      case "PREMIUM":
        dailyLimit = Infinity;
        break;
      default:
        dailyLimit = 1; // free plan
    }

    if (usageCount >= dailyLimit) {
      return { allowed: false, remaining: 0 };
    }

    usageCount++;
    await updateDoc(userDocRef, {
      cvUsage: {
        count: usageCount,
        lastUsedDate: lastUsedDate,
      },
    });

    return {
      allowed: true,
      remaining: dailyLimit === Infinity ? Infinity : dailyLimit - usageCount,
    };
  } catch (error) {
    console.error("Error in checkAndIncrementDailyCvUse:", error);
    throw error;
  }
};

export {
  auth,
  provider,
  database,
  checkUserExists,
  addUserData,
  updateUserData,
  getUserCVData,
  signInWithPopup,
  fetchProductsData,
  fetchUserSubscriptionsData,
  createCheckoutSession,
  getUser,
  deleteUserFromFirestore,
  getProductPriceId,
  fetchFirstActiveUserSubscriptionDoc,
  checkAndIncrementDailyCvUse,
};
