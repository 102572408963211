import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import starsUnfilled from "../../assets/starsUnfilled.svg";
import Select from "react-select";
import useResumeStore from "../../stores/resume/resumeStore";
import { sendRequest } from "../../utils/api";
import useJobsStore from "../../stores/resume/jobsStore";

// For simplicity, define months used in experiences
const MONTHS_OPTIONS = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1980 + 1 }, (_, i) => 1980 + i);
const YEARS_OPTIONS = years.map((year) => ({ value: String(year), label: String(year) }));
const END_YEARS_WITH_PRESENT = [{ value: "Present", label: "Present" }, ...YEARS_OPTIONS];

export const ResumeTailorPanel = forwardRef(({ showAnalysisPanel, setShowAnalysisPanel }, ref) => {
  const { resumeTailored, resume, setResumeTailored } = useResumeStore();
  const { addHighlightedItem } = useResumeStore.getState();
  const [analysisData, setAnalysisData] = useState(null);
  const [showExperienceModal, setShowExperienceModal] = useState(false);

  // Controls the "Add new experience or project" second popup
  const [showAddExpOrProjectModal, setShowAddExpOrProjectModal] = useState(false);
  // Which type the user wants to create, "experience" or "project"?
  const [newEntryType, setNewEntryType] = useState("experience");

  // We'll store a minimal set of fields to create a new EXPERIENCE
  const [newExperienceData, setNewExperienceData] = useState({
    title: "",
    company: "",
    location: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    description: "",
  });

  // We'll store a minimal set of fields to create a new PROJECT
  const [newProjectData, setNewProjectData] = useState({
    title: "",
    url: "",
    description: "",
  });

  const [selectedCompanies, setSelectedCompanies] = useState("");
  const [optimizationSuggestion, setOptimizationSuggestion] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [analysing, setAnalysing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const selectedJob = useJobsStore((state) => state.selectedJob);
  const initialized = useRef(false);
  const [highlightedItems, setHighlightedItems] = useState(new Set());

  useEffect(() => {
    if (!initialized.current) {
      if (resume && Object.keys(resume).length > 0) {
        setResumeTailored(JSON.parse(JSON.stringify(resume)));
        if (selectedJob) handleJobAnalyse(selectedJob?.description);
        initialized.current = true;
      }
      if (Object.keys(resumeTailored).length && selectedJob?.description) {
        handleJobAnalyse(selectedJob?.description);
        initialized.current = true;
      }
    }
  }, [selectedJob, resumeTailored]);

  const handleJobAnalyse = async (desc = jobDescription) => {
    setAnalysisData(null);
    setAnalysing(true);
    setShowAnalysisPanel(true);
    setShowPopup(false);

    if (!desc.trim()) {
      alert("Please enter a job description.");
      setAnalysing(false);
      return;
    }

    const payload = { resume_data: resumeTailored, job_description: desc };

    try {
      const response = await sendRequest(payload, "/optimize-cv-for-job");
      setAnalysisData(response.resume_data);
      setAnalysing(false);
    } catch (error) {
      console.error("Error sending data:", error);
      alert("Failed to send data.");
      setAnalysing(false);
    }
  };

  const {
    matching_score = 0,
    missing_skills,
    education_requirements,
    optimization_suggestions,
    searchability,
    job_title_match,
    recruiters_tips,
    grammar_corrections,
    summary_of_issues,
  } = analysisData || {};

  const weights = {
    missing_keywords: 2,
    missing_skills: 3,
    experience_gaps: 5,
    grammar_issues: 1,
  };

  const strokeColor =
    matching_score < 30 ? "#E74C3C" : matching_score > 65 ? "#27AE60" : "#F2994A";

  const handleSkillClick = (skill) => {
    if (!analysisData) return;
    // Update analysis and resume
    const updatedSkills = [...(resumeTailored.skills?.[0]?.list || []), skill];
    setResumeTailored({
      ...resumeTailored,
      skills: [{ ...resumeTailored.skills?.[0], list: updatedSkills }],
    });

    setAnalysisData((prev) => ({
      ...prev,
      missing_skills: {
        ...prev.missing_skills,
        hard_skills: prev.missing_skills.hard_skills.filter((s) => s !== skill),
      },
      summary_of_issues: {
        ...prev.summary_of_issues,
        missing_skills_count: Math.max(0, prev.summary_of_issues.missing_skills_count - 1),
        total_issues_count: Math.max(0, prev.summary_of_issues.total_issues_count - 1),
      },
      matching_score: calculateMatchingScore(),
    }));

    addHighlightedItem(skill);
  };

  // Handle adding new info from the selected experiences/projects
  const handleExperienceAdd = async () => {
    if (selectedCompanies.length === 0) {
      alert("Please select at least one item (experience or project).");
      return;
    }

    try {
      // We'll copy current resume & analysis states so we can mutate them as we process each selection
      let updatedResume = { ...resumeTailored };
      let updatedAnalysis = { ...analysisData };

      for (const item of selectedCompanies) {
        const itemValue = item.value || {};
        const payload = {
          experience_gap: optimizationSuggestion,
          exp_description: itemValue?.description ?? "",
        };

        const response = await sendRequest(payload, "/add-experience"); // your AI endpoint
        const updatedDescription = response.resume_data.experience_updated;

        if (itemValue.company) {
          // It's an experience
          updatedResume = {
            ...updatedResume,
            experiences: updatedResume.experiences.map((exp) =>
              exp.company === itemValue.company && exp.title === itemValue.title
                ? {
                    ...exp,
                    description: highlightAddedText(exp.description, updatedDescription),
                  }
                : exp
            ),
          };
        } else {
          // It's a project
          updatedResume = {
            ...updatedResume,
            projects: updatedResume.projects.map((proj) =>
              proj.title === itemValue.title
                ? {
                    ...proj,
                    description: highlightAddedText(proj.description, updatedDescription),
                  }
                : proj
            ),
          };
        }

        // Remove that suggestion from the array of optimization_suggestions
        // (assuming each selection is addressing the same 'optimizationSuggestion')
        updatedAnalysis = {
          ...updatedAnalysis,
          optimization_suggestions: updatedAnalysis.optimization_suggestions.filter(
            (suggestion) => suggestion !== optimizationSuggestion
          ),
          summary_of_issues: {
            ...updatedAnalysis.summary_of_issues,
            experience_gaps_count: Math.max(
              0,
              updatedAnalysis.summary_of_issues.experience_gaps_count - 1
            ),
            total_issues_count: Math.max(
              0,
              updatedAnalysis.summary_of_issues.total_issues_count - 1
            ),
          },
        };

        // Recalculate matching score after each addition
        updatedAnalysis.matching_score = calculateMatchingScore(updatedAnalysis.summary_of_issues);
      }

      // Finally, set the updated states
      setResumeTailored(updatedResume);
      setAnalysisData(updatedAnalysis);

      toggleExperienceModal();
    } catch (error) {
      console.error("Error adding experiences/projects:", error);
      alert("We had a problem. Please try again.");
    }
  };

  const handleRecruitersTips = (value, key) => {
    if (key === "personal_summary" && !resumeTailored?.summary && analysisData?.user_summary) {
      setResumeTailored({
        ...resumeTailored,
        summary: highlightAddedText("", analysisData.user_summary),
      });
    }

    setAnalysisData((prev) => ({
      ...prev,
      recruiters_tips: {
        ...prev.recruiters_tips,
        [key]: true,
      },
    }));
  };

  const handleJobTitle = (job_title_match) => {
    if (job_title_match?.length > 0) {
      const updatedResume = {
        ...resumeTailored,
        title: highlightAddedText(resumeTailored?.title || "", job_title_match),
      };
      setResumeTailored(updatedResume);
    }

    setTimeout(() => {
      setAnalysisData((prev) => ({
        ...prev,
        job_title_match: "",
      }));
    }, 100);
  };

  const calculateMatchingScore = (overrideSummaryOfIssues) => {
    // We can pass an updated summary_of_issues if we have one
    const usedSummary = overrideSummaryOfIssues || (analysisData && analysisData.summary_of_issues);
    if (!analysisData || !usedSummary) return 0;

    const {
      optimization_suggestions_count,
      missing_skills_count,
      total_issues_count,
    } = usedSummary;

    if (total_issues_count === 0) {
      return 100;
    }

    const safeMissingSkillsCount = Math.max(0, missing_skills_count);
    const safeOptimizationSuggestionsCount = Math.max(0, optimization_suggestions_count);

    const newScore =
      100 -
      (safeMissingSkillsCount * weights.missing_skills +
        safeOptimizationSuggestionsCount * weights.experience_gaps);

    return Math.max(0, Math.min(100, newScore));
  };

  const highlightAddedText = (originalText, updatedText) => {
    if (!originalText || !updatedText) return updatedText;
    const cleanOriginal = originalText.replace(/<mark>|<\/mark>/g, "");
    if (!cleanOriginal.trim()) {
      // If original text is empty, mark entire updated text
      return `<mark>${updatedText}</mark>`;
    }

    const index = updatedText.toLowerCase().indexOf(cleanOriginal.toLowerCase());
    if (index !== -1) {
      const before = updatedText.slice(0, index);
      const after = updatedText.slice(index + cleanOriginal.length);
      return (
        cleanOriginal +
        before.replace(/^|\n/g, "$&<mark>") +
        after.replace(/\n|$/g, "</mark>$&")
      );
    }
    // fallback if we can't find original
    return `<mark>${updatedText}</mark>`;
  };

  const toggleExperienceModal = (role) => {
    setOptimizationSuggestion(role || "");
    setJobDescription("");
    setSelectedCompanies([]);
    setShowExperienceModal(!showExperienceModal);
  };

  // ---- NEW: The second popup logic (Add Experience or Project) ----
  const openAddExpOrProjectModal = () => {
    // Close the first popup immediately
    setShowExperienceModal(false);

    // Reset all fields
    setNewEntryType("experience");
    setNewExperienceData({
      title: "",
      company: "",
      location: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
    });
    setNewProjectData({
      title: "",
      url: "",
      description: "",
    });

    setShowAddExpOrProjectModal(true);
  };

  const closeAddExpOrProjectModal = () => {
    setShowAddExpOrProjectModal(false);
  };

  const handleCreateNewEntry = () => {
    // Validate required fields
    if (newEntryType === "experience") {
      const {
        title,
        company,
        location,
        startMonth,
        startYear,
        endMonth,
        endYear,
        description,
      } = newExperienceData;
      if (
        !title.trim() ||
        !company.trim() ||
        !location.trim() ||
        !startMonth ||
        !startYear ||
        !endMonth ||
        !endYear ||
        !description.trim()
      ) {
        alert("All fields are mandatory for a new Experience.");
        return;
      }

      // All fields present, create the new Experience
      const newExp = {
        title: title.trim(),
        company: company.trim(),
        location: location.trim(),
        startMonth,
        startYear,
        endMonth,
        endYear,
        description: description.trim(),
      };

      const updatedExperiences = resumeTailored.experiences
        ? [...resumeTailored.experiences, newExp]
        : [newExp];

      setResumeTailored({
        ...resumeTailored,
        experiences: updatedExperiences,
      });
    } else {
      // Project
      const { title, url, description } = newProjectData;
      if (!title.trim() || !url.trim() || !description.trim()) {
        alert("All fields are mandatory for a new Project.");
        return;
      }

      const newProj = {
        title: title.trim(),
        url: url.trim(),
        description: description.trim(),
      };
      const updatedProjects = resumeTailored.projects
        ? [...resumeTailored.projects, newProj]
        : [newProj];

      setResumeTailored({
        ...resumeTailored,
        projects: updatedProjects,
      });
    }
    setShowAddExpOrProjectModal(false);
  };

  // We expose "analyse" so parent can call this ref method
  useImperativeHandle(ref, () => ({
    analyse: handleJobAnalyse,
  }));

  // For the second popup forms
  const handleExperienceFieldChange = (field, value) => {
    setNewExperienceData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleProjectFieldChange = (field, value) => {
    setNewProjectData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Build a single combined array of experiences + projects for the multi-select
  const experiencesOptions = (resumeTailored.experiences || []).map((exp) => ({
    label: `${exp.title} at ${exp.company} (Experience)`,
    value: exp,
    type: "experience",
  }));
  const projectsOptions = (resumeTailored.projects || []).map((proj) => ({
    label: `${proj.title} (Project)`,
    value: proj,
    type: "project",
  }));
  const combinedOptions = [...experiencesOptions, ...projectsOptions];

  // Check if the suggested job title is already the same as in the user's resume
  const isJobTitleSame =
    job_title_match &&
    resumeTailored?.title?.replace(/<mark>|<\/mark>/g, "").trim().toLowerCase() ===
      job_title_match.trim().toLowerCase();

  return (
    <>
      {analysing ? (
        <div className="h-lvh w-full flex items-center justify-center gap-3">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-400 fill-purple"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 
                100.591 50 100.591C22.3858 100.591 
                0 78.2051 0 50.5908C0 22.9766 22.3858 
                0.59082 50 0.59082C77.6142 0.59082 100 
                22.9766 100 50.5908ZM9.08144 50.5908C9.08144 
                73.1895 27.4013 91.5094 50 91.5094C72.5987 
                91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 
                27.9921 72.5987 9.67226 50 9.67226C27.4013 
                9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 
                38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 
                28.8227 92.871 24.3692 89.8167 20.348C85.8452 
                15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 
                4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 
                0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 
                1.69328 37.813 4.19778 38.4501 6.62326C39.0873 
                9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
                9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 
                10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 
                17.9648 79.3347 21.5619 82.5849 25.841C84.9175 
                28.9121 86.7997 32.2913 88.1811 35.8758C89.083 
                38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          Analysing CV...
        </div>
      ) : (
        <div className="p-7">
          {analysisData && (
            <>
              {/* Matching Score */}
              {matching_score !== undefined && (
                <div className="mt-5 text-center">
                  <h2 className="text-lg text-indigo-600 font-extrabold mb-2">Matching Score</h2>
                  <svg width="80" height="80" viewBox="0 0 36 36" className="inline-block">
                    <circle
                      cx="18"
                      cy="18"
                      r="16"
                      stroke="#E0E0E0"
                      strokeWidth="4"
                      fill="none"
                    ></circle>
                    <circle
                      cx="18"
                      cy="18"
                      r="16"
                      strokeDasharray="100"
                      strokeDashoffset={100 - matching_score}
                      stroke={strokeColor}
                      strokeWidth="4"
                      fill="none"
                      strokeLinecap="round"
                    ></circle>
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      fill={strokeColor}
                      fontSize="8px"
                      dy=".3em"
                    >
                      {matching_score}
                    </text>
                  </svg>
                  <p>{summary_of_issues?.total_issues_count || 0} issues found</p>
                </div>
              )}

              {/* Job Title Match */}
              {job_title_match?.length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Job Title Match</h3>
                  <div className="flex">
                    <p className="text-sm text-gray-600">{job_title_match}</p>
                    {isJobTitleSame ? (
                      <span className="ml-2 mt-1 text-green-600 text-xs flex items-center">
                        ✔
                      </span>
                    ) : (
                      <button
                        className="ml-2 mt-1 bg-indigo-600 text-white px-2 py-0 rounded-full text-xs flex items-center"
                        onClick={() => handleJobTitle(job_title_match)}
                      >
                        Add
                        <img
                          src={starsUnfilled}
                          alt="Stars Icon"
                          className="w-[8px] h-[8px] ml-1"
                        />
                      </button>
                    )}
                  </div>
                </div>
              )}

              {/* Recruiters' Tips */}
              {recruiters_tips && Object.keys(recruiters_tips).length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Recruiters' Tips</h3>
                  <ul className="list-none">
                    {Object.entries(recruiters_tips).map(([key, value], index) => {
                      const isPersonalSummary = key === "personal_summary";
                      const hasSummary = !!resumeTailored?.summary?.trim();
                      return (
                        <div className="flex items-center mb-2" key={index}>
                          <li className="text-sm text-gray-600">
                            {isPersonalSummary && hasSummary ? "" : "💡"}
                            {key.replace(/_/g, " ")}{" "}
                            {isPersonalSummary && hasSummary ? (
                              <span className="ml-2 mt-1 text-green-600 text-xs">✔</span>
                            ) : (
                              ""
                            )}
                          </li>
                          {/* Show "Add" button only if it's personal summary and user does NOT have a summary */}
                          {isPersonalSummary && !hasSummary && (
                            <button
                              className="ml-2 bg-indigo-600 text-white px-3 py-0 rounded-full text-xs flex items-center"
                              onClick={() => handleRecruitersTips(value, key)}
                            >
                              Add
                              <img
                                src={starsUnfilled}
                                alt="Stars Icon"
                                className="w-[8px] h-[8px] ml-1"
                              />
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}

              {/* Searchability */}
              {searchability && Object.keys(searchability).length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Searchability</h3>
                  <ul className="list-none">
                    {Object.entries(searchability).map(([key, value], index) => (
                      <li key={index} className="flex items-center justify-between mb-2">
                        <span>{key.replace(/_/g, " ")}</span>
                        <span className={`ml-2 ${value ? "text-green-600" : "text-red-600"}`}>
                          {value ? "Included" : "Missing"}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Optimization Suggestions */}
              {optimization_suggestions?.length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">
                    Optimization Suggestions
                  </h3>
                  <ul className="list-none">
                    {optimization_suggestions.map((suggestion, index) => (
                      <div className="flex items-center mb-2" key={index}>
                        <li className="text-sm text-gray-600">{suggestion}</li>
                        <button
                          className="ml-2 bg-indigo-600 text-white px-3 py-0 rounded-full text-xs flex items-center"
                          onClick={() => toggleExperienceModal(suggestion)}
                        >
                          Add
                          <img
                            src={starsUnfilled}
                            alt="Stars Icon"
                            className="w-[8px] h-[8px] ml-1"
                          />
                        </button>
                      </div>
                    ))}
                  </ul>
                </div>
              )}

              {/* Grammar Corrections */}
              {grammar_corrections?.length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">
                    Grammar Corrections
                  </h3>
                  <ul className="list-none">
                    {grammar_corrections.map((correction, index) => (
                      <li key={index} className="mb-2 text-sm text-gray-600">
                        ⚠ {correction}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Missing Education */}
              {education_requirements?.missing_degrees_or_certifications?.length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">
                    Missing Degrees or Certifications
                  </h3>
                  <ul className="list-none">
                    {education_requirements.missing_degrees_or_certifications.map(
                      (degree, index) => (
                        <li key={index} className="mb-2 text-sm text-gray-600">
                          {degree}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

              {/* Missing Skills */}
              {missing_skills?.hard_skills?.length > 0 && (
                <div className="mt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">Missing Skills</h3>
                  <ul className="list-none">
                    {missing_skills.hard_skills.map((skill, index) => (
                      <li
                        key={`${skill}-${index}`}
                        className="flex items-center mb-2 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          id={`skill-${skill}`}
                          className="hidden"
                          onChange={() => handleSkillClick(skill)}
                        />
                        <label
                          htmlFor={`skill-${skill}`}
                          className="relative pl-8 text-sm cursor-pointer"
                        >
                          <span className="absolute left-0 top-1/2 -translate-y-1/2 w-5 h-5 rounded-full bg-white border-2 border-indigo-900 transition"></span>
                          {skill}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}

          {/* "Where did you use this skill?" EXPERIENCE MODAL */}
          {showExperienceModal && (
            <div className="popup-overlay">
              <div
                className="popup-content rounded-lg p-6 max-w-[650px] mx-auto flex flex-col gap-4"
                style={{ minHeight: "250px" }}
              >
                <h3 className="mb-4 text-center text-2xl font-bold">
                  Where did you use this skill?
                </h3>
                <div className="mb-8 text-center">
                  {/* Now listing experiences + projects */}
                  <Select
                    id="companySelect"
                    options={combinedOptions}
                    isMulti
                    value={selectedCompanies}
                    onChange={(selected) => setSelectedCompanies(selected || [])}
                    placeholder="Select experiences or projects..."
                  />
                </div>
                <div className="flex flex-wrap items-center justify-center gap-3 my-2">
                  <button
                    className="bg-indigo-600 text-white px-6 py-2 rounded-md"
                    onClick={handleExperienceAdd}
                  >
                    Add experience/project with AI{" "}
                    <img
                      src={starsUnfilled}
                      alt="Stars Icon"
                      className="ml-2 w-4 h-4 inline-block"
                    />
                  </button>
                  <button
                    className="bg-gray-200 text-gray-700 px-6 py-2 rounded-md"
                    onClick={() => toggleExperienceModal()}
                  >
                    Cancel
                  </button>
                </div>

                {/* NEW BUTTON: "Add new experience or project" */}
                <div className="mt-4 text-center">
                  <button
                    className="text-indigo-600 underline"
                    onClick={openAddExpOrProjectModal}
                  >
                    + Add new experience or project
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* The second popup: "Add new experience or project" */}
          {showAddExpOrProjectModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 w-[600px] max-h-[80vh] overflow-y-auto relative">
                <h3 className="text-lg font-bold mb-4 text-center">
                  Add a New Experience or Project
                </h3>

                <div className="mb-4 text-sm">
                  <label className="block font-semibold mb-1">Choose type to add:</label>
                  <select
                    className="border p-2 w-full rounded"
                    value={newEntryType}
                    onChange={(e) => setNewEntryType(e.target.value)}
                  >
                    <option value="experience">Job Experience</option>
                    <option value="project">Project</option>
                  </select>
                </div>

                {newEntryType === "experience" && (
                  <div className="space-y-4 border p-4 rounded-md mb-4">
                    <div>
                      <label className="block text-xs font-bold mb-1">Title / Role</label>
                      <input
                        type="text"
                        className="w-full border p-2 rounded"
                        value={newExperienceData.title}
                        onChange={(e) => handleExperienceFieldChange("title", e.target.value)}
                        placeholder="e.g., Software Engineer"
                      />
                    </div>
                    <div>
                      <label className="block text-xs font-bold mb-1">Company</label>
                      <input
                        type="text"
                        className="w-full border p-2 rounded"
                        value={newExperienceData.company}
                        onChange={(e) => handleExperienceFieldChange("company", e.target.value)}
                        placeholder="e.g., Google"
                      />
                    </div>
                    <div>
                      <label className="block text-xs font-bold mb-1">Location</label>
                      <input
                        type="text"
                        className="w-full border p-2 rounded"
                        value={newExperienceData.location}
                        onChange={(e) => handleExperienceFieldChange("location", e.target.value)}
                        placeholder="e.g., San Francisco, CA"
                      />
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1">
                        <label className="block text-xs font-bold mb-1">Start Month</label>
                        <select
                          className="border p-2 w-full rounded"
                          value={newExperienceData.startMonth}
                          onChange={(e) =>
                            handleExperienceFieldChange("startMonth", e.target.value)
                          }
                        >
                          <option value="">--</option>
                          {MONTHS_OPTIONS.map((m) => (
                            <option key={m.value} value={m.value}>
                              {m.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex-1">
                        <label className="block text-xs font-bold mb-1">Start Year</label>
                        <select
                          className="border p-2 w-full rounded"
                          value={newExperienceData.startYear}
                          onChange={(e) =>
                            handleExperienceFieldChange("startYear", e.target.value)
                          }
                        >
                          <option value="">--</option>
                          {YEARS_OPTIONS.map((y) => (
                            <option key={y.value} value={y.value}>
                              {y.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1">
                        <label className="block text-xs font-bold mb-1">End Month</label>
                        <select
                          className="border p-2 w-full rounded"
                          value={newExperienceData.endMonth}
                          onChange={(e) =>
                            handleExperienceFieldChange("endMonth", e.target.value)
                          }
                          disabled={newExperienceData.endYear === "Present"}
                        >
                          <option value="">--</option>
                          {MONTHS_OPTIONS.map((m) => (
                            <option key={m.value} value={m.value}>
                              {m.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex-1">
                        <label className="block text-xs font-bold mb-1">End Year</label>
                        <select
                          className="border p-2 w-full rounded"
                          value={newExperienceData.endYear}
                          onChange={(e) =>
                            handleExperienceFieldChange("endYear", e.target.value)
                          }
                        >
                          <option value="">--</option>
                          {END_YEARS_WITH_PRESENT.map((y) => (
                            <option key={y.value} value={y.value}>
                              {y.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label className="block text-xs font-bold mb-1">Description</label>
                      <textarea
                        className="w-full border p-2 rounded"
                        rows={3}
                        value={newExperienceData.description}
                        onChange={(e) =>
                          handleExperienceFieldChange("description", e.target.value)
                        }
                        placeholder="What did you do/accomplish?"
                      ></textarea>
                    </div>
                  </div>
                )}

                {newEntryType === "project" && (
                  <div className="space-y-4 border p-4 rounded-md mb-4">
                    <div>
                      <label className="block text-xs font-bold mb-1">Project Title</label>
                      <input
                        type="text"
                        className="w-full border p-2 rounded"
                        value={newProjectData.title}
                        onChange={(e) => handleProjectFieldChange("title", e.target.value)}
                        placeholder="e.g., Portfolio Website"
                      />
                    </div>
                    <div>
                      <label className="block text-xs font-bold mb-1">Project URL</label>
                      <input
                        type="text"
                        className="w-full border p-2 rounded"
                        value={newProjectData.url}
                        onChange={(e) => handleProjectFieldChange("url", e.target.value)}
                        placeholder="e.g., https://github.com/username/portfolio"
                      />
                    </div>
                    <div>
                      <label className="block text-xs font-bold mb-1">Description</label>
                      <textarea
                        className="w-full border p-2 rounded"
                        rows={3}
                        value={newProjectData.description}
                        onChange={(e) => handleProjectFieldChange("description", e.target.value)}
                        placeholder="What is this project about?"
                      ></textarea>
                    </div>
                  </div>
                )}

                <div className="flex justify-end gap-3 mt-4">
                  <button
                    onClick={closeAddExpOrProjectModal}
                    className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCreateNewEntry}
                    className="px-4 py-2 bg-purple text-white rounded-md"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
});
