import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { addUserData } from "../../utils/firebase.js";
import { uploadResume } from "./uploadResume.jsx";
import useResumeStore from "../../stores/resume/resumeStore";
import LinkedinUploadModal from "./LinkedinUploadModal.jsx";
import ResumeSamples from "./ResumeSamples.jsx";
const Resume = () => {
  const { resume, loadingResume, setResume } = useResumeStore();
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [isLinkedInModalOpen, setIsLinkedInModalOpen] = useState(false);
  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const loadingMessages = [
    "Importing your resume...",
    "Analyzing your experience...",
    "Analyzing your projects...",
    "Analyzing your career paths...",
    "Identifying key skills...",
    "Almost done..."
  ];

  useEffect(() => {
    let interval;
    if (isUploading) {
      interval = setInterval(() => {
        setLoadingStep((prev) => {
          const next = prev + 1;
        
          if (next >= loadingMessages.length) {
            clearInterval(interval);
            return prev;
          }
          return next;
        });
      }, 4000); 
    } else {
      setLoadingStep(0);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isUploading]);

  useEffect(() => {
    if (resume && Object.keys(resume).length > 0) {
      navigate("/resume/edit");
    } else {
      console.log("No CV data found for the user.");
    }
  }, [resume, navigate]);
  const options = [
    {
      title: "From Scratch",
      description: "Use this option to build a new resume using our easy flow section by section"
    },
    {
      title: "Import your existing resume",
      description: "We'll fetch your data and autofill our resume builder tool, so you can review and edit any info.",
      action: "upload",
    },
    {
      title: "Create a resume from Linkedin profile",
      description: "Simply paste your linkedin profile URL and we’ll import it straight into our resume builder.",
      action: "linkedin",
      href: "/resume/edit",
    },
    {
      title: "Choose from a Template",
      description: "Use an existing, pre-built sample resume template with the perfect match the career you want",
      action: "template",
    }
  ];
 
  const handleLinkedInFileUpload = async (e) => {
    await handleFileUpload(e);
    setIsLinkedInModalOpen(false);
  };
  const handleFileUpload = async (e) => {
    try {
      setIsUploading(true); // Start uploading

      const file = e.target.files[0];
      if (!file) {
        setIsUploading(false);
        return;
      }
      const userData = await uploadResume(file);

      await addUserData(userData.uid, userData);
      
      setIsUploading(false);

      navigate("/resume/edit");
    } catch (error) {
      console.error("File upload failed:", error);
      setIsUploading(false);
    }
  };
  const newResume = () => {
    setResume({ title: '' });
    navigate('/resume/edit');
  }

  return (
    <>
      {loadingResume ? (
        <div className="w-full h-full fixed flex items-center justify-center">
          <div className="absolute w-full h-full flex items-center justify-center">
            <svg
              className="w-12 h-12 text-gray-300 animate-spin"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
            >
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 
                49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 
                58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 
                39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 
                52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 
                60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 
                58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 
                49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 
                3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 
                8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 
                20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="currentColor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 
                6.16256C49.2477 8.24138 52.7762 11.2562 55.466 
                14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 
                27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 
                40.9762"
                stroke="currentColor"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-purple"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full w-full bg-white rounded-lg overflow-hidden">
          {/* FULL-SCREEN OVERLAY WHILE UPLOADING */}
          {isUploading && (
            <div className="absolute inset-0 bg-white bg-opacity-80 flex flex-col items-center justify-center z-50">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-10 h-10 text-gray-200 animate-spin fill-purple"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 
                    100.591C22.3858 100.591 0 78.2051 0 50.5908C0 
                    22.9766 22.3858 0.59082 50 0.59082C77.6142 
                    0.59082 100 22.9766 100 50.5908ZM9.08144 
                    50.5908C9.08144 73.1895 27.4013 91.5094 50 
                    91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 
                    50.5908C90.9186 27.9921 72.5987 9.67226 50 
                    9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 
                    50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 
                    38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 
                    28.8227 92.871 24.3692 89.8167 20.348C85.8452 
                    15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 
                    4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 
                    0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 
                    1.69328 37.813 4.19778 38.4501 6.62326C39.0873 
                    9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
                    9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 
                    10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 
                    17.9648 79.3347 21.5619 82.5849 25.841C84.9175 
                    28.9121 86.7997 32.2913 88.1811 35.8758C89.083 
                    38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>

              {/* Show progressive loading messages */}
              <p className="text-lg font-semibold text-gray-700 mt-4">
                {loadingMessages[loadingStep]}
              </p>
            </div>
          )}

          <div
            className={`max-w-md ${
              isUploading ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            <h1 className="text-3xl font-semibold mb-6">
              How would you like to create your resume?
            </h1>

            {/* Options */}
            <div className="space-y-4 w-full max-w-md">
            {options.map((option, index) => {
                if (option.action === "upload"){
                  return(
                  <label
                    key={index}
                    htmlFor="file-upload"
                    className="w-full block text-start py-4 px-6 bg-white rounded-lg border border-gray-300 hover:border-purple focus:outline-none focus:ring-2 focus:ring-indigo-500 cursor-pointer"
                  >
                    <h2 className="text-md font-bold mb-1">{option.title}</h2>
                    <p className="text-sm text-gray-600">
                      {option.description}
                    </p>
                    <input
                      type="file"
                      id="file-upload"
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                      onChange={handleFileUpload}
                    />
                  </label>
                  );
                  } else if (option.action === "linkedin") {
                  return (
                    <button
                      key={index}
                      type="button"
                      className="w-full text-start py-4 px-6 bg-white rounded-lg border border-gray-300 hover:border-purple"
                      onClick={() => setIsLinkedInModalOpen(true)}
                      disabled={isUploading}
                    >
                      <h2 className="text-md font-bold mb-1">{option.title}</h2>
                      <p className="text-sm text-gray-600">
                        {option.description}
                      </p>
                    </button>
                  );
                  }else if (option.action === "template") {
                    return (
                      <button
                      key={index}
                      type="button"
                      className="w-full text-start py-4 px-6 bg-white rounded-lg border border-gray-300 hover:border-purple"
                      onClick={() => setShowTemplatePopup(true)}
                      disabled={isUploading}
                    >
                      <h2 className="text-md font-bold mb-1">{option.title}</h2>
                      <p className="text-sm text-gray-600">
                        {option.description}
                      </p>
                    </button>
                  );
                  }  
                  else {
                  // "From Scratch"
                  return (
                  <button
                    key={index}
                    className="w-full text-start py-4 px-6 bg-white rounded-lg border border-gray-300 hover:border-purple focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={newResume}
                    disabled={isUploading}
                  >
                    <h2 className="text-md font-bold mb-1">{option.title}</h2>
                    <p className="text-sm text-gray-600">{option.description}</p>
                  </button>
                );
              }
            })}
            </div>
          </div>
        </div>
      )}
      <LinkedinUploadModal
        isOpen={isLinkedInModalOpen}
        onClose={() => setIsLinkedInModalOpen(false)}
        onFileUpload={handleLinkedInFileUpload}
        isUploading={isUploading}
        loadingMessage={loadingMessages[loadingStep]}
      />
      {showTemplatePopup && (
        <ResumeSamples
          isOpen={showTemplatePopup}
          onClose={() => setShowTemplatePopup(false)}
        />
      )}
    </>
  );
};

export default Resume;
