const aspirationOptions = [
    { label: "Work-life balance", value: "Work-life balance" },
    { label: "Competitive salary", value: "Competitive salary" },
    { label: "Hybrid or remote flexibility", value: "Hybrid or remote flexibility" },
    { label: "Opportunities for career growth", value: "Opportunities for career growth" },
    { label: "Positive, collaborative culture", value: "Positive, collaborative culture" },
    { label: "Meaningful impact on society", value: "Meaningful impact on society" },
    { label: "Challenging environment", value: "Challenging environment" },
    { label: "Job security", value: "Job security" },
    { label: "Autonomy and creativity", value: "Autonomy and creativity" },
    { label: "Professional development", value: "Professional development" },
    { label: "Leadership opportunities", value: "Leadership opportunities" },
    { label: "Mentorship programs", value: "Mentorship programs" },
    { label: "Startup culture", value: "Startup culture" },
    { label: "Diverse and inclusive environment", value: "Diverse and inclusive environment" },
    { label: "Work with cutting-edge technology", value: "Work with cutting-edge technology" },
    { label: "Flexible work schedule", value: "Flexible work schedule" },
    { label: "Recognition and rewards", value: "Recognition and rewards" },
    { label: "Strong health benefits", value: "Strong health benefits" },
    { label: "Travel opportunities", value: "Travel opportunities" },
    { label: "Team-building events", value: "Team-building events" },
    { label: "Environmentally conscious", value: "Environmentally conscious" },
    { label: "Room for innovation", value: "Room for innovation" },
    { label: "Clear promotion path", value: "Clear promotion path" },
    { label: "Equity or stock options", value: "Equity or stock options" },
    { label: "Global collaboration", value: "Global collaboration" },
    { label: "Work under respected leaders", value: "Work under respected leaders" },
    { label: "Support for continuing education", value: "Support for continuing education" },
    { label: "Generous PTO policy", value: "Generous PTO policy" },
    { label: "Friendly and welcoming team", value: "Friendly and welcoming team" },
    { label: "Strong brand reputation", value: "Strong brand reputation" },
    { label: "Philanthropy and volunteering", value: "Philanthropy and volunteering" },
  ];
  
  // 30+ culture values
  const cultureOptions = [
    { label: "Innovative", value: "Innovative" },
    { label: "Mission-driven", value: "Mission-driven" },
    { label: "Collaborative", value: "Collaborative" },
    { label: "Inclusive", value: "Inclusive" },
    { label: "Fast-paced", value: "Fast-paced" },
    { label: "Employee-focused", value: "Employee-focused" },
    { label: "Transparent", value: "Transparent" },
    { label: "Customer-centric", value: "Customer-centric" },
    { label: "Supportive", value: "Supportive" },
    { label: "Results-oriented", value: "Results-oriented" },
    { label: "Respectful", value: "Respectful" },
    { label: "Data-driven", value: "Data-driven" },
    { label: "Entrepreneurial", value: "Entrepreneurial" },
    { label: "Human-centered", value: "Human-centered" },
    { label: "Empowering", value: "Empowering" },
    { label: "Agile", value: "Agile" },
    { label: "Open-minded", value: "Open-minded" },
    { label: "Accountable", value: "Accountable" },
    { label: "Integrity-based", value: "Integrity-based" },
    { label: "Growth mindset", value: "Growth mindset" },
    { label: "Learning-oriented", value: "Learning-oriented" },
    { label: "Community-driven", value: "Community-driven" },
    { label: "Trust-building", value: "Trust-building" },
    { label: "Collaborative leadership", value: "Collaborative leadership" },
    { label: "Passionate", value: "Passionate" },
    { label: "Employee empowerment", value: "Employee empowerment" },
    { label: "Highly communicative", value: "Highly communicative" },
    { label: "Respect for diversity", value: "Respect for diversity" },
    { label: "Family-friendly", value: "Family-friendly" },
    { label: "Wellness-focused", value: "Wellness-focused" },
    { label: "Adaptive", value: "Adaptive" },
  ];
  
  // Add some sample seniority options
  const seniorityOptions = [
    { label: "Intern", value: "intern" },
    { label: "Junior", value: "junior" },
    { label: "Mid-level", value: "mid-level" },
    { label: "Senior", value: "senior" },
    { label: "Lead", value: "lead" },
    { label: "Manager", value: "manager" },
  ];

export {aspirationOptions, cultureOptions, seniorityOptions}